import React, { useEffect, useState,useContext } from "react";
import {
  Button,
  Input,
  Modal,
  Radio,
  RadioGroup,
} from "@salesforce/design-system-react";
import { v4 } from "uuid";
import "../css/createNewPCR.css";
import { getTransferDetails } from "../services/transfer";
import { useAuth } from "../hooks/useAuth.js";
import {
  getPatientAge,
  getMonths,
  getFormattedDateString,
} from "../utils/date";
import { useTranslation } from "react-i18next";
import { ERROR_CODES, yesOrNoOptions } from "../data/constants";
import { getMedicIds } from "../storage/localStorage.js";
import { db } from "../storage/idb.js";
import { useNetworkStatus } from "../hooks/useNetworkStatus.js";
import moment from "moment";
import {useHandlePcrData} from '../hooks/dataHandlingHooks/useHandlePCRData'
import { PCRContext } from "../context/PCRContext";
const attendingSupportingOptions = ["attend", "support"];
const tnOptions = [
  "Yes, provided Transfer number is accurate",
  "No, I made a typo",
];

const CreateNewPCR = ({ onPCRSelection }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isCrewSelected, setIsCrewSelected] = useState(null);
  const [isCrewIDSelected, setIsCrewIDSelected] = useState(null);
  const { authorize } = useAuth();
  const isOnline = useNetworkStatus();
  const loggedInMedicIds = getMedicIds()?.split(",");
  const [isManualProcess, setIsManualProcess] = useState(false);
  const {setPcrRoot,pcrData} = useHandlePcrData();
  const {setReloadPcrGrid,showStartPcr,startPcrInfo,setShowStartPcr} = useContext(PCRContext);

  useEffect(() => {
    if(showStartPcr){
      console.log(startPcrInfo);
      
      toggleModalOpen();
      setTransferNumber(startPcrInfo?.pcrTransferNumber);
    }
  },[showStartPcr]);

  const toggleModalOpen = () => {
    if (showModal) {
      setIsAttendingCrew(null);
      setAttendingCrewId("");
      setShowVehicleView(false);
      setErrorCode("");
      setServerError();
      setAllMembersLoggedIn("");
      setTransferNumberError(null);
      setTnConfirmation("");
      setTransferNumber("");
      setVehicleNumber("");
      setIsCrewSelected(null);
      setIsCrewIDSelected(null);
    }
    setShowModal(!showModal);
  };
  const [transferNumberError, setTransferNumberError] = useState(null);
  const [vehicleNumberError, setVehicleNumberError] = useState(null);
  const [tnConfirmation, setTnConfirmation] = useState("");
  const [attendingCrewId, setAttendingCrewId] = useState(loggedInMedicIds[0]);
  const [transferNumber, setTransferNumber] = useState("");
  const [isAttendingCrew, setIsAttendingCrew] = useState(null);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleType, setvehicleType] = useState("AMB");
  const [serverError, setServerError] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [showVehicleView, setShowVehicleView] = useState(!isOnline);
  const [allMembersLoggedIn, setAllMembersLoggedIn] = useState();

  const handleIsAttendingCrewSelection = ({ target: { value } }) => {
    setIsCrewSelected(true);
    setIsAttendingCrew(value);
  };

  useEffect(() => {
    setShowVehicleView(!isOnline);
  }, [isOnline]);

  useEffect(() => {
    if (transferNumberError !== null) {
      validateTransferNumber();
    }
  }, [transferNumber]);

  const handleAttendingCrewIDSelection = ({ target: { value } }) => {
    setIsCrewIDSelected(true);
    setAttendingCrewId(value);
  };

  const handleTransferNoChange = ({ target: { value } }) => {
    if (errorCode === ERROR_CODES.PCR_EXISTS) {
      setErrorCode("");
      setServerError("");
    }
    setTransferNumber(value);
  };

  const handleVehicleNumber = ({ target: { value } }) => {
    if (value && vehicleNumberError) {
      setVehicleNumberError("");
    }
    setVehicleNumber(value);
  };

  const handleVehicleType = ({ target: { value } }) => {
    setvehicleType(value);
  };

  const handleAllMembersLoggedIn = ({ target: { value } }) => {
    setAllMembersLoggedIn(value);
  };

  const addNewPCR = (data) => {
    const result = setPcrRoot(data);
    toggleModalOpen();
    const dataFromConstruct =   {
      id: v4(),
      // for summary
      pcrTransferNumber: transferNumber,
      vehicleNumber: isManualProcess ? vehicleNumber : result.identificationDetails?.vehicleNumber,
      vehicleType: isManualProcess ? vehicleType : result.identificationDetails?.vehicleType,
      dispatchType: "Land",
      isAttendingCrew: isAttendingCrew === "attend",
      medicIds: getMedicIds(),
        ...result,
      pcrStatus: "In Progress",
      timestamp: new Date().toISOString(),
      isAttendingCrew: isAttendingCrew === "attend",
      viewType: "attending"
    };
    
    if (data && Object.keys(data).length) {     

    db.pcrs.add(
      dataFromConstruct
    );

  }
  setReloadPcrGrid(true);  
    setTimeout(() => {    
      onPCRSelection(transferNumber);
    }, 1000);

    if (isManualProcess) {
      setIsManualProcess(false);
    }
  };

  const validateTransferNumber = () => {
    if (!transferNumber) {
      setTransferNumberError(t("inputIsRequiredError"));
      return false;
    }

    if (!new RegExp(/^\d+$/).test(transferNumber)) {
      setTransferNumberError("Only numbers are allowed.");
      return false;
    } else if (Number(transferNumber) === 0) {
      setTransferNumberError("Number should be greater than 0.");
      return false;
    } else {
      setTransferNumberError("");
      return true;
    }
  };

  const handleNextClick = async () => {
    const isValidTransferNumber = validateTransferNumber();

    if (isAttendingCrew === null) {
      setIsCrewSelected(false);
      return;
    }

    if (!isValidTransferNumber) {
      return;
    }

    if (isCrewIDSelected === null && loggedInMedicIds.length !== 1) {
      setIsCrewIDSelected(false);
      return;
    }
    if (showVehicleView && !vehicleNumber) {
      setVehicleNumberError(t("inputIsRequiredError"));
      return;
    }

    // const isRecordExists = await db.pcrs.where({pcrTransferNumber: transferNumber }).count();
    const dbPcrList = await db.pcrs.toArray();
    const isRecordExists = dbPcrList.some(p => p.pcrTransferNumber === transferNumber);
   
    if(isRecordExists && showStartPcr){
      let rec = dbPcrList.find(p => p.pcrTransferNumber === transferNumber);
      // handleUpdate
      db.pcrs.delete(rec.id);
      setShowStartPcr(false);      
    }
    else{
      if (isRecordExists && !showStartPcr) {
        setErrorCode(ERROR_CODES.PCR_EXISTS);
        setServerError(t("PCRExists"));
        return;
      }
  
      if ((!isOnline || isManualProcess)) {
        addNewPCR({
          pcrTransferNumber:transferNumber,
          vehicleNumber,
          vehicleType,
          pcrAttendPractitioner: attendingCrewId ? attendingCrewId : loggedInMedicIds[0],
         
        });
        return;
      }
    }

  

    // TODO - Save transfer number and medic if for later use.
    const response = await getTransferDetails(transferNumber, loggedInMedicIds);
    
    if (response.status === ERROR_CODES.UNAUTHORIZED) {
      authorize();
    } else if (
      response.status === ERROR_CODES.BAD_REQUEST ||
      response.status === ERROR_CODES.RESOURCE_NOT_FOUND ||
      response.status === ERROR_CODES.PCR_SERVER_ERROR
    ) {
      setErrorCode(response.error);
      setServerError(response.data);
    } else {
      addNewPCR(response.data[0]);
    }
  };

  const handleTNConfirmation = ({ target: { value } }) => {
    setTnConfirmation(value);
  };

  const handleProceed = () => {
    setShowVehicleView(true);
    setServerError("");
    setIsManualProcess(true);
  };

  const getFooterButtons = () => {
    if (tnConfirmation === tnOptions[1]) {
      return [
        <Button
          variant="outline-brand"
          className="createPCRCancel"
          onClick={toggleModalOpen}
        >
          Cancel
        </Button>,
        <Button
          className="createPCRNext"
          variant="brand"
          onClick={handleNextClick}
        >
          Try again
        </Button>,
      ];
    }

    if (!serverError) {
      return [
        <Button
          variant="outline-brand"
          className="createPCRCancel"
          onClick={toggleModalOpen}
        >
          {t("cancel")}
        </Button>,
        <Button
          className="createPCRNext"
          variant="brand"
          onClick={handleNextClick}
        >
          {t("next")}
        </Button>,
      ];
    } else if (
      (errorCode === ERROR_CODES.NO_TRANFER_NUMBER_FOUND &&
        tnConfirmation === tnOptions[0]) ||
      !errorCode ||
      (errorCode === ERROR_CODES.MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER &&
        tnConfirmation === tnOptions[0] &&
        allMembersLoggedIn === yesOrNoOptions[0])
    ) {
      return (
        <Button
          className="createPCRNext"
          variant="brand"
          onClick={handleProceed}
        >
          Proceed
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <Button variant="outline-brand" onClick={toggleModalOpen}>
        {t("newPCR")}
      </Button>
      <Modal
        isOpen={showModal}
        onRequestClose={toggleModalOpen}
        header={t("newPCR")}
        footer={getFooterButtons()}
      >
        <section id="createNewPCRModal" className="slds-p-around_medium">
          <div className="modalDesc">
            {showVehicleView ? t("noConnectivityCreatePCRDesc") : t("newOrder")}
          </div>
          <>
            <div className="cnpcrInputWrapper">
              <Input
                fixedTextLeft
                id="create-pcr-transfer-number"
                label={t("transfer")}
                onChange={handleTransferNoChange}
                value={transferNumber}
                required
                errorText={
                  transferNumberError && (
                    <div className="aic df">
                      <div className="inputErrorIcon"></div>
                      <div>{transferNumberError}</div>
                    </div>
                  )
                }
              />
            </div>
            <div className="cnpcrRadioBtnWrapper">
              <RadioGroup
                labels={{ label: t("crew") }}
                onChange={handleIsAttendingCrewSelection}
                required
                name="attending-crew-radio-group"
              >
                {attendingSupportingOptions.map((value) => (
                  <Radio
                    key={value}
                    id={`attending-crew-${value}`}
                    labels={{ label: t(value) }}
                    value={value}
                    checked={isAttendingCrew === value}
                    variant="base"
                  />
                ))}
              </RadioGroup>
              {isCrewSelected === false && (
                <div className="radioSelectionError slds-form-element__help selectionError">
                  {t("inputSelectionRequiredError")}
                </div>
              )}
            </div>
          </>
          {isAttendingCrew && (
            <>
              {loggedInMedicIds.length > 1 ? (
                <div className="cnpcrRadioBtnWrapper">
                  <RadioGroup
                    labels={{
                      label:
                        isAttendingCrew === "attend"
                          ? "Attending ID"
                          : "Your ID",
                    }}
                    onChange={handleAttendingCrewIDSelection}
                    required
                    name="attending-crewID-radio-group"
                  >
                    {loggedInMedicIds.map((value) => (
                      <Radio
                        key={value}
                        id={`crew-id-${value}`}
                        labels={{ label: value }}
                        value={value}
                        checked={attendingCrewId === value}
                        variant="base"
                      />
                    ))}
                  </RadioGroup>
                  {isCrewIDSelected === false && (
                    <div className="radioSelectionError slds-form-element__help selectionError">
                      {t("inputSelectionRequiredError")}
                    </div>
                  )}
                </div>
              ) : (
                <div className="onlyMedicTextWrapper">
                  <div className="onlyMedicLabel">
                    {isAttendingCrew === "attend" ? "Attending ID" : "Your ID"}
                  </div>
                  <div className="onlyMedicValue">{loggedInMedicIds[0]}</div>
                </div>
              )}
            </>
          )}
          {showVehicleView && (
            <>
              <div className="cnpcrInputWrapper paddingTop10">
                <Input
                  fixedTextLeft
                  className="pb10"
                  id="create-pcr-vehicle-number"
                  label="Vehicle Number"
                  onChange={handleVehicleNumber}
                  value={vehicleNumber}
                  required
                  errorText={
                    vehicleNumberError ? t("inputIsRequiredError") : ""
                  }
                />
              </div>
              <div className="vehicleTypeWrapper slds-form-element paddingTop10 pb10">
                <label for="vehicleTypes">
                  <abbr class="slds-required" title="required">
                    *
                  </abbr>
                  Vehicle Type
                </label>
                <div className="slds-form-element vehicleTypeSelectWrapper onlyMedicValue">
                  <select
                    name="vehicleTypes"
                    id="vehicleTypes"
                    className="slds-input"
                    onChange={handleVehicleType}
                  >
                    <option key="select-amb" value="AMB">
                      AMB
                    </option>
                    <option key="select-ptu" value="PTU">
                      PTU
                    </option>
                    <option key="select-csu" value="CSU">
                      CSU
                    </option>
                    <option key="select-rru" value="RRU">
                      RRU
                    </option>
                    <option key="select-mts" value="MTS">
                      MTS
                    </option>
                    <option key="select-cpu" value="CPU">
                      CPU
                    </option>
                  </select>
                </div>
              </div>
            </>
          )}

          {serverError && !showVehicleView && (
            <div className="serverErrorWrapper">
              <div className="serverErrorWarning pa"></div>
              <div className="serverErrorWarningText">{serverError}</div>
            </div>
          )}

          {(errorCode === ERROR_CODES.NO_TRANFER_NUMBER_FOUND ||
            errorCode === ERROR_CODES.MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER) &&
            !showVehicleView && (
              <>
                <div className="cnpcrComfirmTNRadioBtnWrapper">
                  <RadioGroup
                    labels={{
                      label:
                        "Please confirm if Transfer number entered above is correct?",
                    }}
                    onChange={handleTNConfirmation}
                    name="tn-confirm-radio-group"
                  >
                    {tnOptions.map((value) => (
                      <Radio
                        key={value}
                        id={`tn-options-${value}`}
                        labels={{ label: value }}
                        value={value}
                        checked={tnConfirmation === value}
                        variant="base"
                      />
                    ))}
                  </RadioGroup>
                </div>
              </>
            )}

          {errorCode === ERROR_CODES.MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER &&
            tnConfirmation === tnOptions[0] &&
            !showVehicleView && (
              <>
                <div className="cnpcrMemberLoggedInRadioBtnWrapper paddingTop10">
                  <RadioGroup
                    labels={{
                      label:
                        "Are all members of your crew currently logged into the ePCR application?",
                    }}
                    onChange={handleAllMembersLoggedIn}
                    name="all-members-radio-group"
                  >
                    {yesOrNoOptions.map((value) => (
                      <Radio
                        key={value}
                        id={`all-members-${value}`}
                        labels={{ label: value }}
                        value={value}
                        checked={allMembersLoggedIn === value}
                        variant="base"
                      />
                    ))}
                  </RadioGroup>
                </div>
              </>
            )}

          {tnConfirmation === tnOptions[0] &&
            !showVehicleView &&
            (errorCode === ERROR_CODES.NO_TRANFER_NUMBER_FOUND ||
              (errorCode === ERROR_CODES.MEDIC_NOT_FOUND_ON_TRANSFER_NUMBER &&
                allMembersLoggedIn === yesOrNoOptions[0])) && (
              <div className="manualChartText">
                Please proceed with manual charting.
              </div>
            )}
          {tnConfirmation === tnOptions[1] && (
            <div className="correctTNAndTryAgain">
              Please correct the transfer number above, and try again.
            </div>
          )}
          {allMembersLoggedIn === yesOrNoOptions[1] &&
            tnConfirmation === tnOptions[0] && (
              <div className="closePopupMemberLoggedIn">
                Please close this popup and ensure all members of your crew are
                logged into the ePCR application before you create the PCR.
              </div>
            )}
        </section>
      </Modal>
    </>
  );
};

export default CreateNewPCR;
