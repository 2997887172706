import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../../../css/PCRVasculars.css";
import PCRVascularItem from 'components/PCR/PCR-AV/Treatment/Vasculars/PCRVascularItem';
import { PCRContext } from "context/PCRContext.js";
import { Button, Modal, Icon } from '@salesforce/design-system-react';
import { VALIDATION_TYPES } from "../../../../../data/constants";

const PCRVasculars = ({ pcr }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { treatment } = pcrData || {};
  const patientVasculars = treatment?.vasculars || [];
  
  const [vascularItems, setVascularItems] = useState([{ id: 0, data: {} }]);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePCRContext = (inputObj) => {
    const field = formValidationConfig.find(f => f.name === Object.keys(inputObj)[0]);
    let errorState = {};

    Object.entries(inputObj).forEach(([key, value]) => {
      field?.validations.forEach((type) => {
        if (field?.shouldDisplayError && !value) {
          switch (type) {
            case VALIDATION_TYPES.VALUE_REQUIRED:
              errorState[`${field.name}Error`] = t("inputIsRequiredError");
              break;
            default:
              break;
          }
        }
        if (field?.shouldDisplayError && value) {
          errorState[`${field.name}Error`] = "";
        }
      });
    });

    setPCRData({
      ...pcrData,
      treatment: { ...treatment, vasculars: { ...patientVasculars, ...inputObj } },
      ...errorState,
    });
  };

  // Function to add a new vascular row
  const addVascularItem = () => {
    const newId = vascularItems.length > 0 ? vascularItems[vascularItems.length - 1].id + 1 : 0;
    setVascularItems([...vascularItems, { id: newId, data: {} }]);
  };

  // Function to handle data updates in each row
  const handleVascularRowDataChange = (index, field, value) => {
    const updatedItems = [...vascularItems];
    updatedItems[index].data[field] = value;
    setVascularItems(updatedItems);
  };

  // Function to check if a row contains any data (returns true if it has data)
  const isRowEmpty = (rowData) => {
    return Object.values(rowData).every(value => value === "" || value === null || value === undefined);
  };

  // Function to handle the delete button click
  const handleDeleteClick = (index) => {
    const rowData = vascularItems[index].data;

    // If the row is empty, delete it directly
    if (isRowEmpty(rowData)) {
      removeVascularItem(index);
    } else if (!isRowEmpty(rowData) && vascularItems.length === 1) {
      setSelectedRowForDeletion(index);
      setShowDeleteModal(true);
      addVascularItem();
    } else {
      // If the row contains data, show the modal for confirmation
      setSelectedRowForDeletion(index);
      setShowDeleteModal(true);
    }
  };

  // Function to remove the selected row and handle the case for 1 row with data
  const removeVascularItem = (index) => {
    let updatedItems = vascularItems.filter((_, i) => i !== index);

    // If there is only one row and it contains data, remove it and add a new empty row
    if (vascularItems.length === 1 && !isRowEmpty(vascularItems[0].data)) {
      updatedItems = [{ id: 0, data: {} }];
    }

    // If no rows left after deletion, add a new empty row
    if (updatedItems.length === 0) {
      updatedItems.push({ id: 0, data: {} });
    }

    setVascularItems(updatedItems);
    setSelectedRowForDeletion(null);
    setShowDeleteModal(false);
  };

  // Function to confirm row deletion from the modal
  const confirmDeletion = () => {
    removeVascularItem(selectedRowForDeletion);
  };

  return (
    <>
      <div className='pcr-col' style={{ width: '100%' }}>
        <div className="pcr-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', width: '100%', marginTop: '10px' }}>
          {/* Vascular Access component label */}
          <label className=" dataValueVascular slds-form-element__label">
            {t("vascularAccess")}
          </label>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {vascularItems.map((item, index) => (
            <div key={item.id} className='slds-grid slds-gutters'>
              {/* Input fields container */}
              {/* style={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }} */}
              <PCRVascularItem
                  index={index}
                  updateRowData={(field, value) => handleVascularRowDataChange(index, field, value)}
                  updatePCRContext={updatePCRContext}
                />

              {/* Delete and Chart Icon */}
             {
              index === 0 ? (
                <div style={{marginTop: '1.5rem', marginLeft: '1rem'}}>
                <Button
                  iconCategory="utility"
                  iconName="new"
                  variant="icon"
                  className="slds-icon-text-success"
                  onClick={addVascularItem}
                  iconSize="large"
                />
              </div>
              ) : (<div style={{marginTop: '1.5rem', marginLeft: '1rem'}}>
                <Button
                  iconCategory="utility"
                  iconName="ban"
                  variant="icon"
                  className="slds-icon-text-error"
                  onClick={() => handleDeleteClick(index)}
                  iconSize="large"
                />
              </div>)
             }
              
            </div>
          ))}
        </div>

        {/* Modal for deletion confirmation */}
        {showDeleteModal && (
          <Modal
            footer={[
              <Button label="Confirm" onClick={confirmDeletion} />,
              <Button label="Cancel" onClick={() => setShowDeleteModal(false)} />,
            ]}
            isOpen={showDeleteModal}
            onRequestClose={() => setShowDeleteModal(false)}
            title={
              <span>
                <Icon
                  assistiveText={{ label: "warning" }}
                  category="utility"
                  name="warning"
                  size="medium"
                  style={{ fill: "#fe9339" }}
                />
                <span>{t("Warning: Data Deletion")}</span>
              </span>
            }
          >
            <div className="slds-m-around_medium">
              <p>{t("You are about to remove a row that contains data. Are you sure you want to proceed?")}</p>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PCRVasculars;