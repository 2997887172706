import React, { useContext, useState, useRef, useEffect } from 'react';
import { RadioGroup, Radio, Tooltip, Button, Icon, Modal, Checkbox } from '@salesforce/design-system-react';
import { useTranslation } from 'react-i18next';
import { PCRContext } from 'context/PCRContext';
import { VALIDATION_TYPES } from "../../../../../data/constants";
import PCRTextInput from "../../../../common/PCRTextInput";
import "../../../../../css/circulationAndMonitor.css";
import PCRACPElectricalTherapy from "./PCRACPElectricalTherapy.jsx";
import CardiacArrestSection from "./PCRCardiacArrestSection.jsx";
import PDFViewer from "../../../../PDFViewer";
import { useTreatmentValidations } from '../../../../../hooks/useTreatmentValidations';

const PCRCirculationAndMonitor = ({ pcr }) => {
    const { t } = useTranslation();
    const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
    const { treatment } = pcrData || {};
    const circulationAndMonitor = treatment?.circulationAndMonitor || {};

    const [dnarDirective, setDnarDirective] = useState(circulationAndMonitor?.dnarDirective || "");
    const [dnarLevel, setDnarLevel] = useState(circulationAndMonitor?.dnarLevel || "");
    const [receivedFrom, setReceivedFrom] = useState(circulationAndMonitor?.receivedFrom || "");
    const [isDNARTooltipOpen, setIsDNARTooltipOpen] = useState(false);
    const dnarTooltipRef = useRef(null);
    const [monitorSN, setMonitorSN] = useState(circulationAndMonitor?.monitorSN || "");
    const [patientID, setPatientID] = useState(circulationAndMonitor?.patientID || "");
    const [isECGAcquired, setIsECGAcquired] = useState(circulationAndMonitor?.isECGAcquired || "");
    const [isECGTransmitted, setIsECGTransmitted] = useState(circulationAndMonitor?.isECGTransmitted || "");
    const [isFibrinolyticChecklistComplete, setIsFibrinolyticChecklistComplete] = useState(circulationAndMonitor?.isFibrinolyticChecklistComplete || "");
    const [isVagalManeuverSelected, setIsVagalManeuverSelected] = useState(false);
    const [vagalManeuverMethod, setVagalManeuverMethod] = useState("");
    const [cardiacArrest, setCardiacArrest] = useState(circulationAndMonitor?.cardiacArrest || "");
    const [showFibrinolyticChecklist, setShowFibrinolyticChecklist] = useState(false);

    // Manage electrical therapy items
    const [therapyItems, setTherapyItems] = useState([{ id: 0, data: {} }]);
    const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {getTreatmentValidations}  = useTreatmentValidations();


    const updatePCRContext = (inputObj) => {
        const field = formValidationConfig.find(f => f.name === Object.keys(inputObj)[0]);
        let errorState = {};

        Object.entries(inputObj).forEach(([key, value]) => {
            field?.validations.forEach((type) => {
                if (field?.shouldDisplayError && !value) {
                    switch (type) {
                        case VALIDATION_TYPES.VALUE_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputIsRequiredError");
                            break;
                        default:
                            break;
                    }
                }
                if (field?.shouldDisplayError && value) {
                    errorState[`${field.name}Error`] = "";
                }
            });
        });

        setPCRData({
            ...pcrData,
            treatment: { ...treatment, circulationAndMonitor: { ...circulationAndMonitor, ...inputObj } },
            ...errorState,
        });
    };

    // Handle numeric validation for Joules
    const handleMonitorSNChange = (e) => {
        const monitorSNValue = e?.monitorSN || "";
        const value = monitorSNValue.replace(/[^1-9]/g, '');
        if(value >= 0){
            setMonitorSN(value);
            updatePCRContext({ monitorSN: value });
        }

    };

    // Handle numeric validation for Patient ID
    const handlePatientIDChange = (e) => {
        const patientIDValue = e?.patientID || "";
        const value = patientIDValue.replace(/[^1-9]/g, '');
        if(value > 0){
            setPatientID(value);
            updatePCRContext({ patientID: value });
        }
      
    };

    // Handlers for DNAR Tooltip
    const toggleDNARTooltip = () => {
        setIsDNARTooltipOpen(!isDNARTooltipOpen);
    };

    const closeDNARTooltip = () => {
        setIsDNARTooltipOpen(false);
    };

    // Function to add a new row
    const addTherapyItem = () => {
        const newId = therapyItems.length > 0 ? therapyItems[therapyItems.length - 1].id + 1 : 0;
        setTherapyItems([...therapyItems, { id: newId, data: {} }]);
    };

    // Function to check if row contains data (returns true if data exists)
    const isRowEmpty = (rowData) => {
        return Object.values(rowData).every(value => value === "" || value === null || value === undefined);
    };

    // Function to handle data updates in each row
    const handleRowDataChange = (index, field, value) => {
        const updatedItems = [...therapyItems];
        updatedItems[index].data[field] = value;
        setTherapyItems(updatedItems);
    };

    // Function to handle the delete button click
    const handleDeleteClick = (index) => {
        const rowData = therapyItems[index].data;
        if (isRowEmpty(rowData)) {
            removeTherapyItem(index);
        } else if (!isRowEmpty(rowData) && therapyItems.length === 1) {
            setSelectedRowForDeletion(index);
            setShowDeleteModal(true);
            addTherapyItem();
        } else {
            // If row contains data, show warning modal
            setSelectedRowForDeletion(index);
            setShowDeleteModal(true);
        }
    };

    // Function to remove a row
    const removeTherapyItem = (index) => {
        let updatedItems = therapyItems.filter((_, i) => i !== index);

        // If only one row and it has data, remove it and add a new empty row
        if (therapyItems.length === 1 && !isRowEmpty(therapyItems[0].data)) {
            updatedItems = [{ id: 0, data: {} }];
        }

        // If no rows left, add a new empty row
        if (updatedItems.length === 0) {
            updatedItems.push({ id: 0, data: {} });
        }

        setTherapyItems(updatedItems);
        setSelectedRowForDeletion(null);
        setShowDeleteModal(false);
    };

    // Function to confirm row deletion in the modal
    const confirmDeletion = () => {
        removeTherapyItem(selectedRowForDeletion);
    };

    // Handle checkbox change for Vagal Maneuver
    const handleVagalManeuverChange = (e) => {
        setIsVagalManeuverSelected(e.target.checked);
        if (!e.target.checked) {
            setVagalManeuverMethod("");
        }
    };

    const isRequiredField = (name) => {
        
        // let allFields = getTreatmentValidations(pcrData);
       let field = formValidationConfig.find(f => f.name === name);
       return field?.shouldDisplayError;

    }

    const handleChecklistFile = () => setShowFibrinolyticChecklist(!showFibrinolyticChecklist);

    return (
        <>
            <PDFViewer
                showPDF={showFibrinolyticChecklist}
                onDone={handleChecklistFile}
                fileName={`${process.env.PUBLIC_URL}/STEMI_Reperfusion_Checklist_v1.1.pdf`}
            />

            <div className="df h-4half-r">
                   {/* Advanced directive: DNAR */}
                   <div className="w-15">
                    <RadioGroup
                        labels={{
                            label: (
                                <span className="dataValueCircularfont">
                                    {t("advancedDirectiveDNAR")}
                                </span>
                            ),
                        }}
                        name="dnar-directive-radio-group"
                        className="disp-align-end"
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setDnarDirective(selectedValue);
                            updatePCRContext({ dnar: selectedValue });
                        }}
                    >
                        <Radio
                            id="dnar-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={dnarDirective === "Yes"}
                        />
                        <Radio
                            id="dnar-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={dnarDirective === "No"}
                        />
                    </RadioGroup>
                
                </div>

                {/* Advanced directive: DNAR level */}
                <div className="w-20">
                
                    <label className="slds-form-element__label" htmlFor="dnar-level">
                        {t('advancedDirectiveDNARLevel')}
                        <Tooltip
                            isOpen={isDNARTooltipOpen}
                            onRequestClose={closeDNARTooltip}
                            content={
                                <div ref={dnarTooltipRef} style={{ position: 'relative' }}>
                                    <Button
                                        assistiveText={{ icon: 'close' }}
                                        iconCategory="utility"
                                        iconName="ban"
                                        iconSize="large"
                                        variant="icon"
                                        onClick={closeDNARTooltip}
                                        className="custom-tooltip-close-button"
                                    />
                                    <img
                                        src={`${process.env.PUBLIC_URL}/DNAR_Level_Guide.png`}
                                        alt="DNAR Level Reference Guide"
                                        className="custom-tooltip-image"
                                    />
                                </div>
                            }
                            align="top"
                        >
                            <Button
                                assistiveText={{ icon: 'info' }}
                                iconCategory="utility"
                                iconName="info"
                                iconSize="small"
                                variant="icon"
                                onClick={toggleDNARTooltip}
                                className="slds-m-left_x-small"
                            />
                        </Tooltip>
                    </label>
                    <PCRTextInput
                        id="dnar-level"
                        required={false}
                        value={dnarLevel}
                        name={"dnarLevel"}
                        onChange={(e) => {
                            updatePCRContext({ dnarLevel: e.dnarLevel });
                            setDnarLevel(e['dnarLevel']);
                        }}
                    />
                </div>
               

                {/* Advanced directive: Received from */}
                <div className="w-20">
                    <PCRTextInput
                        id="received-from"
                        label={t("advancedDirectiveDNAReceivedFrom")}
                        required={false}
                        value={receivedFrom}
                        name={"receivedFrom"}
                        onChange={(e) => {
                            updatePCRContext({ receivedFrom: e.receivedFrom });
                            setReceivedFrom(e['receivedFrom']);
                        }}
                    />
                </div>
                
            </div>

            <div className="df h-4half-r">
                {/* Monitor S/N */}
                <div className="w-15">
                    <PCRTextInput
                        id="monitor-sn"
                        label={t("monitorSN")}
                        type="text"
                        value={monitorSN}
                        name="monitorSN"
                        onChange={handleMonitorSNChange}
                        required={isRequiredField('monitorSN')}

                    />
                </div>
                

                {/* Patient ID */}
                <div className="w-20">
                    <PCRTextInput
                        id="patient-id"
                        label={t("patientId")}
                        type="text"
                        value={patientID}
                        name="patientID"
                        onChange={handlePatientIDChange}
                        required={isRequiredField('patientID')}

                    />
                </div>

                {/* Was 12-lead ECG acquired? */}
                <div className="w-20">
                    <RadioGroup
                        labels={{ label: t("12LeadECGAcquired") }}
                        name="ecg-acquired-radio-group"
                        className="disp-align-end"
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setIsECGAcquired(selectedValue);
                            updatePCRContext({ was12LeadECGAcquired: selectedValue });
                        }}
                        required={isRequiredField('was12LeadECGAcquired')}

                    >
                        <Radio
                            id="ecg-acquired-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={isECGAcquired === "Yes"}
                        />
                        <Radio
                            id="ecg-acquired-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={isECGAcquired === "No"}
                        />
                    </RadioGroup>
                </div>
               

                {/* If Yes, show additional fields */}
                <div className="df w-30">
                {isECGAcquired === "Yes" && (
                    <>
                        {/* Container for ECG transmitted and fibrinolytic checklist */}
                        <div style={{ display: 'block', flexDirection: 'column', width: '100%',justifyContent: 'space-between' }}>
                            {/* First row: Was 12-lead ECG transmitted? */}
                            <div style={{ display: 'block', alignItems: 'center', whiteSpace: 'nowrap'}}>
                                <div className="radio-group">
                                    <RadioGroup
                                        labels={{ label: t("12LeadECGTransmitted") }}
                                        name="ecg-transmitted-radio-group"
                                        className="disp-align-end"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setIsECGTransmitted(selectedValue);
                                            updatePCRContext({ was12LeadECGTransmitted: selectedValue });
                                        }}
                                        required={isECGAcquired === "Yes"}
                                    >
                                        <Radio
                                            id="ecg-transmitted-yes"
                                            labels={{ label: t("Yes") }}
                                            value="Yes"
                                            checked={isECGTransmitted === "Yes"}
                                        />
                                        <Radio
                                            id="ecg-transmitted-no"
                                            labels={{ label: t("No") }}
                                            value="No"
                                            checked={isECGTransmitted === "No"}
                                        />
                                    </RadioGroup>
                                </div>

                                <div className="radio-group">
                                    <label className="slds-form-element__label" htmlFor="fibrinolytic-checklist-complete">
                                        {t("fibrinolyticChecklistComplete")}
                                        <Button
                                            assistiveText={{ icon: 'info' }}
                                            iconCategory="utility"
                                            iconName="info"
                                            iconSize="small"
                                            variant="icon"
                                            onClick={handleChecklistFile}
                                            className="slds-m-left_x-small"
                                        />
                                    </label>
                                    <RadioGroup
                                        labels={{ label: "" }}
                                        name="fibrinolytic-checklist-radio-group"
                                        className="disp-align-end"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setIsFibrinolyticChecklistComplete(selectedValue);
                                            updatePCRContext({ fibrinolycticChecklist: selectedValue });
                                        }}
                                    >
                                        <Radio
                                            id="fibrinolytic-yes"
                                            labels={{ label: t("Yes") }}
                                            value="Yes"
                                            checked={isFibrinolyticChecklistComplete === "Yes"}
                                        />
                                        <Radio
                                            id="fibrinolytic-no"
                                            labels={{ label: t("No") }}
                                            value="No"
                                            checked={isFibrinolyticChecklistComplete === "No"}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                </div>
            </div>

            <div className="df">
             {/* ACP-Electrical Therapy component label */}
             <label className=" dataValueCircular ">
                {t("ACPElectricalTherapyLabel")}
                </label>
              </div>
        
            
            <div className='df'>
                <div style={{width: "60%"}}>
                {therapyItems.map((item, index) => (
                    <div key={item.id} style={{ display: 'flex'}}>
                        {/* Input fields container */}
                            <PCRACPElectricalTherapy
                                index={index}
                                updateRowData={(field, value) => handleRowDataChange(index, field, value)}
                                updatePCRContext={updatePCRContext}
                                addTherapyItem={addTherapyItem}
                                handleDeleteClick={handleDeleteClick}
                            />
                       
                    </div>
                ))}
            </div>
                <div style={{width: "40%"}}>
                    <div className="df" style={{ marginTop: '25px'}}>
                       <div style={{width: '25%'}}>
                       <Checkbox
                            labels={{
                                label: (
                                    <span className="dataValueCircularfont">
                                        {t("vagalManeuver")}
                                    </span>
                                ),
                            }}
                            checked={isVagalManeuverSelected}
                            onChange={handleVagalManeuverChange}
                            classname= "dataValueCircular"
                        />
                       </div>
                        {isVagalManeuverSelected && (
                            <PCRTextInput
                                id="vagal-maneuver-method"
                                placeholder={t("Method")}
                                value={vagalManeuverMethod}
                                onChange={(e) => setVagalManeuverMethod(e.target.value)}
                                required={true}
                                name="vagalManeuverMethod"
                                style={{ marginTop: "-7px" }}
                            />
                        )}
                    </div>
                </div>
            </div>
           

            {/* Modal for deletion confirmation */}
            {showDeleteModal && (
                <Modal
                    footer={[
                        <Button label="Confirm" onClick={confirmDeletion} />,
                        <Button label="Cancel" onClick={() => setShowDeleteModal(false)} />,
                    ]}
                    isOpen={showDeleteModal}
                    onRequestClose={() => setShowDeleteModal(false)}
                    title={
                        <span>
                            <Icon
                                assistiveText={{ label: "warning" }}
                                category="utility"
                                name="warning"
                                size="medium"
                                style={{ fill: "#fe9339" }}
                            />
                            <span>{t("Warning: Data Deletion")}</span>
                        </span>
                    }
                >
                    <div className="slds-m-around_medium">
                        <p>{t("You are about to remove a row that contains data. Are you sure you want to proceed?")}</p>
                    </div>
                </Modal>
            )}

            < div className="pcr-row" >
                {/* Cardiac Arrest Patient */}
                < div className="w-33" >
                    <RadioGroup
                        labels={{ label: t("cardiacArrestLabel") }}
                        name="cardiac-arrest-radio-group"
                        required
                        className="disp-align-end"
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setCardiacArrest(selectedValue);
                            updatePCRContext({ isThisACardiacArrestPatient: selectedValue });
                        }}
                    >
                        <Radio
                            id="cardiac-arrest-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={cardiacArrest === "Yes"}
                        />
                        <Radio
                            id="cardiac-arrest-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={cardiacArrest === "No"}
                        />
                    </RadioGroup>
                </div >
            </div >

            < div className="pcr-row" >
                {/* Conditional Cardiac Arrest Section */}
                {
                    cardiacArrest === "Yes" && (
                        <CardiacArrestSection
                            updatePCRContext={updatePCRContext}
                        />
                    )
                }
            </div >

        </>
    );
};

export default PCRCirculationAndMonitor;