import React, { useContext, useEffect } from "react";
import { Accordion, AccordionPanel } from "@salesforce/design-system-react";
import PCRIdentification from "./PCRIdentification";
import { useTranslation } from "react-i18next";
import { PCRContext } from "../context/PCRContext";
import { PCRAssessment } from "./PCR/PCR-AV/Assesment/PCRAssessment";
import { PCRTreatment } from "./PCR/PCR-AV/Treatment/PCRTreatment";
import { PCRTransferOfCare } from './PCR/PCR-AV/Transfer/PCRTransferOfCare';
import { Button } from "@salesforce/design-system-react";

export const PCRAccordionList = ({ pcr }) => {
  const { setCurrentPcrTab } = useContext(PCRContext);
  const [expanded, setExpanded] = React.useState({});
  const { t } = useTranslation();

  const togglePanel = (data) => {
    setCurrentPcrTab(data.summary);
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [data.id]: !prevExpanded[data.id],
    }));
  };

  const handleCancelConfirmation = (id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: false,
      [id + 1]: true,
    }));
  };

  // Function to toggle all panels
  const toggleAllPanels = () => {
    const allExpanded = itemsList.items.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {});

    const isAllExpanded = itemsList.items.every((item) => expanded[item.id]);

    setExpanded(isAllExpanded ? {} : allExpanded);
  };

  const itemsList = {
    items: [
      {
        id: 1,
        summary: t("identification"),
        details: (
          <PCRIdentification
            pcr={pcr}
            id={1}
            handleCancelConfirmation={handleCancelConfirmation}
          />
        ),
      },
      {
        id: 2,
        summary: t("assessment"),
        details: (
          <PCRAssessment
            pcr={pcr}
            id={2}
            handleCancelConfirmation={handleCancelConfirmation}
          />
        ),
      },
      {
        id: 3,
        summary: t("treatment"),
        details: (
          <PCRTreatment
            pcr={pcr}
            id={3}
            handleCancelConfirmation={handleCancelConfirmation}
          />
        ),
      },
      {
        id: 4,
        summary: t("transferOfCare"),
        details: (
          <PCRTransferOfCare
            pcr={pcr}
            id={4}
            handleCancelConfirmation={handleCancelConfirmation}
          />
        ),
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "white", padding: "1rem" }}>
      {/* <Button
        label="Toggle All"
        onClick={toggleAllPanels}
        variant="brand"
        style={{ marginBottom: "1rem" }}
      /> */}
      <Accordion id="base-example-accordion">
        {itemsList?.items?.map((ele) => (
          <AccordionPanel
            expanded={!!expanded[ele.id]}
            id={ele.id}
            key={ele.id}
            onTogglePanel={() => togglePanel(ele)}
            summary={ele.summary}
          >
            {ele.details}
          </AccordionPanel>
        ))}
      </Accordion>
    </div>
  );
};