import React, { useEffect, useState } from "react";
import { Button, Popover } from '@salesforce/design-system-react';
import { useTranslation } from "react-i18next";
import "css/comboBox.css";
import "css/PCRMedicationItem.css";
import PCRTextInput from "components/common/PCRTextInput";
import PCRComboBox from "components/common/PCRComboBox";
import {
  medicationNameOptions,
  unitOptions,
  routeOptions,
  effectOptions,
  successOptions
} from "data/constants";
import PCRMedicationsTooltipTable from "./PCRMedicationsTooltipTable";

const PCRMedicationItem = ({ index, updateRowData, updatePCRContext }) => {
  const { t } = useTranslation();
  const [medicationName, setMedicationName] = useState("");
  const [administeredTime, setAdministeredTime] = useState("");
  const [dose, setDose] = useState("");
  const [unit, setUnit] = useState("");
  const [route, setRoute] = useState("");
  const [amountDiscarded, setAmountDiscarded] = useState("");
  const [amountDiscardedWitness, setWitnessInitials] = useState("");
  const [amountDiscardedIdNumber, setWitnessID] = useState("");
  const [effect, setEffect] = useState("");
  const [doubleCheck, setDoubleCheck] = useState("");
  const [initial, setInitial] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [isRowActive, setIsRowActive] = useState(false);
  const [errorText, setErrorText] = useState({
    administeredTime: "",
    dose: "",
    amountDiscarded: "",
    amountDiscardedWitness: "",
    initial: "",
  });

  // List of medications that require Witness and ID#
  const specialMedications = ["KETAMINE", "MIDAZOLAM (Versed)", "FENTANYL"];

  // Handle selection change for Medications name
  const handleMedicationNameChange = (selected) => {
    const medicationValue = selected.label || selected;
    setMedicationName(medicationValue);
    updateRowData(`medicationName-${index}`, medicationValue);
    updatePCRContext({ [`medicationName-${index}`]: medicationValue });
  };

  // Validation for HH:MM format in 24h clock
  const handleAdministeredTimeChange = (e) => {
    const timeValue = e?.administeredTime || "";
    setAdministeredTime(timeValue);
    let errorTextTime = "";
    const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    // Validate if the format is correct
    if (timeValue.length > 0 && timeValue.length !== 5) {
      errorTextTime = t("invalidFormat");
        setErrorText(t(""));
    } else if (timeValue.length === 5 && !timePattern.test(timeValue)) {
      errorTextTime = t("invalidFormat");
    } else {
      errorTextTime = "";
    }

    setErrorText((prevErrors) => ({
      ...prevErrors,
      administeredTime: errorTextTime
    }));

    // Update the parent component with the new administered time value
    updateRowData(`administeredTime-${index}`, timeValue);
    updatePCRContext({ [`administeredTime-${index}`]: timeValue });
  };

  // Validation for numeric dose (allows one decimal point) and must be > 0
  const handleDoseChange = (e) => {
    const doseValue = e?.dose || "";
    const numericPattern = /^[0-9]+(\.[0-9])?$/;
    let errorTextDose = "";
   
    // Validate the dose value (must be numeric and > 0)
    if (doseValue && (!numericPattern.test(doseValue) || parseFloat(doseValue) <= 0)) {
      errorTextDose = t('invalidFormat');
    }

    setDose(doseValue);
    setErrorText((prevErrors) => ({
      ...prevErrors,
      dose: errorTextDose,
    }));
    updateRowData(`dose-${index}`, doseValue);
    updatePCRContext({ [`dose-${index}`]: doseValue });

  };

  // Handle selection change for Unit dropdown
  const handleUnitChange = (selected) => {
    const unitValue = selected.label || selected;
    setUnit(unitValue);
    updateRowData(`unit-${index}`, unitValue);
    updatePCRContext({ [`unit-${index}`]: unitValue });
  };

  // Handle selection change for Route dropdown
  const handleRouteChange = (selected) => {
    const routeValue = selected.label || selected;
    setRoute(routeValue);
    updateRowData(`route-${index}`, routeValue);
    updatePCRContext({ [`route-${index}`]: routeValue });
  };

  // Validation for "Amount discarded" numeric value > 0 with one decimal point
  const handleAmountDiscardedChange = (e) => {
    const amountValue = e?.amountDiscarded || "";
    const numericPattern = /^[0-9]+(\.[0-9])?$/;
    let errorTextAmount = "";

    if (amountValue && (!numericPattern.test(amountValue) || parseFloat(amountValue) <= 0)) {
      errorTextAmount = t('invalidFormat');
    }

    setAmountDiscarded(amountValue);
    setErrorText((prevErrors) => ({
      ...prevErrors,
      amountDiscarded: errorTextAmount,
    }));

    // Clear witness fields if amount discarded is cleared
    if (!amountValue) {
      setWitnessInitials("");
      setWitnessID("");
    }

    updateRowData(`amountDiscarded-${index}`, amountValue);
    updatePCRContext({ [`amountDiscarded-${index}`]: amountValue });
  };

  // Handle "Witness Initials" alphabetic validation
  const handleWitnessInitialsChange = (e) => {
    const initialValue = e?.amountDiscardedWitness || "";
    const value = initialValue.replace(/[^a-zA-Z]/g, '');
    setWitnessInitials(value);

    setErrorText((prevErrors) => ({
      ...prevErrors,
      amountDiscardedWitness: /[^a-zA-Z]/.test(initialValue)
        ? t('invalidFormat')
        : ""
    }));

    updateRowData(`amountDiscardedWitness-${index}`, value);
    updatePCRContext({ [`amountDiscardedWitness-${index}`]: value });
  };

  // Handle "Witness ID#" field
  const handleWitnessIDChange = (e) => {
    const idValue = e?.amountDiscardedIdNumber || "";
    setWitnessID(idValue);
    updateRowData(`amountDiscardedIdNumber-${index}`, idValue);
    updatePCRContext({ [`amountDiscardedIdNumber-${index}`]: idValue });
  };

  // Handle Effect dropdown change
  const handleEffectChange = (selected) => {
    const effectValue = selected.label || selected;
    setEffect(effectValue);
    updateRowData(`effect-${index}`, effectValue);
    updatePCRContext({ [`effect-${index}`]: effectValue });
  };

  // Handle Double Check dropdown change
  const handleDoubleCheckChange = (selected) => {
    const doubleCheckValue = selected.label || selected;
    setDoubleCheck(doubleCheckValue);
    updateRowData(`doubleCheck-${index}`, doubleCheckValue);
    updatePCRContext({ [`doubleCheck-${index}`]: doubleCheckValue });
  };

  // Handle alphabetic validation for Initial
  const handleInitialChange = (e) => {
    const initialValue = e?.initial || "";
    const value = initialValue.replace(/[^a-zA-Z]/g, '');
    setInitial(value);

    setErrorText((prevErrors) => ({
      ...prevErrors,
      initial: /[^a-zA-Z]/.test(initialValue)
        ? t('invalidFormat')
        : ""
    }));

    updateRowData(`initial-${index}`, value);
    updatePCRContext({ [`initial-${index}`]: value });
  };

  // Handle change for ID#
  const handleIdNumberChange = (e) => {
    const value = e?.idNumber || "";
    setIdNumber(value);
    updateRowData(`idNumber-${index}`, value);
    updatePCRContext({ [`idNumber-${index}`]: value });
  };

  // Function to check if any field has a value
  const checkRowActivation = () => {
    return (
      medicationName ||
      administeredTime ||
      dose ||
      unit ||
      route ||
      amountDiscarded ||
      amountDiscardedWitness ||
      amountDiscardedIdNumber ||
      effect ||
      doubleCheck ||
      initial ||
      idNumber
    );
  };

  // UseEffect to track field changes and activate the row if any value is entered
  useEffect(() => {
    if (checkRowActivation()) {
      setIsRowActive(true); // Activate the row when any field has a value
    } else {
      setIsRowActive(false); // Deactivate the row when no field has a value
    }
  }, [
    medicationName,
    administeredTime,
    dose,
    unit,
    route,
    amountDiscarded,
    amountDiscardedWitness,
    amountDiscardedIdNumber,
    effect,
    doubleCheck,
    initial,
    idNumber,
  ]);

  return (
    <>
          {/* Medications name */}
          <label className="slds-form-element__label" htmlFor={`medicationName-${index}`}  style={{width: '14rem'}}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}Name
            <>
              <Popover
                body={<PCRMedicationsTooltipTable />}
                id="medication-popover"
                align="top left"
              >
                <Button
                  assistiveText={{ icon: 'Medication Signs info' }}
                  iconCategory="utility"
                  iconName="info"
                  iconSize="medium"
                  iconVariant="bare"
                  variant="icon"
                />
              </Popover>
            </>
            <PCRComboBox
              id={`medicationName-${index}`}
              options={medicationNameOptions}
              value={medicationName}
              placeholder="--"
              onChange={handleMedicationNameChange}
              onSelect={(val) => handleMedicationNameChange(val.medicationName)}
              selection={medicationName ? [{ id: medicationName, label: medicationName }] : []}
              name='medicationName'
              required={isRowActive}
            />
          </label>

          {/* Administered Time */}
          <label className="slds-form-element__label" htmlFor={`administeredTime-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationTime")}
            <PCRTextInput
              id={`administeredTime-${index}`}
              placeholder="HH:MM"
              value={administeredTime}
              onChange={handleAdministeredTimeChange}
              errorText={errorText.administeredTime}
              required={isRowActive}
              name="administeredTime"
            />
          </label>

          {/* Dose */}
          <label className="slds-form-element__label" htmlFor={`dose-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationDose")}
            <PCRTextInput
              id={`dose-${index}`}
              value={dose}
              onChange={handleDoseChange}
              errorText={errorText.dose}
              required={isRowActive}
              type="number"
              name="dose"
            />
          </label>

          {/* Unit */}
          <label className="slds-form-element__label" htmlFor={`unit-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationUnit")}
            <PCRComboBox
              id={`unit-${index}`}
              options={unitOptions}
              value={unit}
              placeholder="--"
              onChange={handleUnitChange}
              onSelect={(val) => handleUnitChange(val.unit)}
              selection={unit ? [{ id: unit, label: unit }] : []}
              name="unit"
              required={isRowActive}
            />
          </label>

          {/* Route */}
          <label className="slds-form-element__label" htmlFor={`route-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationRoute")}
            <PCRComboBox
              id={`route-${index}`}
              options={routeOptions}
              value={route}
              placeholder="--"
              onChange={handleRouteChange}
              onSelect={(val) => handleRouteChange(val.route)}
              selection={route ? [{ id: route, label: route }] : []}
              name="route"
              required={isRowActive}
            />
          </label>

          {/* Amount Discarded */}
          <div style={{ marginTop : "-5px"}}>
          <label className="slds-form-element__label" htmlFor={`amountDiscarded-${index}`}>
            <PCRTextInput
              id={`amountDiscarded-${index}`}
              value={amountDiscarded}
              onChange={handleAmountDiscardedChange}
              errorText={errorText.amountDiscarded}
              required={isRowActive}
              name="amountDiscarded"
              label={t("medicationAmountDiscarded")}
              
            />
          </label>
          </div>

          {/* Show Witness and ID# fields only if special medication is selected and amount discarded is entered */}
          {specialMedications.includes(medicationName) && amountDiscarded && (
            <>
              {/* Witness Initials */}
              <label className="slds-form-element__label" htmlFor={`amountDiscardedWitness-${index}`}>
                <span style={{ color: "red" }}>*</span>{t("medicationAmountDiscardedWitness")}
                <PCRTextInput
                  id={`amountDiscardedWitness-${index}`}
                  placeholder="Initials"
                  value={amountDiscardedWitness}
                  onChange={handleWitnessInitialsChange}
                  errorText={errorText.amountDiscardedWitness}
                  required={true}
                  name="amountDiscardedWitness"
                />
              </label>

              {/* Witness ID# */}
              <label className="slds-form-element__label" htmlFor={`amountDiscardedIdNumber-${index}`} style={{marginTop: '1.4rem'}}>
                <PCRTextInput
                  id={`amountDiscardedIdNumber-${index}`}
                  placeholder="ID#"
                  value={amountDiscardedIdNumber}
                  onChange={handleWitnessIDChange}
                  required={true}
                  name="amountDiscardedIdNumber"
                />
              </label>
            </>
          )}

          {/* Effect */}
          <label className="slds-form-element__label" htmlFor={`effect-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationEffect")}
            <PCRComboBox
              id={`effect-${index}`}
              options={effectOptions}
              value={effect}
              placeholder="--"
              onChange={handleEffectChange}
              onSelect={(val) => handleEffectChange(val.effect)}
              selection={effect ? [{ id: effect, label: effect }] : []}
              name="effect"
              required={isRowActive}
            />
          </label>

          {/* Double Check */}
          <label className="slds-form-element__label" htmlFor={`doubleCheck-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationDoubleCheck")}
            <PCRComboBox
              id={`doubleCheck-${index}`}
              options={successOptions}
              value={doubleCheck}
              placeholder="--"
              onChange={handleDoubleCheckChange}
              onSelect={(val) => handleDoubleCheckChange(val.doubleCheck)}
              selection={doubleCheck ? [{ id: doubleCheck, label: doubleCheck }] : []}
              name="doubleCheck"
              required={isRowActive}
            />
          </label>

          {/* Initial */}
          <label className="slds-form-element__label" htmlFor={`initial-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationInitial")}
            <PCRTextInput
              id={`initial-${index}`}
              value={initial}
              onChange={handleInitialChange}
              errorText={errorText.initial}
              required={isRowActive}
              name="initial"
            />
          </label>

          {/* ID# */}
          <label className="slds-form-element__label" htmlFor={`idNumber-${index}`}>
            {isRowActive && <span style={{ color: "red" }}>*</span>}{t("medicationID#")}
            <PCRTextInput
              id={`idNumber-${index}`}
              value={idNumber}
              onChange={handleIdNumberChange}
              required={isRowActive}
              name="idNumber"
            />
          </label>
        </>
  );
};

export default PCRMedicationItem;