import { Button } from "@salesforce/design-system-react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import "../css/landing.css";
import { useUsers } from "../context/UserContext";
import AddCrewMember from "../components/AddCrewMember";
import FinishShift from "../components/FinishShift";

const Landing = () => {
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const { state } = useUsers();
  const { authorize } = useAuth();
  const isOnline = useNetworkStatus();
  const { t } = useTranslation();

  useEffect(() => {
    const checkTokenAvailability = () => {
      setIsTokenAvailable(!!localStorage.getItem("sf_access_token"));
    };

    checkTokenAvailability();
    window.addEventListener('storage', checkTokenAvailability);

    return () => {
      window.removeEventListener('storage', checkTokenAvailability);
    };
  }, []);

  const handleLogin = () => {
    authorize();
  };

  return (
    <>
      {/* header - green bar */}
      <div className="top-header df">
        <div className="landingWelcome">{t("welcome")}</div>
        <div className="anb-image" />
      </div>

      {/* main-content */}
      <div className="main-content slds-m-top_small">
        {/* First Section */}
        <div className="slds-grid slds-wrap">

          {/* Left Image */}
          <div className="slds-col slds-size_1-of-2">
            <img src={require("../assets/left_Landing_Image.jpg")} alt="landing" />
          </div>

          {/* Right Content */}
          <div className="slds-col slds-size_1-of-2 slds-align_absolute-center">
            <div className="right-content">
              {/* Heading */}
              <h2 className="shift_headers">{t("startShift_header")}</h2>
              
              {/* Green Line */}
              <hr className="green-line" />
              
              {/* Content */}
              <p>{t("landingPara1")}</p>
              <br />
              <p>{t("landingPara3")}</p>

              {/* Add Crew Member Button */}
              {isTokenAvailable && (
                <div className="slds-m-top_large">
                  <AddCrewMember />
                </div>
              )}

              {/* Login Button */}
              {!isTokenAvailable && (
                <>
                  <br />
                  <p>{t("landingPara2")}</p>
                  <div className="login-button-container slds-m-top_medium slds-grid slds-wrap">
                    <div className="slds-col slds-size_1-of-1">
                      <div className="slds-align_absolute-center">
                        <Button
                          disabled={!isOnline}
                          variant="outline-brand"
                          onClick={handleLogin}
                        >
                          {t("login")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="slds-grid slds-wrap">

          {/* Right Image */}
          <div className="slds-col slds-size_1-of-2 slds-order_2">
            <img src={require("../assets/right_Landing_Image.jpg")} alt="landing" />
          </div>  

          {/* Left Content */}
          <div className="slds-col slds-size_1-of-2 slds-align_absolute-center slds-order_1">
            <div className="right-content">
              {/* Heading */}
              <h2 className="shift_headers">{t("endShift_header")}</h2>
              
              {/* Green Line */}
              <hr className="green-line" />
              
              {/* Content */}
              <p>{t("landingPara4")}</p>
              <br />
              <p>{t("landingPara5")}</p>

              {/* Finish Shift Button */}
              <div className="slds-m-top_large">
                <FinishShift />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
