import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PCRTextInput from '../../../../common/PCRTextInput';
import PCRComboBox from '../../../../common/PCRComboBox';
import {
    successOptions
} from '../../../../../data/constants';
import PCRTimePicker from '../../../../common/PCRTimePicker';

const EMSDefibrillation = ({ index, updateRowData, updatePCRContext }) => {
    const { t } = useTranslation();
    const [emsDefibTime, setEmsDefibTime] = useState("");
    const [joules, setJoules] = useState("");
    const [pulse, setPulse] = useState("");
    const [initial, setInitial] = useState("");
    const [id, setId] = useState("");
    const [errorText, setErrorText] = useState("");

    // Handle time input change with validation
    const handleTimeChange = (e) => {
        const timeValue = e?.emsDefibTime || "";
        setEmsDefibTime(timeValue);

        // Regular e Fxpression for HH:MM time validation
        const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

        // If the field is not empty and length is less than 5, show a generic format error
        if (timeValue.length > 0 && timeValue.length !== 5) {
            setErrorText(t("invalidFormat"));
        }
        // When length is exactly 5, check for correct time format
        else if (timeValue.length === 5 && !timePattern.test(timeValue)) {
            setErrorText(t("invalidFormat"));
        }
        // Clear the error if no issues are found
        else {
            setErrorText("");
        }

        // Update parent with the new time value
        updateRowData(`emsDefibTime-${index}`, timeValue);
        updatePCRContext({ [`emsDefibTime-${index}`]: timeValue });
    };

    // Handle numeric validation for Joules
    const handleJoulesChange = (e) => {
        const joulesValue = e?.joules || "";
        const value = joulesValue.replace(/[^1-9]/g, '');
        if(value > 0){
            setJoules(value);
            updateRowData(`joules-${index}`, value);
            updatePCRContext({ [`joules-${index}`]: value });
        }
       
    };

    // Handle Pulse selection from dropdown
    const handlePulseChange = (selected) => {
        const pulseValue = selected.label || selected;
        setPulse(pulseValue);
        updateRowData(`pulse-${index}`, pulseValue);
        updatePCRContext({ [`pulse-${index}`]: pulseValue });
    };

    // Handle alphabet validation for Initial
    const handleInitialChange = (e) => {
        const initialValue = e?.initial || "";
        const value = initialValue.replace(/[^a-zA-Z]/g, '');
        setInitial(value);
        updateRowData(`initial-${index}`, value);
        updatePCRContext({ [`initial-${index}`]: value });
    };

    // Handle ID change
    const handleIdChange = (e) => {
        const idValue = e?.id || "";
        setId(idValue);
        updateRowData(`id-${index}`, idValue);
        updatePCRContext({ [`id-${index}`]: idValue });
    };

    return (
        <div className='pcr-row'>
            {/* Time */}
            <PCRTimePicker
                id={`emsDefibTime-${index}`}
                label={t("EMSDefibrillationTime")}
                placeholder="HH:MM"
                value={emsDefibTime}
                onChange={handleTimeChange}
                pattern="([01][0-9]|2[0-3]):[0-5][0-9]"
                errorText={errorText}
                required={false}
                name="emsDefibTime"
            />

            {/* Joules */}
            <PCRTextInput
                id={`joules-${index}`}
                label={t("EMSDefibrillationJoules")}
                value={joules}
                onChange={handleJoulesChange}
                required={false}
                name='joules'
            />

            {/* Pulse */}
            <label className="slds-form-element__label" htmlFor={`pulse-${index}`}>
                {t("EMSDefibrillationPulse")}
                <PCRComboBox
                    id={`pulse-${index}`}
                    options={successOptions}
                    value={pulse}
                    placeholder="--"
                    onChange={handlePulseChange}
                    onSelect={(val) => handlePulseChange(val.pulse)}
                    selection={pulse ? [{ id: pulse, label: pulse }] : []}
                    name='pulse'
                    required={false}
                />
            </label>

            {/* Initial */}
            <PCRTextInput
                id={`initial-${index}`}
                label={t("EMSDefibrillationInitial")}
                value={initial}
                onChange={handleInitialChange}
                errorText={initial && /[^a-zA-Z]/.test(initial) ? t('invalidFormat') : ""}
                required={false}
                name='initial'
            />

            {/* ID# */}
            <PCRTextInput
                id={`id-${index}`}
                label={t("EMSDefibrillationID")}
                value={id}
                onChange={handleIdChange}
                required={false}
                name='id'
            />
        </div>
    );
};

export default EMSDefibrillation;