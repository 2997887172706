import React, { useState, useEffect, useRef } from "react";
import { Combobox, Icon } from "@salesforce/design-system-react";
import "../../css/comboBox.css";
import { useTranslation } from "react-i18next";

const PCRComboBox = ({
  id,
  onChange,
  onRequestRemoveSelectedOption,
  onSelect,
  label,
  required,
  options,
  value,
  errorText = "",
  menuItemVisibleLength = 7,
  filterAttribute = "label",
  name = "",
  selectAttribute = "label",
  saveOnBlur = true,
  lowerCaseRequired = true,
  placeholder = "select",
  disabled = false,
  onerror = () => {}
}) => {
  const { t } = useTranslation();
  const [searchLocIsOpen, setSearchLocIsOpen] = useState(false);
  const [internalErrorText, setInternalErrorText] = useState(errorText);
  const [touched, setTouched] = useState(false);
  const prevRequiredRef = useRef(required);
  
  // Handle Blur Event

  const onBlur = () => {
    if (saveOnBlur && !disabled) {
        if (required && !value) {
          setInternalErrorText(t("inputIsRequiredError"));
          onerror(t("inputIsRequiredError"));
        } 
        if (required && value) {
          setInternalErrorText("");
          onerror("");
        }

      if (!value && !required) {
        onSelect({
          [name]: "",
        });
      } else {
        const isMatchFound = options.some(
          (o) => o?.[filterAttribute] === value
        );
        if (!isMatchFound) {
          onSelect({
            [name]: "",
          });
        }
        // setInternalErrorText("");
      }
      setSearchLocIsOpen(false);
    }
  };

  // Handle when the combobox is focused

  const handleFocus = () => {
    if (!disabled) {
      setSearchLocIsOpen(true);
      setTouched(true);
    }
  };

  // Handle when the combobox is selected
  const handleSelect = (event, data) => {
    if (!disabled) {
      onSelect({
        [name]: data?.selection[0]?.[selectAttribute] || "",
      });
      setSearchLocIsOpen(false);
      setInternalErrorText("");
    }
  };

  // Handle validation only if the field has been touched
  useEffect(() => {
    if (required && !value && touched) {
      setInternalErrorText(t("inputIsRequiredError"));
      onerror(t("inputIsRequiredError"));
    } else {
      setInternalErrorText("");
      onerror("");

    }
  }, [required, value, touched, t]);

  // Reset touched state when field becomes required again
  // useEffect(() => {
  //   if (prevRequiredRef.current === false && required === true) {
  //     setTouched(false);
  //   }
  //   prevRequiredRef.current = required;
  // }, [required]);

  return (
    <div className={`combo-box-wrapper ${disabled ? "disabled" : ""}`} id="pcr-combo-box">
      <Combobox
        id={id}
        isOpen={searchLocIsOpen && !disabled}
        disabled={disabled}
        events={{
          onChange: ({ target: { value } }) => !disabled && onChange(value),
          onRequestRemoveSelectedOption,
          onSelect: handleSelect,
          onFocus: handleFocus,
          onBlur,
        }}
        labels={{
          label,
          placeholder: placeholder,
        }}
        menuItemVisibleLength={menuItemVisibleLength}
        options={
          lowerCaseRequired
            ? (typeof value === "string" ? value?.toLowerCase() : "")
              ? options?.filter((op) =>
                op?.[filterAttribute]
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
              )
              : options
            : options
        }
        value={value}
        required={required}
        errorText={errorText ? errorText : internalErrorText}
        name={name}
        placeHolder=""
      />

      <div className={label ? "combo-box-close df" : "combo-box-nolabel df"}>
        {value && (
          <span
            onClick={() => {
              if (!disabled) {
                onSelect({
                  [name]: "",
                });
              }
            }}
            style={{
              paddingRight: ".5rem",
              paddingLeft: ".5rem",
              cursor: "pointer",
            }}
          >
            <Icon
              assistiveText={{ label: "Clear Text" }}
              category="utility"
              name="close"
              size="xx-small"
            />
          </span>
        )}

        {searchLocIsOpen && !disabled ? (
          <span
            onClick={() => {
              setSearchLocIsOpen(false);
            }}
          >
            <Icon
              assistiveText={{ label: "Down chevron" }}
              category="utility"
              name="chevronup"
              size="xx-small"
            />
          </span>
        ) : (
          <span
            onClick={() => {
              if (!disabled) {
                setSearchLocIsOpen(true);
              }
            }}
          >
            <Icon
              assistiveText={{ label: "Down chevron" }}
              category="utility"
              name="chevrondown"
              size="xx-small"
            />
          </span>
        )}
      </div>

    </div>
  );
};

export default PCRComboBox;
