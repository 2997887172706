import { useNavigate } from "react-router-dom";
import { getLanguage } from "../utils/util.js";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useContext } from 'react';
import { useUsers } from '../context/UserContext.js';
import { fetchPCRList, UpdateDatabaseWithPCRList } from '../utils/pcrDbUtil.js'; // Import PCR functions
import { useHandlePcrData } from './dataHandlingHooks/useHandlePCRData.js';


 /*const LOCAL_CONFIG = {
  PWA_REDIRECT_URL: "http://localhost:3000/az-callback",
  SF_URL: "https://ccnb-scnb--epcrdev.sandbox.my.salesforce.com/services",
  SF_CLIENT_ID: "3MVG9P7Pp4QrREPm4EkHavd5Xzm4mdhOecBrtA_AD8AkcbTdv5WZnNjYYtki_JG5uGH0fCGDETyCp4mQIVtCp",
  AD_TOKEN_URL: "https://login.microsoftonline.com",
  AD_TENANT_ID: "e08b7eef-b501-4a67-9ed0-07e38bfccee7",
  AD_SSO_PROVIDER: "GNB Azure AD SSO - Test Tenant",
  AD_APP_CLIENT_ID: "6eb7e954-33ff-4d31-bd01-c955d8a1866f",
  AD_SCOPE: "openid profile email",
  TOKEN_HANDLER: "ANB_ADTokenExchangeHandler",
  REACT_APP_CONFIG: "LOCAL_CONFIG",
  REACT_APP_ENVIRONMENT: "Local"
};

const LOCAL_QA_CONFIG = {
  PWA_REDIRECT_URL: "http://localhost:3000/az-callback",
  SF_URL: "https://ccnb-scnb--epcrqa.sandbox.my.salesforce.com/services",
  SF_CLIENT_ID: "3MVG9gtjsZa8aaSXW4i7D6V8CdHW74W01i.9u05hNppu0TZlU1Lr00qW27BpFhiIRkj0ooQVLqM9tGbX_ilwZ",
  AD_TOKEN_URL: "https://login.microsoftonline.com",
  AD_TENANT_ID: "cd777b7e-0ab5-4ff6-9ab7-41f80826d268",
  AD_SSO_PROVIDER: "GNB Azure AD SSO - Test Tenant",
  AD_APP_CLIENT_ID: "0b665bc2-bf95-41b8-b8d9-71d6197b9cd5",
  AD_SCOPE: "email openid profile User.Read",
  TOKEN_HANDLER: "ANB_ADTokenExchangeHandler",
  REACT_APP_CONFIG: "LOCAL_QA_CONFIG",
  REACT_APP_ENVIRONMENT: "Local QA"
};

const DEV_CONFIG = {
  PWA_REDIRECT_URL: "https://anb-pcr-dsp-dev.gnb.ca/az-callback",
  SF_URL: "https://ccnb-scnb--epcrdev.sandbox.my.salesforce.com/services",
  SF_CLIENT_ID: "3MVG9P7Pp4QrREPm4EkHavd5Xzm4mdhOecBrtA_AD8AkcbTdv5WZnNjYYtki_JG5uGH0fCGDETyCp4mQIVtCp",
  AD_TOKEN_URL: "https://login.microsoftonline.com",
  AD_TENANT_ID: "e08b7eef-b501-4a67-9ed0-07e38bfccee7",
  AD_SSO_PROVIDER: "GNB Azure AD SSO - Test Tenant",
  AD_APP_CLIENT_ID: "6eb7e954-33ff-4d31-bd01-c955d8a1866f",
  AD_SCOPE: "openid profile email",
  TOKEN_HANDLER: "ANB_ADTokenExchangeHandler",
  REACT_APP_CONFIG: "DEV_CONFIG",
  REACT_APP_ENVIRONMENT: "Dev"
};

const SIT_CONFIG = {
  PWA_REDIRECT_URL: "https://anb-pcr-dsp-sit.gnb.ca/az-callback",
  SF_URL: "https://ccnb-scnb--epcrqa.sandbox.my.salesforce.com/services",
  SF_CLIENT_ID: "3MVG9gtjsZa8aaSXW4i7D6V8CdHW74W01i.9u05hNppu0TZlU1Lr00qW27BpFhiIRkj0ooQVLqM9tGbX_ilwZ",
  AD_TOKEN_URL: "https://login.microsoftonline.com",
  AD_TENANT_ID: "cd777b7e-0ab5-4ff6-9ab7-41f80826d268",
  AD_SSO_PROVIDER: "GNB Azure AD SSO - Test Tenant",
  AD_APP_CLIENT_ID: "0b665bc2-bf95-41b8-b8d9-71d6197b9cd5",
  AD_SCOPE: "email openid profile User.Read",
  TOKEN_HANDLER: "ANB_ADTokenExchangeHandler",
  REACT_APP_CONFIG: "SIT_CONFIG",
  REACT_APP_ENVIRONMENT: "SIT"
};

export const APP_CONFIG = DEV_CONFIG; // LOCAL_CONFIG, LOCAL_QA_CONFIG, DEV_CONFIG, or SIT_CONFIG */

export const APP_CONFIG = {
  PWA_REDIRECT_URL: process.env.REACT_APP_PWA_REDIRECT_URL,
  SF_URL: process.env.REACT_APP_SF_URL,
  SF_CLIENT_ID: process.env.REACT_APP_SF_CLIENT_ID,
  AD_TOKEN_URL: process.env.REACT_APP_AD_TOKEN_URL,
  AD_TENANT_ID: process.env.REACT_APP_AD_TENANT_ID,
  AD_SSO_PROVIDER: process.env.REACT_APP_AD_SSO_PROVIDER,
  AD_APP_CLIENT_ID: process.env.REACT_APP_AD_APP_CLIENT_ID,
  AD_SCOPE: process.env.REACT_APP_AD_SCOPE,
  TOKEN_HANDLER: process.env.REACT_APP_TOKEN_HANDLER,
  REACT_APP_CONFIG: process.env.REACT_APP_CONFIG,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
};

console.log('APP_CONFIG.REACT_APP_CONFIG >>>', APP_CONFIG.REACT_APP_CONFIG);

export const useAuth = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useUsers();
  const {setPcrRoot} = useHandlePcrData();
  //debugger;
  const tokenUrl = `${APP_CONFIG.AD_TOKEN_URL}/${APP_CONFIG.AD_TENANT_ID}/oauth2/v2.0/token`;
  const fetchADToken = async (code) => {
    if (localStorage.getItem('ad_access_token') || localStorage.getItem('fetching_token')) {
      return;
    }
    localStorage.setItem('fetching_token', 'true');

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

      const response = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "authorization_code",
          client_id: APP_CONFIG.AD_APP_CLIENT_ID,
          redirect_uri: APP_CONFIG.PWA_REDIRECT_URL,
          code: decodeURIComponent(code),
          code_verifier: localStorage.getItem("codeVerifier"),
          scope: APP_CONFIG.AD_SCOPE,
          prompt: "login"
        }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      if (data?.access_token) {
        const ad_access_token = data?.access_token;
        const ad_id_token = data?.id_token;
        console.log('ad_id_token >>>', ad_id_token);
        localStorage.setItem('ad_access_token', ad_access_token);
        localStorage.setItem('ad_id_token', ad_id_token);
        await fillUsersTableRecordFromTokenPayload(ad_access_token);
        await fillLoginHint(ad_id_token);
        await sfTokenExchnageProcess(ad_access_token);
      } else {
        throw new Error("No access token received");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      if (error.name === 'AbortError') {
        console.error("Request timed out");
      }
    } finally {
      localStorage.removeItem('fetching_token');
    }
  };

  const fillLoginHint = async (ad_id_token) => {
    const tokenDecoded = jwtDecode(ad_id_token);
    const loginHint = tokenDecoded.login_hint;
    const userFederationId = tokenDecoded.oid;
    const users = JSON.parse(localStorage.getItem('users'));
    const userIndex = users.findIndex(user => user.federationId === userFederationId);
    if (userIndex !== -1) {
      users[userIndex].login_hint = loginHint;
      localStorage.setItem('users', JSON.stringify(users));
    }
  }

  // Logout from AD
  function logoutFromAD(user) {
    try {
      const loginHint = user.login_hint;
      const clientId = APP_CONFIG.AD_APP_CLIENT_ID;
      if (!loginHint || !clientId) {
        throw new Error("Login hint or client id is missing");
      }
      const tenantId = APP_CONFIG.AD_TENANT_ID;
      const logoutUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/logout?logout_hint=${loginHint}&client_id=${clientId}&post_logout_redirect_uri=${encodeURIComponent(window.location.origin)}`;
      window.location.href = logoutUrl;

      return true;
    }
    catch (error) {
      console.error("Failed to logout from Microsoft:", error);
      return false;
    }
  }

  const fillUsersTableRecordFromTokenPayload = async (ad_token) => {
    const tokenDecoded = jwtDecode(ad_token);
    const userInstance = {
      title: '',
      fullName: (tokenDecoded.given_name + ' ' + tokenDecoded.family_name) || '',
      federationId: tokenDecoded.oid || '',
      employeeId: '',
      ad_access_token: ad_token
    };
    console.log('fillUsersTableRecordFromTokenPayload() > userInstance >>>', userInstance);

    if (userInstance.federationId) {
      const users = JSON.parse(localStorage.getItem('users') || '[]');
      const existingUserIndex = users.findIndex(user => user.federationId === userInstance.federationId);
      if (existingUserIndex === -1) {
        users.push(userInstance);
      } else {
        users[existingUserIndex].ad_access_token = ad_token;
      }
      localStorage.setItem('users', JSON.stringify(users));
    }
  }

  const updateUserSfAccessTokenPropertyUsingFederationId = async (details) => {
    const users = JSON.parse(localStorage.getItem('users') || '[]');
    const userIndex = users.findIndex(user => user.federationId === details.federationId);
    if (userIndex !== -1) {
      users[userIndex].sf_access_token = details.sf_access_token;
      localStorage.setItem('users', JSON.stringify(users));
    }
  }

  const sfTokenExchnageProcess = async (adAccessToken) => {
    try {
      const tokenDecoded = jwtDecode(adAccessToken);
      let userFederationId = tokenDecoded?.oid || '';

      // get the salesforce access token
      const response = await fetch(`${APP_CONFIG.SF_URL}/oauth2/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
          subject_token_type: "urn:ietf:params:oauth:token-type:access_token",
          client_id: APP_CONFIG.SF_CLIENT_ID,
          token_handler: APP_CONFIG.TOKEN_HANDLER,
          subject_token: adAccessToken
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data?.id && data?.access_token) {
        // store salesforce access token in storage
        const sf_access_token = data.access_token;
        localStorage.setItem("sf_access_token", sf_access_token);

        await updateUserSfAccessTokenPropertyUsingFederationId({ federationId: userFederationId, sf_access_token: sf_access_token });

        const userDetailResponse = await getUserDetailsByFederationId(userFederationId);
        console.log('userDetailResponse >>>', userDetailResponse);

        /* 71 */
        // Fetch PCRs using the employeeId (medicId) obtained from userDetailResponse
        if (userDetailResponse?.employeeId) {
          const medicIdList = [userDetailResponse.employeeId];
          const pcrList = await fetchPCRList(getLanguage(), medicIdList);
          pcrList.forEach(list => list = setPcrRoot(list));
      
          // Update the local database (IndexedDB) with the fetched PCRs
          setTimeout(async () => await UpdateDatabaseWithPCRList(pcrList,setPcrRoot), 200);
          console.log('PCRs fetched and stored successfully for medicId:',pcrList);
        }
       

        return data;
      } else {
        console.error("User id not found!");
      }
    } catch (error) {
      console.error("sfTokenExchnageProcess() Error fetching user details:", error);
    }
  };


  ////////////////////////////////////////////////////////////

  const getUserDetailsByFederationId = async (userFederationId) => {
    const sf_access_token = localStorage.getItem("sf_access_token");
    if (!sf_access_token) {
      console.error("Salesforce access token not found");
      throw new Error("Salesforce access token not found");
    }

    const params = new URLSearchParams({
      federationId: userFederationId,
      language: getLanguage()
    });

    const sf_endpoint = `${APP_CONFIG.SF_URL}/apexrest/ANB/getUserDetails?${params}`;

    try {
      const response = await fetch(sf_endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sf_access_token}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const userDetails = await response.json();
      console.log('User Details:', JSON.stringify(userDetails));

      // Assuming user data is directly in userDetails object
      if (userDetails && userDetails.employeeId && userDetails.fullName && userDetails.federationId) {
        let users = JSON.parse(localStorage.getItem("users") || "[]");
        if (users.some(u => u.federationId === userFederationId)) {
          const userIndex = users.findIndex(u => u.federationId === userFederationId);
          users[userIndex].employeeId = userDetails.employeeId;
          users[userIndex].fullName = userDetails.fullName;
          users[userIndex].title = userDetails.title;
        } else {
          users.push(userDetails);
        }
        localStorage.setItem("users", JSON.stringify(users));
        navigate("/");
      } else {
        console.error("User data not found in response");
      }
      return userDetails;
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };

  const revokeSFToken = async (sfAccessToken) => {
    try {
      const sf_access_token = sfAccessToken;
      if (!sf_access_token) {
        console.error("No token found to revoke.");
        return false; // Early return if there's no token to revoke
      }
      // use SF url to revoke the token, pass token in body as parameter called 'token'
      const response = await axios.post(`${APP_CONFIG.SF_URL}/oauth2/revoke`, {
        token: sf_access_token
      }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      // Check if the response was successful
      if (response.status === 200) {
        console.log("Salesforce token successfully revoked.");
        return true;
      } else {
        console.error("Failed to revoke Salesforce token: Server responded with status", response.status);
        console.log("Salesforce token revoke response body >>>", response.data);
        return false;
      }
    } catch (error) {
      console.error("Error revoking Salesforce token:", error.response ? error.response.data : error.message);
      console.log("Salesforce token revoke response body >>>", error?.response?.data);
      return false;
    }
  };

  const logout = async () => {
    try {
      const sf_access_token = localStorage.getItem("sf_access_token");
      const response = await axios.post(
        `${APP_CONFIG.SF_URL}/auth/sp/saml2/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${sf_access_token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const authorize = async () => {
    try {
      let codeVerifier = localStorage.getItem("codeVerifier");
      if (!codeVerifier) {
        codeVerifier = await generateCodeVerifier();
        localStorage.setItem("codeVerifier", codeVerifier);
      }
      const codeChallenge = await generateCodeChallenge(codeVerifier);
      const url = await buildAuthorizationUrl(codeChallenge);
      window.location.href = url.toString();
    } catch (error) {
      console.error("Error during authorization:", error);
    }
  };

  const generateCodeVerifier = async () => {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array)
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  };

  const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };

  const buildAuthorizationUrl = async (codeChallenge) => {
    if (!codeChallenge) {
      console.error("Code challenge is required. Given codeChallenge >>>", codeChallenge);
      throw new Error("Code challenge is required");
    }
    const clientId = APP_CONFIG.AD_APP_CLIENT_ID;
    const redirectUri = encodeURIComponent(APP_CONFIG.PWA_REDIRECT_URL);
    const responseType = "code";
    const scope = encodeURIComponent(APP_CONFIG.AD_SCOPE);

    return (
      `https://login.microsoftonline.com/${APP_CONFIG.AD_TENANT_ID}/oauth2/v2.0/authorize?` +
      `client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&` +
      `code_challenge=${codeChallenge}&code_challenge_method=S256&prompt=login&sso_provider=${APP_CONFIG.AD_SSO_PROVIDER}`
    );
  };

  return {
    fetchADToken,
    authorize,
    revokeSFToken,
    logout,
    logoutFromAD
  };
};