import React, { useState, useRef } from "react";
import { Modal, Button, Icon, Textarea } from "@salesforce/design-system-react";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

const VerbalReportSignatureModal = ({ isOpen, onClose, onSign, onClearSignatureData }) => {
    const sigCanvas = useRef({});
    const [isSigned, setIsSigned] = useState(false);

    const clearSignature = () => {
        sigCanvas.current.clear();
        setIsSigned(false);
        onClearSignatureData();
    };

    const handleSign = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const currentDate = moment().format("YYYY-MMM-DD, HH:mm").toUpperCase();
        onSign(signature, currentDate);
        onClose();
    };

    return (
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                footer={[
                    <Button key="cancel" label="Cancel" onClick={onClose} />,
                    <Button
                        key="sign"
                        label="Sign"
                        variant="brand"
                        disabled={!isSigned}
                        onClick={handleSign}
                        style={{ marginRight: '5%' }}
                    />
                ]}
                title={
                    <span style={{display: 'flex', marginLeft: '5%'}}>
                        <Icon
                            assistiveText={{ label: "warning" }}
                            category="utility"
                            name="warning"
                            size="medium"
                            style={{ fill: "#fe9339", justifyContent: "flex-start" }}
                        />
                        <span style={{ fontSize: '20px', fontFamily: 'sans-serif', color: 'gray', justifyContent: "flex-start", marginLeft: '10px', marginTop: '1%' }}>{" Warning: Verbal Report Signature"}</span>
                    </span>
                }
            >
                <div>
                    <div style={{ fontSize: '16px', fontFamily: 'sans-serif', color: 'gray', marginLeft: '7%' }}>
                        <p>Please sign below to verify verbal report was received.</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginRight: '7%', position: "relative" }}>
                        <Button
                            label="Clear"
                            style={{
                                marginRight:'-2%',
                                background: "transparent",
                                border: "none",
                                fontSize: '14px',
                                fontFamily: 'sans-serif',
                                color: 'gray',
                                Outline: 'none',
                                boxShadow: 'none'
                            }}
                            variant="neutral"
                            onClick={clearSignature} />
                    </div>
                    <div style={{ border: "1px solid black", minWidth: "550px", maxWidth: '550px' ,height: "200px", alignItems: "center", marginBottom: "10px", marginLeft: '7%', marginRight: '7%' }}>
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{ width: 550, height: 200, className: "sigCanvas" }}
                            onEnd={() => setIsSigned(true)}
                        />
                    </div>
                </div>
            </Modal>
    );
};

export default VerbalReportSignatureModal;