import React, { useContext, useEffect,useState } from "react";
import { Button } from "@salesforce/design-system-react";
import IdentificationCallDetails from "./../components/PCR/PCR-Common/IdentificationCallDetails";
import IdentificationPatientDetails from "./PCR/PCR-AV/Identification/IdentificationPatientDetails";
import "../css/identification.css";
import "../css/comboBox.css";
import { PCRContext } from "../context/PCRContext";
import { useTranslation } from "react-i18next";
import IdentificationSVPatientDetails from "./PCR/PCR-SV/Identification/IdentificationSVPatientDetails";

const PCRIdentification = ({ pcr, id, handleCancelConfirmation }) => {
   
  const handleConfirm = () => handleCancelConfirmation(id);
  const {
    onSaveIdentificationTab,
    toggleAccordian,
    setToggleAccordian,
    setShowSoftValidationModal,
    showSoftValidationModal,
    pcrData, 
    setPCRData, 
    updatePCRData
  } = useContext(PCRContext);

  const { t } = useTranslation();
  const [showModal,setShowModal] = useState(false);

  useEffect(() => {
    if (showSoftValidationModal) {
      setShowModal(showSoftValidationModal)
    }
  }, [showSoftValidationModal]);

  useEffect(() => {
    updatePCRData(pcr);
  },[pcr])

  useEffect(() => {
    if(!showModal){
      setShowSoftValidationModal(false)
    }
  },[showModal]);

  useEffect(() => {
    if (toggleAccordian) {
      handleConfirm();
      setTimeout(() => {
        setToggleAccordian(false);
      }, 200);
    }
  }, [toggleAccordian]);

  const validateAndSave = () => {
    onSaveIdentificationTab();
  }; 

  const updatePCRContext = async (updatedAttrObj) => {
    const {identification} = pcrData;
    setPCRData({
      ...pcrData,
      identification: { ...identification, ...updatedAttrObj }
    });  
  };


  return (
    <>
      <div className="identification-wrapper">
        <IdentificationCallDetails pcr={pcr} />
        {pcr?.isAttendingCrew ? (
          <IdentificationPatientDetails
            pcr={pcr}
            handleCancelConfirmation={handleConfirm}
          />
        ) : (
          <IdentificationSVPatientDetails
            pcr={pcr}
            handleCancelConfirmation={handleConfirm}
          />
        )}
        <div className="df fdrr">
          <Button className="" variant="brand" onClick={validateAndSave}>
            {t("validate")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PCRIdentification;
