import React, { createContext, useEffect, useState } from "react";
import { FORM_TYPES, VALIDATION_TYPES } from "../data/constants.js";
import { useTranslation } from "react-i18next";
import { useIdentificationValidations } from "../hooks/useIdentificationValidations.js";
import { useAssesmentValidations } from "../hooks/useAssesmentValidations.js";
import { useTreatmentValidations } from "../hooks/useTreatmentValidations.js";
export const PCRContext = createContext();

export const vitalItem = {
  id: 0,
  vitalsTime: '',
  respiration: '',
  o2Sat: '',
  etco2: '',
  hr: '',
  bp: '',
  arm: '',
  eye: '',
  verbal: '',
  motor: '',
  gcsTotal: '',
  temperatureC: '',
  pain: '',
  glucose: '',
  rPupil: '',
  lPupil: ''
}

export const vascularItem = {
  vascTime: '',
  onScene: '',
  site: '',
  vascType: '',
  gauge: '',
  successful: '',
  salineLock: '',
  flowRateValue: '',
  flowRateType: '',
  mLInfused: '',
  initial: '',
  idNumber: ''
}

export const medicationItem = {
  medicationName: '',
  administeredTime: '',
  dose: '',
  unit: '',
  route: '',
  amountDiscarded: '',
  amountDiscardedWitness: '',
  amountDiscardedIdNumber: '',
  effect: '',
  doubleCheck: '',
  initial: '',
  idNumber: '',
}

export const airwayItem = {
  oxygenationDevice: "Select",
  oxygenFlowRate: '',
  oxygenDuration: '',
  ventilation: '',
  ventilationDevice: "Select",
  ventilationFlowRate: '',
  airwayOptions: '',
  cpapFlowRate: '',
  magillInitials: '',
  magillID: '',
  airwayACPOptions: '',
  peepValue: '',
  needleThoracostomyLocation: "Select",
  needleAttempts: '',
  cricothyrotomyType: "Select"
}

export const circulationAndMonitorItem = {
  dnarDirective: '',
  dnarLevel: '',
  receivedFrom: '',
  monitorSN: '',
  patientID: '',
  isECGAcquired: '',
  isECGTransmitted: '',
  isFibrinolyticChecklistComplete: '',
  vagalManeuverMethod: '',
  selectedTherapyType: '',
  electricalTherapyTime: '',
  electricalTherapyJoules: '',
  electricalTherapyPulse: '',
  mA: '',
  rate: '',
  cardiacArrest: '',
  electricalTherapyPulse: '',
  electricalTherapyPulse: '',
  electricalTherapyPulse: '',
  witnessArrest: '',
  witnessTime: '',
  cpr: '',
  cprTime: '',
  bystanderDefibrillation: '',
  bystanderDefibTime: '',
  responderDefibrillation: '',
  responderDefibTime: '',
  EMSDefibrillationTime: '',
  emsDefibTime: '',
  joules: '',
  pulse: '',
  initial: '',
  id: '',
  arrestEtiology: '',
  terminationOfCare: '',
  terminationTime: '',
  rosc: '',
  roscTime: '', 
}

export const otherTreatmentsItem = {
  obsDeliveryTime: '',
  obsDeliverySex: '',
  min1appearance: '',
  min1pulse: '',
  min1grimace: '',
  min1activity: '',
  min1RespEffort: '',
  min1Score: '',
  min2appearance: '',
  min2pulse: '',
  min2grimace: '',
  min2activity: '',
  min2RespEffort: '',
  min2Score: '',
  OLMCContacted: '',
  OLMCPhysicians: '',
  otherPhysician: '',
  OLMCReasons: '',
  otherReason: '',
  comments: '',
  OLMCContacted: '',
  strokeTraumaActivation: '',
  stroke: '',
  trauma: '',
  strokeTime: '',
  traumaDropdown: '',
  OLMCContacted: '',
  OLMCContacted: '',
  patientSeptic: '',
  suspectedInfection: '',
  temperature: '',
  respiratory: '',
  etco2: '',
  heartRate: ''
}

const initialPCRData = {
  isAttendingCrew: true,
  validateRequiredFields: false,
  identificationDetails: {
  },
  assessment: {
    wasTheActiveOfferMade: null,
    cheifComplaint: null,
    protocolGuideline: null,
    protocolGuidelineSecondary: null,
    patientAllergies: null,
    patientMedications: null,
    pastMedicalHistory: null,
    notesAndBackgroundInfo: null,
    traumaPatient: null,
    injuryLocation: null,
    burns: null
  },
  treatment: {
    vitals: [vitalItem],
    vasculars: [vascularItem],
    medications: [medicationItem],
    airwayAndBreathing: [airwayItem],
    circulationAndMonitor: [circulationAndMonitorItem],
    otherTreatments: [otherTreatmentsItem]
  },
  transferOfCare: {
    wasProperHandHygieneCompleted: null,
    arePatientBelongingsPresent: null,
    patientBelongingsGivenTo: "Select",
    otherSpecify: "",
    numberOfPatients: "",
    patientOffloadDelay: "",
    attendingPractitionerId: "",
    attendingPractitionerStation: "Select",
    supportingPractitionerId: "",
    supportingParamedicId: "",
    assistingPersonnel: [{ id: 0, assistingPersonnelName: "", assistingPersonnelPosition: "" }],
    verbalReportGivenToName: "",
    verbalReportGivenToPosition: "--",
    verbalReportGivenToSignature: "",
    PCRAccessGivenTo: "Select",
    EMPReferral: false,
    ReviewFlag: false,
    verbalReportOtherSpecify: "",
  },
  injuryTimeError: "",
  patientSexAtBirth: null,
  showSoftValidateModal: false,
};

const softValidateTree = {
  svAttending: [],
  svAir: [],
  svSupplementary: [],
};

export const evaluateError = (data, pcrData, obj, t) => {
  let errorState = {};
  obj?.validations.forEach((type) => {
    switch (type) {
      case VALIDATION_TYPES.VALUE_REQUIRED:
        // if value exists obj.type and obj.name are object type and name of each object formvalidation config
        if (pcrData[obj.type].hasOwnProperty(obj.name)) {
          // check if it is mandatory (can be found in formvalidation config)
          if (obj.shouldDisplayError) {
            if (!pcrData[obj.type][obj.name]) {
              errorState[`${obj.name}Error`] = t("inputIsRequiredError");
              // check to verify softvalidate

              // attending view
              if (obj?.softValidateAttending) {
                softValidateTree.svAttending.push(`${obj.name}Error`);
              }

              // supplementary
              if (obj?.softValidateSV) {
                softValidateTree.svSupplementary.push(`${obj.name}Error`);
              }

              // AIR
              if (obj?.softValidateAir) {
                softValidateTree.svAir.push(`${obj.name}Error`);
              }
            }
            errorState[`${obj.name}Error`] = !pcrData[obj.type][obj.name]
              ? t("inputIsRequiredError")
              : "";

            if (
              obj.name === "numberOfPatientsAssessed" &&
              pcrData[obj.type][obj.name] === 0
            ) {
              errorState[`${obj.name}Error`] = "";
            }
          } else {
            errorState[`${obj.name}Error`] = "";
          }
        }

        break;

      case VALIDATION_TYPES.SELECTION_REQUIRED:
        if (pcrData[obj.type].hasOwnProperty(obj.name)) {
          if (obj.shouldDisplayError) {
            if (!pcrData[obj.type][obj.name]) {
              errorState[`${obj.name}Error`] = t("inputSelectionRequiredError");

              // attending view
              if (obj?.softValidateAttending) {
                softValidateTree.svAttending.push(`${obj.name}Error`);
              }

              // supplementart
              if (obj?.softValidateSV) {
                softValidateTree.svSupplementary.push(`${obj.name}Error`);
              }

              // AIR
              if (obj?.softValidateAir) {
                softValidateTree.svAir.push(`${obj.name}Error`);
              }
            }
            errorState[`${obj.name}Error`] = !pcrData[obj.type][obj.name]
              ? t("inputIsRequiredError")
              : "";
          } else {
            errorState[`${obj.name}Error`] = "";
          }
        }

        break;
      default:
        break;
    }
  });

  return errorState;
};

export const PCRContextProvider = (props) => {// Load initial data from localStorage if available
  /* 71 */
  const [pcrData, setPCRData] = useState(() => {
    const savedPCRData = localStorage.getItem("pcrData");
    return savedPCRData ? JSON.parse(savedPCRData) : initialPCRData;
  }); 

  const [toggleAccordian, setToggleAccordian] = useState(false);
  const [showSoftValidationModal, setShowSoftValidationModal] = useState(false);
  const [softValidateOtherErr, setSoftValidateOtherErr] = useState("");
  const [softValidationRequiredErr, setSoftValidationRequiredErr] =
    useState("");
  const [currentPcrTab, setCurrentPcrTab] = useState("Identification");
  const [showEtoiError, setShowEtoiError] = useState(false);
  const { t } = useTranslation();
  const { getIdentificationValidation } = useIdentificationValidations();
  const { getAssesmentValidations } = useAssesmentValidations();
  const {getTreatmentValidations} = useTreatmentValidations();
  const [reloadPcrGrid,setReloadPcrGrid] = useState(false);
  const [startPcrInfo,setStartPcrInfo] = useState(null);
  const [showStartPcr,setShowStartPcr] = useState(false);
  const [treatmentErrors,setTreatmentErrors] = useState({});

  /* localcopy of validation schema need to refresh it whenever 
    pcrdata changes as vaidation values   changes allong with pcrdata */

  const [formValidationConfig, setFormValidationConfig] = useState();

  useEffect(() => {
    setSchema();
  }, [pcrData]);

  useEffect(() => {
    setSchema();
  }, [currentPcrTab]);

  useEffect(() => {

  },[formValidationConfig])

  const setSchema = () => {
    let schema = [];

    switch (currentPcrTab) {
      case "Identification":
        schema = getIdentificationValidation(pcrData);
        break;
      case "Assessment":
        schema = getAssesmentValidations(pcrData);
        break;
      case "TransferOfCare":
        schema = getAssesmentValidations(pcrData);
        break;
      case "Treatment":        
        schema = getTreatmentValidations(pcrData);
        break
      default:
        schema = getIdentificationValidation(pcrData);
        break;
    }
    setFormValidationConfig(schema);
  };

  /* 71   */
  const updatePCRData = (newData) => {
    setPCRData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      localStorage.setItem("pcrData", JSON.stringify(updatedData));
      return updatedData;
    });
  }; 


  const onSaveIdentificationTab = () => {
    const {identificationDetails} = pcrData;

    // tree to maintain all errors in the form
    let errorStates = {};

    let validationConfig = pcrData.isAttendingCrew
      ? formValidationConfig.filter((c) => c.isAttendingView)
      : formValidationConfig.filter((c) => c.isSupportingView);


      const checkAddressError = (addressKey) => {
        const address = identificationDetails[addressKey];
        if (typeof address === 'object') {
            const hasError = Object.values(address).some(value => !value);
            return hasError ? t('inputIsRequiredError') : "";
        }
        return undefined;
    };
    
    for (const obj of validationConfig) {
        const value = identificationDetails[obj.name] || identificationDetails?.patient[obj.name];
    
        if (obj.shouldDisplayError) {
            errorStates[obj.name + "Error"] = value ? undefined : t('inputIsRequiredError');
        }
    
        // Specific checks for 'numberOfPatientsAssessed'
        if (obj.name === "numberOfPatientsAssessed" && identificationDetails?.numberOfPatientsAssessed === 0) {
            errorStates[`${obj.name}Error`] = "";
        }
    
        // Check for address-related errors
        if (["sceneLocationAddress", "destinationLocationAddress"].includes(obj.name)) {
            errorStates[`${obj.name}Error`] = checkAddressError(obj.name);
        }
    }
    
    

    // show softvalidate option
    if (!identificationDetails?.softValidateOption && !showSoftValidationModal) {
      setShowSoftValidationModal(true);  
    }
    
    // show softvalidate option if softvalidate option is not selected
    if(showSoftValidationModal && !identificationDetails?.softValidateOption){
       setSoftValidationRequiredErr(t("inputSelectionRequiredError"));
    }

     // if Softvalidation reason selected as other and and value not provided
     if (identificationDetails?.softValidateOption === "Other" &&
      !identificationDetails?.softValidateOtherReason
    ) {
      setSoftValidateOtherErr(t("inputIsRequiredError"));
     
    }

    // clearing softvalidation Errors
    if ((identificationDetails?.softValidateOption === "Other" &&
      identificationDetails?.softValidateOtherReason) || (identificationDetails?.softValidateOption) 
    ) {
      setSoftValidateOtherErr("");
      setSoftValidationRequiredErr("");
      formValidationConfig.forEach(fvc => {
        if(fvc.softValidateAttending){
          errorStates[fvc?.name+"Error"] = "";
        }
      });
    }
    
    setPCRData({ ...pcrData, ...errorStates });
    return;
    let hasErr = false;
    Object.entries(errorStates).forEach(([key, value], index) => {
      if(value) {
        hasErr = true;
      }
      if(index === Object.entries(errorStates).length-1 && !hasErr){
        setToggleAccordian(true);
      }
    });
   
  };

  return (
    <PCRContext.Provider
      value={{
        pcrData,
        setPCRData,
        onSaveIdentificationTab,
        toggleAccordian,
        setToggleAccordian,
        formValidationConfig,
        setShowSoftValidationModal,
        showSoftValidationModal,
        setSoftValidateOtherErr,
        softValidateOtherErr,
        setSoftValidationRequiredErr,
        softValidationRequiredErr,
        setCurrentPcrTab,
        setShowEtoiError,
        showEtoiError,
        setReloadPcrGrid,
        reloadPcrGrid,
        updatePCRData,
        setStartPcrInfo,
        startPcrInfo,
        setShowStartPcr,
        showStartPcr,
        setTreatmentErrors,
        treatmentErrors
      }}
    >
      {props.children}
    </PCRContext.Provider>
  );
};
