import React, { useContext, useEffect, useState } from "react";
import {
  DataTableCell,
  DataTableColumn,
  DataTable,
  Icon,
  Input,
  InputIcon,
  Button
} from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PCRContext } from "../context/PCRContext";

const initialSortState = {
  sortColumn: "pcrTransferNumber",
  sortColumnDirection: {
    pcrTransferNumber: "desc",
    callTime: "desc",
    callDate: "desc",
    pcrAttendPractitioner: "desc",
    //patientName: "desc",
    sceneAddress: "desc",
    patientAge: "desc",
    patientSexAtBirth: "desc",
    pcrCause: "desc",
    pcrStatus: "desc",
  },
};

const PCRGrid = ({ onPCRSelection, data }) => {  

  const {setStartPcrInfo,setShowStartPcr} = useContext(PCRContext)
  
  const [list, setPcrList] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [sortState, setSortState] = useState(initialSortState);
  const { t } = useTranslation();

  const colMap = {
    pcrTransferNumber: t("transferNumber"),
    pcrAttendPractitioner: t("attendingPractitioner"),
    //patientName: t("patientName"),
    sceneAddress: t("sceneAddress"),
    patientAge: t("age"),
    callDate: t("date"),
    callTime: t("callTime"),
    pcrStatus: t("status"),
    patientSexAtBirth: t("sex"),
    pcrCause: t("cause"),
    actions: t("actions"),
  };

  useEffect(() => {
    setPcrList(data);
  },[data])

  useEffect(() => {

  data.forEach((d) => {    
    
    // incase of transfer number exists and flow is from create new pcr
    if(!d.pcrAttendPractitioner && d.json){
      let tempMedic = d?.json?.pcrFormAssignedMedics?.split(',');
      if(tempMedic){
        d.pcrAttendPractitioner = tempMedic.length ? tempMedic[0] : "";
      }
    }
    if(!d.pcrAttendPractitioner && !d.json){
      let tempMedic = d?.pcrFormAssignedMedics?.split(',');
      if(tempMedic){
        d.pcrAttendPractitioner = tempMedic.length ? tempMedic[0] : "";
      }
    }
  })
    
    setPcrList(data);
  }, []);

  const handleSort = (sortColumn) => {
    const sortProperty = sortColumn.property;
    const { sortDirection } = sortColumn;

    const newState = {
      sortColumn: sortProperty, 
      sortColumnDirection: {
        [sortProperty]: sortDirection,
      },
    };

    sortItems(sortProperty, sortDirection);
    setSortState(newState);
  };

  const sortItems = (sortProperty, sortDirection, data = list) => {
    // needs to work in both directions
    const items = data.sort((a, b) => {
      let val = 0;

      if (a[sortProperty] > b[sortProperty]) {
        val = 1;
      }
      if (a[sortProperty] < b[sortProperty]) {
        val = -1;
      }

      if (sortDirection === "desc") {
        val *= -1;
      }

      return val;
    });

    setPcrList(items);
  };

  const handleSearch = () => {
    const value = searchText;
    if (!value) {
      setPcrList(data);
      return;
    }
    const lowerCaseValue =
      typeof value === "string" && isNaN(parseInt(value))
        ? value?.toLowerCase()
        : value;
    sortItems(
      sortState.sortColumn,
      sortState.sortColumnDirection[sortState.sortColumn],
      data.filter((item) => {
        return (
          item.pcrTransferNumber?.includes(value) ||
          item.pcrAttendPractitioner?.includes(value) ||
          item.callTime?.includes(value) ||
          item.callDate?.includes(value) ||
          // item.patientName?.toLowerCase().includes(lowerCaseValue) ||
          item.sceneAddress?.toLowerCase().includes(lowerCaseValue) ||
          item.pcrCause?.toLowerCase().includes(lowerCaseValue) ||
          item.pcrStatus?.toLowerCase().includes(lowerCaseValue) ||
          item.patientAge?.toString().includes(lowerCaseValue) ||
          item.patientSexAtBirth?.toLowerCase().includes(lowerCaseValue)
        );
      })
    );
  };

  const CustomDataTableCellTransferNumber = ({ children, ...props }) => (
    <DataTableCell {...props}>
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          onPCRSelection(data.find((d) => d.pcrTransferNumber === children));
        }}
      >
        {children}
      </a>
    </DataTableCell>
  );
  CustomDataTableCellTransferNumber.displayName = DataTableCell.displayName;


  const CustomDataTableCellAction = ({ children, ...props }) => {    
    return (
      <DataTableCell {...props}>
        <Button
          label={props.item.pcrStatus === 'New' ?  "Start PCR"  : t("view PCR")}
          variant="neutral"
          onClick={(event) => {
            event.preventDefault();
           handleViewPCRAction(props.item);
            // onPCRSelection(data.find((d) => d.pcrTransferNumber === children));
          }}
        />
      </DataTableCell>
    )
  };
  CustomDataTableCellAction.displayName = DataTableCell.displayName;
  
  const handleViewPCRAction = (data) => {
    if(data?.pcrStatus === 'New'){
      setStartPcrInfo(data);
      setShowStartPcr(true);
    }
   
  }

  const columns = [
    <DataTableColumn width="1.5rem" key="id" label="" property="id" />,
    <DataTableColumn
      width="8rem"
      isSorted={sortState.sortColumn === "pcrTransferNumber"}
      isDefaultSortDescending
      key="pcrTransferNumber"
      label={t("transferNumber")}
      property="pcrTransferNumber"
      sortable
      sortDirection={sortState.sortColumnDirection.pcrTransferNumber}
    >
      <CustomDataTableCellTransferNumber />
    </DataTableColumn>,
    <DataTableColumn
      isSorted={sortState.sortColumn === "callTime"}
      width="6rem"
      sortable
      key="callTime"
      label={t("callTime")}
      property="callTime"
    />,
    <DataTableColumn
      width="9rem"
      isSorted={sortState.sortColumn === "callDate"}
      sortable
      key="callDate"
      label={t("pcrDataTableDate")}
      property="callDate"
    />,
    <DataTableColumn
      width="8rem"
      sortable
      isSorted={sortState.sortColumn === "pcrCause"}
      key="pcrCause"
      label={t("cause")}
      property="pcrCause"
    />,
    <DataTableColumn
      sortable
      isSorted={sortState.sortColumn === "pcrAttendPractitioner"}
      width="9rem"
      key="pcrAttendPractitioner"
      label={t("attendingPractitioner")}
      property="pcrAttendPractitioner"
    />,
    // <DataTableColumn
    //   width="6rem"
    //   isSorted={sortState.sortColumn === "patientName"}
    //   sortable
    //   key="patientName"
    //   label={t("patientName")}
    //   property="patientName"
    // />,
    <DataTableColumn
      width="6rem"
      isSorted={sortState.sortColumn === "sceneAddress"}
      sortable
      key="sceneAddress"
      label={t("sceneAddress")}
      property="sceneAddress"
    />,
    <DataTableColumn
      width="4rem"
      isSorted={sortState.sortColumn === "patientSexAtBirth"}
      sortable
      key="patientSexAtBirth"
      label={t("sex")}
      property="patientSexAtBirth"
    />,
    <DataTableColumn
      width="4rem"
      sortable
      isSorted={sortState.sortColumn === "patientAge"}
      key="patientAge"
      label={t("age")}
      property="patientAge"
    />,
    <DataTableColumn
      width="6rem"
      isSorted={sortState.sortColumn === "pcrStatus"}
      sortable
      key="pcrStatus"
      label={t("status")}
      property="pcrStatus"
    />,
    <DataTableColumn
      width="8rem"
      key="actions"
      label={t("actions")}
      property="actions" // transferNumber property to filter the PCRs on "View PCR" button click
      sortable={false}
    >
      <CustomDataTableCellAction />
    </DataTableColumn>,
  ];

  useEffect(() => {
    if (list?.[0]) {
      onPCRSelection(list[0]);
    } else {
      onPCRSelection(null);
    }
  }, [list]);

  const handleRefresh = () => {
    setPcrList(data);
    setSearchText("");
    setSortState(initialSortState);
  };

  const onSearchInputChange = ({ target: { value } }) => setSearchText(value);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  const enrichListWithIds = () => {
   
    
    return list.map((l, index) => {
      let callTime = ""; 
      // l?.pcrCallTimemoment(l?.pcrCallTime).utc().format('HH:mm');
      let callDate = "";
      // moment(l?.pcrDate).format('YYYY-MMM-DD');
      let address = l?.identificationDetails?.sceneLocationAddress?.streetAddress1;
      if(l?.pcrCallTime && l?.pcrCallTime !== 'UNK'){
        callTime = moment(l?.pcrCallTime).utc().format('HH:mm');
      }
      else{
        callTime = "UNK";
      }

      if(l?.pcrDate && l?.pcrDate !== 'UNK'){
        callDate = moment(l?.pcrDate).format('YYYY-MMM-DD').toUpperCase();
      }
      else{
        callDate = "UNK";
      }
      
      l.id = (index + 1).toString();  // Explicitly convert to string
      l.callTime = callTime;
      l.callDate = callDate;
      l.patientSexAtBirth = l.patientSex;
      if(!l.pcrStatus){
        l.pcrStatus = "In Progress"
      }
      if(l.callType !== "Emergency"){
        l.pcrCause = "Transfer"
      }
      if(!l.pcrCause){
        l.pcrCause = "UNK";
      }
      if(!l.patientAge){
        l.patientAge = "UNK";
      }
      l.sceneAddress = address || "UNK";
      return l;
    });
  };

  return (
    <>
      <div className="flexWrapper gridFlexWrapper">
        <div className="asc">
          {list.length ? (
            <div className="gridDetail">
              {list.length} {t("itemsSortedBy")} {colMap[sortState.sortColumn]}
            </div>
          ) : null}
          {/* <div>{t("updatedJustNow")}</div> */}
        </div>
        <div className="filterContainer positionRight">
          <div className="refreshIcon" onClick={handleRefresh}>
            <Icon
              assistiveText={{ label: "Refresh" }}
              category="utility"
              name="refresh"
              size="xx-small"
            />
          </div>
          <div className="searchInputWrapper">
            <Input
              iconLeft={
                <InputIcon
                  assistiveText={{
                    icon: "Search",
                  }}
                  name="search"
                  category="utility"
                />
              }
              value={searchText}
              className="searchInput"
              id="search-grid"
              placeholder={t("searchPCRList")}
              onChange={onSearchInputChange}
            />
          </div>
        </div>
      </div>
      <div className="">
        <DataTable
          fixedLayout
          assistiveText={{
            actionsHeader: "actions",
            columnSort: "sort this column",
            columnSortedAscending: "asc",
            columnSortedDescending: "desc",
          }}
          items={enrichListWithIds()}
          id="pcr-data-table"
          ws
          onSort={handleSort}
        >
          {columns}
        </DataTable>
      </div>
    </>
  );
};

export default PCRGrid;
