// import { useHandlePcrData } from '../hooks/dataHandlingHooks/useHandlePCRData.js';
// import { useHandlePcrData } from '../hooks/dataHandlingHooks/useHandlePCRData.js';
import { db } from '../storage/idb.js';
import { APP_CONFIG } from 'configs/config.js';
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using UUIDs for IDs

// Fetch PCR List from the server and update the IndexedDB
export const fetchPCRList = async () => {
    try {
        console.log('APP_CONFIG.SF_URL >>>', APP_CONFIG.SF_URL);
        const language = 'ENG';
        const token = localStorage.getItem('sf_access_token');
        const users = JSON.parse(localStorage.getItem('users') || '[]');
        let medicIdsList = [];
        if (users && users.length > 0) {
            for (const user of users) {
                medicIdsList.push(user.employeeId);
            }
        } else {
            throw new Error('No users found in localStorage');
        }

        const medicIds = medicIdsList.join(',');
        const response = await fetch(`${APP_CONFIG.SF_URL}/apexrest/ANB/fetchPCRList?language=${language}&medicIds=${medicIds}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('fetchPCRList > data >>>', data);
        return data;
    } catch (error) {
        console.error('Error fetching PCR list:', error);
    }
};

// Update the IndexedDB with the fetched PCR list
export const UpdateDatabaseWithPCRList = async (data,setPcrRoot) => {
    // const {setPcrRoot} = useHandlePcrData();
    try {
        const dbPcrList = await db.pcrs.toArray();

        for (const serverPcr of data) {
            // Ensure the object has a unique ID
            if (!serverPcr.id) {
                serverPcr.id = uuidv4(); // Generate a UUID if missing
            }

            const existsInDb = dbPcrList.some(p => p.pcrTransferNumber === serverPcr.pcrTransferNumber);
            dbPcrList.some(p => p.transferNumber === serverPcr.transferNumber);

            if (!existsInDb) {
                let result = setPcrRoot(serverPcr);
                await db.pcrs.add(result);
            }
        }

        console.log('Database updated with PCR list');
    } catch (error) {
        console.error('Error updating database with PCR list:', error);
    }
};

// Get all PCR data from IndexedDB
export const getAllPCRData = async () => {
    try {
        const records = await db.pcrs.toArray();
        return records;
    } catch (error) {
        console.error('Error fetching all PCR data:', error);
        return [];
    }
};

// Save or update a single PCR record in IndexedDB
export const savePCRData = async (data) => {
    try {
        await db.pcrs.put(data); // Use `put` to either add or update the record
        console.log('PCR data saved:', data);
    } catch (error) {
        console.error('Error saving PCR data:', error);
    }
};