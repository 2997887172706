import { FORM_TYPES, VALIDATION_TYPES } from "../data/constants.js";

export const useIdentificationValidations = () => {
  function getIdentificationValidation(pcrData) {
    let validationSchema = [
      {
        name: "codeToScene",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "sexAtBirth",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason &&
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "sceneLocationAddress",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "sceneLocationCode",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "codeFromScene",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.patientTransported === "Yes" ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "destinationLocationAddress",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.patientTransported === "Yes" ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "destinationLocationCode",
        type: FORM_TYPES.IDENTIFICATION,
        authorOnly: true,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.patientTransported === "Yes" ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "estimatedTimeOfInjury",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: pcrData?.estimatedTimeOfInjuryError
          ? true
          : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "injuryTime",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: pcrData?.injuryTimeError ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      // isPatientTransported
      {
        name: "patientTransported",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "numberOfPatientsAssessed",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      //this is not presemt 
      {
        name: "numberOfPatientsAssessedOption",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "noPatientsAssessedTransportedReason",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "prePHEW",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "Pre-ALRT"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "postPHEW",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "cspContacted",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "Pre-ALRT"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "ctasLevel",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.SELECTION_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.cspContacted === "Yes" ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "cspId",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.cspContacted === "Yes" ? true : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      //patient unique identifier type
      {
        name: "uniqueIdentifierType",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: true,
        softValidateAttending: false,
        softValidateSV: false,
      },
      //uniqueIdentifier
      {
        name: "uniqueIdentifier",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found" &&
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "Patient refusal"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      //patient surname
      {
        name: "lastName",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      //patient given name
      {
        name: "firstName",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "initial",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "patientAddress",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "addressCountryCode",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "addressCity",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "addressStateCode",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "addressPostalCode",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "telephone",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "dateOfBirth",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "age",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "weightKgs",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "weightLbs",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: true,
        softValidateAir: true,
      },
      {
        name: "genderIdentity",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: true,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "nextOfKin",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          ((pcrData?.identificationDetails?.patient?.age < 16 &&
            pcrData?.identificationDetails?.patient?.age >= 1 &&
            pcrData?.identificationDetails?.patient?.ageUnit === "Y") ||
            (pcrData?.identificationDetails?.patient?.ageUnit &&
              pcrData?.identificationDetails?.patient?.ageUnit !== "Y")) &&
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "nextOfKinRelationship",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          ((pcrData?.identificationDetails?.patient?.age < 16 &&
            pcrData?.identificationDetails?.patient?.age >= 1 &&
            pcrData?.identificationDetails?.patient?.ageUnit === "Y") ||
            (pcrData?.identificationDetails?.patient?.ageUnit &&
              pcrData?.identificationDetails?.patient?.ageUnit !== "Y")) &&
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "nextOfKinTelephone",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          ((pcrData?.identificationDetails?.patient?.age < 16 &&
            pcrData?.identificationDetails?.patient?.age >= 1 &&
            pcrData?.identificationDetails?.patient?.ageUnit === "Y") ||
            (pcrData?.identificationDetails?.patient?.ageUnit &&
              pcrData?.identificationDetails?.patient?.ageUnit !== "Y")) &&
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason !== "No patient found"
            ? true
            : false,
        softValidateAttending: true,
        softValidateSV: false,
        softValidateAir: true,
      },
      {
        name: "interfacilityTransferReason",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "traumaDetails",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          ((pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "Patient refusal" ||
            pcrData?.identificationDetails?.patientTransported === "Yes") &&
            (pcrData?.identificationDetails?.interfacilityTransferReason ===
              "14- Trauma")) ||
              (pcrData?.identificationDetails?.interfacilityTransferReason === "14- Trauma" && !pcrData?.identificationDetails?.numberOfPatientsAssessed)
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "reasonSpecification",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        ((pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "Patient refusal" ||
          pcrData?.identificationDetails?.patientTransported === "Yes") &&
          (pcrData?.identificationDetails?.interfacilityTransferReason ===
            "15- Other")) ||
            (pcrData?.identificationDetails?.interfacilityTransferReason === '15- Other' && !pcrData?.identificationDetails?.numberOfPatientsAssessed)
            
          ? true
          : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "agencyName",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError://noPatientTransportedReason
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "Transported by other"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "otherAgency",
        type: FORM_TYPES.IDENTIFICATION,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
          pcrData?.identificationDetails?.noPatientsAssessedTransportedReason ===
            "Transported by other" &&
          pcrData?.identificationDetails?.agencyName === "Other"
            ? true
            : false,
        softValidateAttending: false,
        softValidateSV: false,
      },
    ];
    return validationSchema;
  }

  return {
    getIdentificationValidation,
  };
};
