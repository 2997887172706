import React from "react";
import {
    Button
} from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";

const FinishShift = () => {
    const { t } = useTranslation();

    return (
        <>
            <Button variant="outline-brand" className="wide-button">
                {t("finishShift_buttonLabel")}
            </Button>
        </>
    )
};

export default FinishShift;