import React, { useEffect, useState } from "react";
import PCRTimePicker from "components/common/PCRTimePicker";
import { useTranslation } from "react-i18next";
import "css/comboBox.css";
import PCRComboBox from "components/common/PCRComboBox";
import PCRTextInput from "components/common/PCRTextInput";
import {
  successOptions,
  vascularTypeOptions,
  gaugeOptionsIVandEJ,
  gaugeOptionsIO,
  flowRateOptions,
} from "data/constants";

const PCRVascularItem = ({ index, updateRowData, updatePCRContext }) => {
  const { t } = useTranslation();
  const [vascTime, setVascTime] = useState("");
  const [onScene, setOnScene] = useState("");
  const [site, setSite] = useState("");
  const [vascType, setVascularType] = useState("");
  const [gauge, setGauge] = useState("");
  const [gaugeOptions, setGaugeOptions] = useState([]);
  const [successful, setSuccessful] = useState("");
  const [salineLock, setSalineLock] = useState("");
  const [flowRateValue, setFlowRateValue] = useState("");
  const [flowRateType, setFlowRateType] = useState("");
  const [mLInfused, setMLInfused] = useState("");
  const [initial, setInitial] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [isRowActive, setIsRowActive] = useState(false);
  const [errorText, setErrorText] = useState({
    vascTime: "",
    site: "",
    flowRateValue: "",
    mLInfused: "",
    initial: ""
  });

  // Handle time input change with validation
  const handleTimeChange = (e) => {
    const timeValue = e?.vascTime || "";
    setVascTime(timeValue);
    let errorTextTime = "";

    const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    // If the field is not empty and length is less than 5, show a generic format error
    if (timeValue.length > 0 && timeValue.length !== 5) {
      errorTextTime = t("invalidFormat");
    }
    // When length is exactly 5, check for correct time format
    else if (timeValue.length === 5 && !timePattern.test(timeValue)) {
      errorTextTime = t("invalidFormat");
    }
    // Clear the error if no issues are found
    else {
      errorTextTime = ""
    }

    setErrorText((prevErrors) => ({
      ...prevErrors,
      vascTime: errorTextTime
    }));

    // Update parent with the new time value
    updateRowData(`vascTime-${index}`, timeValue);
    updatePCRContext({ [`vascTime-${index}`]: timeValue });
  };

  // Handle selection change for On Scene
  const handleOnSceneChange = (selected) => {
    const onSceneValue = selected.label || selected;
    setOnScene(onSceneValue);
    updateRowData(`onScene-${index}`, onSceneValue);
    updatePCRContext({ [`onScene-${index}`]: onSceneValue });
  };

  // Handle selection change for Vascular Type
  const handleVascularTypeChange = (selected) => {
    const vascularTypeValue = selected.label || selected;
    setVascularType(vascularTypeValue);
    updateRowData(`vascType-${index}`, vascularTypeValue);
    updatePCRContext({ [`vascType-${index}`]: vascularTypeValue });
  };

  // Handle selection change for Gauge
  const handleGaugeChange = (selected) => {
    const gaugeValue = selected.label || selected;
    setGauge(gaugeValue);
    updateRowData(`gauge-${index}`, gaugeValue);
    updatePCRContext({ [`gauge-${index}`]: gaugeValue });
  };

  // Handle Successful dropdown change
  const handleSuccessfulChange = (selected) => {
    const successfulValue = selected.label || selected;
    setSuccessful(successfulValue);
    updateRowData(`successful-${index}`, successfulValue);
    updatePCRContext({ [`successful-${index}`]: successfulValue });
  };

  // Handle Saline Lock dropdown change
  const handleSalineLockChange = (selected) => {
    const salineLockValue = selected.label || selected;
    setSalineLock(salineLockValue);
    updateRowData(`salineLock-${index}`, salineLockValue);
    updatePCRContext({ [`salineLock-${index}`]: salineLockValue });
  };

  // Handle alphabetic validation for Site
  const handleSiteChange = (e) => {
    const siteValue = e?.site || "";
    const value = siteValue.replace(/[^a-zA-Z]/g, '');
    setSite(value);

    setErrorText((prevErrors) => ({
      ...prevErrors,
      site: /[^a-zA-Z]/.test(siteValue)
        ? t("invalidFormat")
        : ""
    }));

    updateRowData(`site-${index}`, value);
    updatePCRContext({ [`site-${index}`]: value });
  };

  // Set gauge options based on the selected type (IV/EJ or IO)
  useEffect(() => {
    setGauge("");
    if (vascType === "IV" || vascType === "EJ") {
      setGaugeOptions(gaugeOptionsIVandEJ);
    } else if (vascType === "IO") {
      setGaugeOptions(gaugeOptionsIO);
    } else {
      setGaugeOptions([{ label: '--', value: '--' }]);
    }
  }, [vascType]);

  // Handle numeric input change for Flow Rate
  const handleFlowRateValueChange = (e) => {
    const flowValue = e?.flowRateValue || "";
    const value = flowValue.replace(/\D/g, '');
    if(value > 0){
      setFlowRateValue(value);
      updateRowData(`flowRateValue-${index}`, value);
      updatePCRContext({ [`flowRateValue-${index}`]: value });
    }

    // If text field is filled, disable the dropdown
    if (value !== "") {
      setFlowRateType("");
    }

  };

  // Handle dropdown change for Flow Rate Type
  const handleFlowRateTypeChange = (selected) => {
    const typeValue = selected.label || selected;
    setFlowRateType(typeValue);

    // If dropdown is selected, disable the text field
    if (typeValue !== "") {
      setFlowRateValue("");
      setErrorText((prevErrors) => ({
        ...prevErrors,
        flowRateValue: ""
      }));
    }

    updateRowData(`flowRateType-${index}`, typeValue);
    updatePCRContext({ [`flowRateType-${index}`]: typeValue });
  };

  // Handle numeric input change for mL Infused
  const handleMLInfusedChange = (e) => {
    const mLInfusedValue = e?.mLInfused || "";
    const value = mLInfusedValue.replace(/\D/g, "");
    setMLInfused(value);

    setErrorText((prevErrors) => ({
      ...prevErrors,
      mLInfused: /\D/.test(mLInfusedValue)
        ? t("invalidFormat")
        : ""
    }));

    updateRowData(`mLInfused-${index}`, value);
    updatePCRContext({ [`mLInfused-${index}`]: value });
  };

  // Handle alphabetic validation for Initial
  const handleInitialChange = (e) => {
    const initialValue = e?.initial || "";
    const value = initialValue.replace(/[^a-zA-Z]/g, '');
    setInitial(value);

    setErrorText((prevErrors) => ({
      ...prevErrors,
      initial: /[^a-zA-Z]/.test(initialValue)
        ? t("invalidFormat")
        : ""
    }));

    // Update parent with the new Site value
    updateRowData(`initial-${index}`, value);
    updatePCRContext({ [`initial-${index}`]: value });
  };

  // Handle change for ID# (no validation required)
  const handleIdNumberChange = (e) => {
    const value = e?.idNumber || "";
    setIdNumber(value);
    updateRowData(`idNumber-${index}`, value);
    updatePCRContext({ [`idNumber-${index}`]: value });
  };

  // Function to check if any field has a value, making the row active
  const checkRowActivation = () => {
    return (
      vascTime || onScene || site || vascType || gauge || successful || salineLock || flowRateValue || flowRateType || mLInfused || initial || idNumber
    );
  };

  // UseEffect to track if any value has been entered and activate the row
  useEffect(() => {
    if (checkRowActivation()) {
      setIsRowActive(true); // Activate the row when any field has a value
    } else {
      setIsRowActive(false); // Deactivate the row when no field has a value
    }
  }, [vascTime, onScene, site, vascType, gauge, successful, salineLock, flowRateValue, flowRateType, mLInfused, initial, idNumber]);

  return (
    <>
    {/* Time */}
    <div className="slds-col" style={{marginTop:"-2px"}}>
      <PCRTimePicker
          id={`vascTime-${index}`}
          placeholder="HH:MM"
          value={vascTime}
          onChange={handleTimeChange}
          pattern="([01][0-9]|2[0-3]):[0-5][0-9]"
          errorText={errorText.vascTime}
          required={isRowActive}
          name="vascTime"
          label={t("vascularTime")}
        />
    </div>

    {/* On Scene */}
    <label className="slds-form-element__label" htmlFor={`onScene-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularOnScene")}
      <PCRComboBox
        id={`onScene-${index}`}
        options={successOptions}
        value={onScene}
        placeholder="--"
        onChange={handleOnSceneChange}
        onSelect={(val) => handleOnSceneChange(val.onScene)}
        selection={onScene ? [{ id: onScene, label: onScene }] : []}
        name='onScene'
        required={isRowActive}
        // label={t("vascularOnScene")}
      />
    </label>
   

    {/* Site */}
    <label className="slds-form-element__label" htmlFor={`site-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularSite")}
      <PCRTextInput
        id={`site-${index}`}
        value={site}
        onChange={handleSiteChange}
        errorText={errorText.site}
        required={isRowActive}
        name='site'
      />
    </label>

    {/* Vascular Type */}
    <label className="slds-form-element__label" htmlFor={`vascType-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularType")}
      <PCRComboBox
        id={`vascType-${index}`}
        options={vascularTypeOptions}
        value={vascType}
        placeholder="--"
        onChange={handleVascularTypeChange}
        onSelect={(val) => handleVascularTypeChange(val.vascType)}
        selection={vascType ? [{ id: vascType, label: vascType }] : []}
        name='vascType'
        required={isRowActive}
      />
    </label>

    {/* Gauge */}
    <label className="slds-form-element__label" htmlFor={`gauge-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularGauge")}
      <PCRComboBox
        id={`gauge-${index}`}
        options={gaugeOptions}
        value={gauge}
        placeholder="--"
        onChange={handleGaugeChange}
        onSelect={(val) => handleGaugeChange(val.gauge)}
        selection={gauge ? [{ id: gauge, label: gauge }] : []}
        name='gauge'
        required={isRowActive}
      />
    </label>

    {/* Successful */}
    <label className="slds-form-element__label" htmlFor={`successful-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularSuccessful")}
      <PCRComboBox
        id={`successful-${index}`}
        options={successOptions}
        value={successful}
        placeholder="--"
        onChange={handleSuccessfulChange}
        onSelect={(val) => handleSuccessfulChange(val.successful)}
        selection={successful ? [{ id: successful, label: successful }] : []}
        name='successful'
        required={isRowActive}
      />
    </label>

    {/* Saline Lock */}
    <label className="slds-form-element__label" htmlFor={`salineLock-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularSalineLock")}
      <PCRComboBox
        id={`salineLock-${index}`}
        options={successOptions}
        value={salineLock}
        placeholder="--"
        onChange={handleSalineLockChange}
        onSelect={(val) => handleSalineLockChange(val.salineLock)}
        selection={salineLock ? [{ id: salineLock, label: salineLock }] : []}
        name='salineLock'
        required={isRowActive}
      />
    </label>

    {/* Flow Rate Text Field */}
    <label className="slds-form-element__label" htmlFor={`flowRateValue-${index}`}>
      {isRowActive && flowRateType === "" && <span style={{ color: "red" }}>*</span>}{t("vascularFlowRate")}
      <PCRTextInput
        id={`flowRateValue-${index}`}
        value={flowRateValue}
        onChange={handleFlowRateValueChange}
        errorText={errorText.flowRateValue}
        required={isRowActive && flowRateType === ""}
        name='flowRateValue'
        disabled={flowRateType !== ""} // Disable if dropdown is selected
      />
    </label>

    {/* Flow Rate Dropdown */}
    <div  style = {{marginTop:"21px"}}>
    <label className="slds-form-element__label" htmlFor={`flowRateType-${index}`}>
      {isRowActive && flowRateValue === "" && <span style={{ color: "red" }}>*</span>}{t(" ")}
      <PCRComboBox
        id={`flowRateType-${index}`}
        options={flowRateOptions}
        value={flowRateType}
        placeholder="--"
        onChange={handleFlowRateTypeChange}
        onSelect={(val) => handleFlowRateTypeChange(val.flowRateType)}
        selection={flowRateType ? [{ id: flowRateType, label: flowRateType }] : []}
        name='flowRateType'
        disabled={flowRateValue !== ""} // Disable if text field is filled
        required={isRowActive && flowRateValue === ""}
      />
    </label>
    </div>

    {/* mL Infused */}
    <label className="slds-form-element__label" htmlFor={`mLInfused-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularMLinfused")}
      <PCRTextInput
        id={`mLInfused-${index}`}
        value={mLInfused}
        onChange={handleMLInfusedChange}
        errorText={errorText.mLInfused}
        name='mLInfused'
        required={isRowActive}
      />
    </label>

    {/* Initial */}
    <label className="slds-form-element__label" htmlFor={`initial-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularInitial")}
      <PCRTextInput
        id={`initial-${index}`}
        value={initial}
        onChange={handleInitialChange}
        errorText={errorText.initial}
        required={isRowActive}
        name='initial'
      />
    </label>

    {/* ID# */}
    <label className="slds-form-element__label" htmlFor={`idNumber-${index}`}>
      {isRowActive && <span style={{ color: "red" }}>*</span>}{t("vascularID#")}
      <PCRTextInput
        id={`idNumber-${index}`}
        value={idNumber}
        onChange={handleIdNumberChange}
        required={isRowActive}
        name='idNumber'
      />
    </label>
  </>
  );
};

export default PCRVascularItem;