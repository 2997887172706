import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PCRContext } from 'context/PCRContext';
import PCRTimePicker from '../../../../../components/common/PCRTimePicker.jsx';
import PCRComboBox from "../../../../../components/common/PCRComboBox.jsx";
import PCRTextInput from "../../../../../components/common/PCRTextInput.jsx";
import { VALIDATION_TYPES, OLMCPhysiciansList, OLMCReasonList, traumaList } from "../../../../../data/constants";
import { RadioGroup, Radio, Textarea, Button, Icon, Checkbox } from '@salesforce/design-system-react';
import PDFViewer from "../../../../PDFViewer";
import PCRAPGARScore from './PCRAPGARScore';

const PCROtherTreatments = ({ pcr, id, handleCancelConfirmation }) => {
    const { t } = useTranslation();
    const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
    const { treatment } = pcrData || {};
    const otherTreatments = treatment?.otherTreatments || {};

    const [obsDeliveryTime, setObsDeliveryTime] = useState(otherTreatments.obsDeliveryTime || "");
    const [obsDeliverySex, setObsDeliverySex] = useState("");
    const [olmcContacted, setOlmcContacted] = useState("");
    const [OLMCPhysicians, setOLMCPhysicians] = useState("");
    const [otherPhysician, setOtherPhysician] = useState("");
    const [OLMCReasons, setOLMCReasons] = useState("");
    const [otherReason, setOtherReason] = useState("");
    const [comments, setComments] = useState("");
    const [strokeTraumaActivation, setStrokeTraumaActivation] = useState("");
    const [stroke, setStroke] = useState(false);
    const [trauma, setTrauma] = useState(false);
    const [showStrokeFile, setShowStrokeFile] = useState(false);
    const [showTraumaFile, setShowTraumaFile] = useState(false);
    const [showSepticFile, setShowSepticFile] = useState(false);
    const [strokeTime, setStrokeTime] = useState("");
    const [traumaDropdown, setTraumaDropdown] = useState("");
    const [patientSeptic, setPatientSeptic] = useState("");
    const [suspectedInfection, setSuspectedInfection] = useState("");
    const [temperature, setTemperature] = useState("");
    const [respiratory, setRespiratory] = useState("");
    const [etco2, setETCO2] = useState("");
    const [heartRate, setHeartRate] = useState("");
    const [errorText, setErrorText] = useState({
        strokeTime: "",
        obsDeliveryTime: ""
    });
    const [isAutoSelectedOLMC, setIsAutoSelectedOLMC] = useState(false);

    const updatePCRContext = (inputObj) => {
        const field = formValidationConfig.find(f => f.name === Object.keys(inputObj)[0]);
        let errorState = {};

        Object.entries(inputObj).forEach(([key, value]) => {
            field?.validations.forEach((type) => {
                if (field?.shouldDisplayError && !value) {
                    switch (type) {
                        case VALIDATION_TYPES.VALUE_REQUIRED:
                            errorState[`${field.name}Error`] = t("inputIsRequiredError");
                            break;
                        default:
                            break;
                    }
                }
                if (field?.shouldDisplayError && value) {
                    errorState[`${field.name}Error`] = "";
                }
            });
        });

        setPCRData({
            ...pcrData,
            treatment: { ...treatment, otherTreatments: { ...otherTreatments, ...inputObj } },
            ...errorState,
        });
    };

    const handleValidate = () => {
        let canToggle = true;
        let errorState = {};

        const otherTreatmentData = {
            // Validation fields to be added
        };

        Object.entries(otherTreatmentData).forEach(([key, value]) => {
            const field = formValidationConfig.find((f) => f.name === key);

            if (field?.shouldDisplayError && !value && !errorState[`${field.name}Error`]) {
                switch (field.validations[0]) {
                    case VALIDATION_TYPES.VALUE_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputIsRequiredError");
                        break;

                    case VALIDATION_TYPES.SELECTION_REQUIRED:
                        errorState[`${field.name}Error`] = t("inputSelectionRequiredError");
                        break;

                    default:
                        break;
                }
                canToggle = false;
            }
        });

        setPCRData({
            ...pcrData,
            ...errorState,
        });

        if (canToggle) handleCancelConfirmation(id);
    };

    // Handle time input change with validation
    const handleTimeChange = (setTime, value, fieldName) => {
        const timeValue = value || "";
        setTime(timeValue);
        const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        setErrorText((prevErrors) => ({
            ...prevErrors,
            [fieldName]: timeValue.length > 0 && (timeValue.length === 5 && !timePattern.test(timeValue))
                ? t("invalidFormat")
                : ""
        }));
    };

    // Handle OLMC: Physician name change
    const handleOLMCPhysicianChange = (selected) => {
        const olmcPhysicianNameValue = selected.label || selected;
        setOLMCPhysicians(olmcPhysicianNameValue);
        updatePCRContext({ olmcPhysicianName: olmcPhysicianNameValue });
    };

    // Handle Reason change
    const handleReasonChange = (selected) => {
        const reasonValue = selected.label || selected;
        setOLMCReasons(reasonValue);
        updatePCRContext({ reason: reasonValue });
    }

    // Handle Trauma Dropdown change
    const handleTraumaDropdownChange = (selected) => {
        const traumaDropdownValue = selected.label || selected;
        setTraumaDropdown(traumaDropdownValue);
        updatePCRContext({ traumaDropdown: traumaDropdownValue });
    }

    // Handle checkbox change for optional selections in ROSC
    const handleCheckboxChange = (setter, e) => {
        setter(e.target.checked);
    };

    // Conditionally show the fields if the primary protocol is 1255, 1260, or 1270
    const protocolPrimary = pcrData?.assesment?.protocolGuideline || "";
    const showOBSFields = protocolPrimary.includes("1255") || protocolPrimary.includes("1260") || protocolPrimary.includes("1270");
    const showSepticField = protocolPrimary.includes("1030");

    // Get the Termination of Care value from the context (external data)
    const terminationOfCareValue = pcrData?.treatment?.circulationAndMonitor?.terminationOfCare || "";

    // Effect to auto-select OLMC when TOC is "Yes" and OLMC is not manually selected
    useEffect(() => {
        if (terminationOfCareValue === "Yes" && !olmcContacted) {
            setOlmcContacted("Yes");
            setIsAutoSelectedOLMC(true);
        }
    }, [terminationOfCareValue, olmcContacted]);

    // Handle OLMC contacted change (manual selection)
    const handleOLMCContactedChange = (e) => {
        const selectedValue = e.target.value;
        setOlmcContacted(selectedValue);
        setIsAutoSelectedOLMC(false);
        updatePCRContext({ olmcContacted: selectedValue });

        // If "No" is selected, clear reflexive fields
        if (selectedValue === "No") {
            setOLMCPhysicians("");
            setOLMCReasons("");
        }
    };

    const handleStrokeFile = () => setShowStrokeFile(!showStrokeFile);
    const handleTraumaFile = () => setShowTraumaFile(!showTraumaFile);
    const handleSepticFile = () => setShowSepticFile(!showSepticFile);

    return (
        <>
            <PDFViewer
                showPDF={showStrokeFile}
                onDone={handleStrokeFile}
                fileName={`${process.env.PUBLIC_URL}/Stroke_Protocol.pdf`}
            />

            <PDFViewer
                showPDF={showTraumaFile}
                onDone={handleTraumaFile}
                fileName={`${process.env.PUBLIC_URL}/Field_Trauma_Triage_v3.6.pdf`}
            />

            <PDFViewer
                showPDF={showSepticFile}
                onDone={handleSepticFile}
                fileName={`${process.env.PUBLIC_URL}/Sepsis_Protocol.pdf`}
            />

            {showOBSFields && (
                <div className="pcr-row" style={{ marginTop: '10px' }}>
                    {/* OBS Delivery: Time*/}
                    <label className="slds-form-element__label" htmlFor="obsDeliveryTime">
                        {t("obsDeliveryTime")}
                        <PCRTimePicker
                            id="obsDeliveryTime"
                            placeholder="HH:MM"
                            value={obsDeliveryTime}
                            onChange={(e) => { handleTimeChange(setObsDeliveryTime, e?.obsDeliveryTime, "obsDeliveryTime") }}
                            errorText={errorText.obsDeliveryTime}
                            name="obsDeliveryTime"
                            required={false}
                        />
                    </label>

                    {/* OBS Delivery: Sex */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("obsDeliverySex") }}
                            name="obs-delivery-sex-radio-group"
                            className="disp-align-end"
                            onChange={(e) => {
                                const selectedValue = e.target.value;
                                setObsDeliverySex(selectedValue);
                                updatePCRContext({ obsDeliverySex: selectedValue });
                            }}
                        >
                            <Radio
                                id="obs-delivery-sex-male"
                                labels={{ label: t("Male") }}
                                value="M"
                                checked={obsDeliverySex === "M"}
                            />
                            <Radio
                                id="obs-delivery-sex-female"
                                labels={{ label: t("Female") }}
                                value="F"
                                checked={obsDeliverySex === "F"}
                            />
                        </RadioGroup>
                    </div>

                    {/* APGAR component */}
                    <PCRAPGARScore
                        updatePCRContext={updatePCRContext}
                    />
                </div>
            )}

            <div className="pcr-row" style={{ marginTop: '10px' }}>
                {/* OLMC Contacted */}
                <div className="radio-group">
                    <RadioGroup
                        labels={{ label: t("OLMCContacted") }}
                        name="olmc-contacted-radio-group"
                        className="disp-align-end"
                        onChange={handleOLMCContactedChange}
                        required
                        value={olmcContacted}
                        errorText={!olmcContacted && t("This field is required")}
                    >
                        <Radio
                            id="olmc-contacted-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={olmcContacted === "Yes"}
                        />
                        <Radio
                            id="olmc-contacted-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={olmcContacted === "No"}
                        />
                    </RadioGroup>
                </div>
                {olmcContacted === "Yes" && (
                    <>
                        {/* OLMC: Physician name */}
                        <label className="slds-form-element__label" htmlFor={"olmcPhysicianName"}>
                            {t("OLMCPhysicianName")}
                            <PCRComboBox
                                id="olmcPhysicianName-combo-box"
                                options={OLMCPhysiciansList}
                                value={OLMCPhysicians}
                                placeholder="Select"
                                onChange={handleOLMCPhysicianChange}
                                onSelect={(val) => handleOLMCPhysicianChange(val.olmcPhysicianName)}
                                selection={OLMCPhysicians ? [{ id: OLMCPhysicians, label: OLMCPhysicians }] : []}
                                name="olmcPhysicianName"
                                required={isAutoSelectedOLMC}
                                errorText={isAutoSelectedOLMC && !OLMCPhysicians ? "This field is required" : ""}
                            />
                            {OLMCPhysicians === "Other" && (
                                <PCRTextInput
                                    id="otherPhysician-input"
                                    placeholder={t("Please specify")}
                                    value={otherPhysician}
                                    onChange={(e) => {
                                        setOtherPhysician(e?.otherPhysician);
                                        updatePCRContext({ otherPhysician: e?.otherPhysician });
                                    }}
                                    required
                                    name="otherPhysician"
                                />
                            )}
                        </label>

                        {/* Reason */}
                        <label className="slds-form-element__label" htmlFor={"reason"}>
                            {t("OLMCReason")}
                            <PCRComboBox
                                id="reason-combo-box"
                                options={OLMCReasonList}
                                value={OLMCReasons}
                                placeholder="Select"
                                onChange={handleReasonChange}
                                onSelect={(val) => handleReasonChange(val.reason)}
                                selection={OLMCReasons ? [{ id: OLMCReasons, label: OLMCReasons }] : []}
                                name="reason"
                                required={isAutoSelectedOLMC}
                                errorText={isAutoSelectedOLMC && !OLMCReasons ? "This field is required" : ""}
                            />
                            {OLMCReasons === "Other" && (
                                <PCRTextInput
                                    id="otherReason-input"
                                    placeholder={t("Please specify")}
                                    value={otherReason}
                                    onChange={(e) => {
                                        setOtherReason(e?.otherReason);
                                        updatePCRContext({ otherReason: e?.otherReason });
                                    }}
                                    required
                                    name="otherReason"
                                />
                            )}
                        </label>

                        {/* Comments */}
                        <label className="slds-form-element__label" htmlFor="comments">
                            {t("OLMCComments")}
                            <div className="comments-container">
                                <Textarea
                                    id="comments"
                                    className="assesment_text_area_cls"
                                    value={comments}
                                    onChange={(e) => {
                                        setComments(e.target.value);
                                        updatePCRContext({ comments: e.target.value });
                                    }}
                                    required={false}
                                />
                            </div>
                        </label>

                    </>
                )}
            </div>

            <div className="pcr-row" style={{ marginTop: '10px', width: '100%', display: 'flex', alignItems: 'flex-start' }}>
                {/* Stroke/Trauma Activation */}
                <div className="radio-group" style={{ width: '80%' }}>
                    <RadioGroup
                        labels={{ label: t("strokeTraumaActivation") }}
                        name="strokeTraumaActivation-radio-group"
                        className="disp-align-end"
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setStrokeTraumaActivation(selectedValue);
                            updatePCRContext({ strokeTraumaActivation: selectedValue });
                        }}
                        required
                        errorText={!strokeTraumaActivation && t("This field is required")}
                    >
                        <Radio
                            id="strokeTraumaActivation-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={strokeTraumaActivation === "Yes"}
                        />
                        <Radio
                            id="strokeTraumaActivation-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={strokeTraumaActivation === "No"}
                        />
                    </RadioGroup>

                    {strokeTraumaActivation === "Yes" && (
                        <>
                            {/* Stroke and Trauma Checkboxes */}
                            <div className="checkbox-group" style={{ marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                    {/* Stroke Checkbox and Field */}
                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '16rem' }}>
                                        <Checkbox
                                            id="stroke-checkbox"
                                            labels={{ label: t("strokeLabel") }}
                                            checked={stroke}
                                            onChange={(e) => {
                                                setStroke(e.target.checked);
                                                updatePCRContext({ stroke: e.target.checked });
                                            }}
                                            style={{ whiteSpace: 'nowrap' }}
                                        />
                                        {/* Info Button next to Stroke */}
                                        <Button
                                            assistiveText={{ icon: 'info' }}
                                            iconCategory="utility"
                                            iconName="info"
                                            iconSize="small"
                                            variant="icon"
                                            className="slds-m-left_x-small"
                                            style={{ marginLeft: '-2px', marginTop: '1rem' }}
                                            onClick={handleStrokeFile}
                                        />
                                        {/* Stroke Time Field */}
                                        {stroke && (
                                            <div style={{ marginLeft: '10px' }}>
                                                <PCRTimePicker
                                                    id="strokeTime"
                                                    placeholder="HH:MM"
                                                    value={strokeTime}
                                                    onChange={(e) => handleTimeChange(setStrokeTime, e?.strokeTime, "strokeTime")}
                                                    pattern="([01][0-9]|2[0-3]):[0-5][0-9]"
                                                    errorText={errorText.strokeTime}
                                                    name="strokeTime"
                                                    required
                                                    style={{ width: '100px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
                                    {/* Trauma Checkbox and Field */}
                                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '200px' }}>
                                        <Checkbox
                                            id="trauma-checkbox"
                                            labels={{ label: t("traumaLabel") }}
                                            checked={trauma}
                                            onChange={(e) => {
                                                setTrauma(e.target.checked);
                                                updatePCRContext({ trauma: e.target.checked });
                                            }}
                                            style={{ whiteSpace: 'nowrap' }}
                                        />
                                        {/* Info Button next to Trauma */}
                                        <Button
                                            assistiveText={{ icon: 'info' }}
                                            iconCategory="utility"
                                            iconName="info"
                                            iconSize="small"
                                            variant="icon"
                                            className="slds-m-left_x-small"
                                            style={{ marginLeft: '-2px', marginTop: '1rem' }}
                                            onClick={handleTraumaFile}
                                        />
                                        {/* Trauma Dropdown Field */}
                                        {trauma && (
                                            <div style={{ marginLeft: '10px' }}>
                                                <PCRComboBox
                                                    id="trauma-combo-box"
                                                    options={traumaList}
                                                    value={traumaDropdown}
                                                    placeholder="--"
                                                    onChange={handleTraumaDropdownChange}
                                                    onSelect={(val) => handleTraumaDropdownChange(val.traumaDropdown)}
                                                    selection={traumaDropdown ? [{ id: traumaDropdown, label: traumaDropdown }] : []}
                                                    name="traumaDropdown"
                                                    errorText={!traumaDropdown && t("This field is required")}
                                                    style={{ width: '200px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Warning message */}
                                {(!trauma && !stroke) && (
                                    <div className="df" style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                                        <Icon
                                            assistiveText={{ label: "warning" }}
                                            category="utility"
                                            name="warning"
                                            size="xx-small"
                                            style={{ fill: "#f3a005", marginRight: '4px' }}
                                        />
                                        <p className="warning_etoi_assesmnt" style={{ color: '#f3a005' }}>
                                            One value must be selected!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {/* Patient Septic */}
                <div className="radio-group">
                    <label className="slds-form-element__label" htmlFor={"olmcPhysicianName"}>
                        {t("patientSeptic")}
                        {/* Info Button next to Patient Septic */}
                        <Button
                            assistiveText={{ icon: 'info' }}
                            iconCategory="utility"
                            iconName="info"
                            iconSize="small"
                            variant="icon"
                            className="slds-m-left_x-small"
                            style={{ marginLeft: '4px', marginTop: '1px' }}
                            onClick={handleSepticFile}
                        />
                    </label>
                    <RadioGroup
                        name="patientSeptic-radio-group"
                        className="disp-align-end"
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setPatientSeptic(selectedValue);
                            updatePCRContext({ patientSeptic: selectedValue });
                        }}
                        required={showSepticField}
                    >
                        <Radio
                            id="patientSeptic-yes"
                            labels={{ label: t("Yes") }}
                            value="Yes"
                            checked={patientSeptic === "Yes"}
                        />
                        <Radio
                            id="patientSeptic-no"
                            labels={{ label: t("No") }}
                            value="No"
                            checked={patientSeptic === "No"}
                        />
                    </RadioGroup>

                    {/* Patient Septic checkboxes */}
                    {patientSeptic === "Yes" && (
                        <div style={{ marginTop: '15px', width: '300%' }}>
                            <Checkbox
                                labels={{ label: t("septicSuspectedInfection") }}
                                checked={suspectedInfection}
                                onChange={(e) => handleCheckboxChange(setSuspectedInfection, e)}
                            />
                            <Checkbox
                                labels={{ label: t("septicTemperature") }}
                                checked={temperature}
                                onChange={(e) => handleCheckboxChange(setTemperature, e)}
                            />
                            <Checkbox
                                labels={{ label: t("septicRespiratory") }}
                                checked={respiratory}
                                onChange={(e) => handleCheckboxChange(setRespiratory, e)}
                            />
                            <Checkbox
                                labels={{ label: t("septicHeartRate") }}
                                checked={heartRate}
                                onChange={(e) => handleCheckboxChange(setHeartRate, e)}
                            />
                            <Checkbox
                                labels={{ label: t("septicETCO2") }}
                                checked={etco2}
                                onChange={(e) => handleCheckboxChange(setETCO2, e)}
                            />
                        </div>
                    )}
                </div>
            </div>


            {/* Validate button */}
            <div className="df transfer-validate-btn-assesment">
                <Button variant="brand" onClick={handleValidate}>
                    {t("validate")}
                </Button>
            </div>
        </>
    );
};

export default PCROtherTreatments;