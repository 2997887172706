import { FORM_TYPES, VALIDATION_TYPES } from "../data/constants.js";
export const useTreatmentValidations = () => {
  function getTreatmentValidations(pcrData) {
    const validationSchema = [
      {
        name: "view",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "successful",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "size",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "rightLung",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "leftLung",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "initial",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "idNumber",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "gastricTube",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "etco2",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "epigastrium",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "depthAtGums",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "airwayTime",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "airwayDevice",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },

      {
        name: "vascType",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "vascTime",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "successful",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "site",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "salineLock",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "onScene",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "mLInfused",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "initial",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "id",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "gauge",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "flowRate",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError: false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "monitorSN",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:  pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "CoRE"|| 
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1055","1080","1081","1085", "1086", "1220", "1225", "1410"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))),
        softValidateAttending: false,
        softValidateSV: false,

      },
      {
        name: "patientId",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:  pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "CoRE"|| 
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1055","1080","1081","1085", "1086", "1220", "1225", "1410"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))),
        softValidateAttending: false,
        softValidateSV: false,

      },
      {
        name: "was12LeadECGAcquired",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1085", "1086", "1220", "1225", "1410"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))) || false,
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "bystanderDefibrillation",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:  pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "CoRE"|| 
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1055","1080","1081"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "firstResponderDefibrillation",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:  pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "CoRE"|| 
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1055","1080","1081"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))),
        softValidateAttending: false,
        softValidateSV: false,
      },
      {
        name: "arrestEtiology",
        type: FORM_TYPES.ASSESSMENT,
        validations: [VALIDATION_TYPES.VALUE_REQUIRED],
        isAttendingView: true,
        isSupportingView: false,
        shouldDisplayError:  pcrData?.identificationDetails?.noPatientsAssessedTransportedReason === "CoRE"|| 
        (pcrData?.assesment?.protocolGuideline &&
          [
            "1055","1080","1081"
          ].some(value => pcrData?.assesment?.protocolGuideline.includes(value))),
        softValidateAttending: false,
        softValidateSV: false,
      }

    ];

    return validationSchema;
  }

  return {
    getTreatmentValidations,
  };
};
