import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PCRTextInput from '../../../../common/PCRTextInput';
import { Tooltip, Button } from '@salesforce/design-system-react';

const PCRAPGARScore = ({ updatePCRContext }) => {
    const { t } = useTranslation();

    const [fields, setFields] = useState({
        min1appearance: "",
        min1pulse: "",
        min1grimace: "",
        min1activity: "",
        min1RespEffort: "",
        min1Score: "",
        min2appearance: "",
        min2pulse: "",
        min2grimace: "",
        min2activity: "",
        min2RespEffort: "",
        min2Score: ""
    });

    // Separate state for error messages
    const [errors, setErrors] = useState({
        min1appearance: "",
        min1pulse: "",
        min1grimace: "",
        min1activity: "",
        min1RespEffort: "",
        min2appearance: "",
        min2pulse: "",
        min2grimace: "",
        min2activity: "",
        min2RespEffort: ""
    });

    const [isScoreTooltipOpen, setIsScoreTooltipOpen] = useState(false);
    const scoreTooltipRef = useRef(null);

    // Dynamic handleInputChange for every fiescoreTooltipRefld
    const handleInputChange = (fieldName, value) => {
        const regex = /^[012]$/;

        // Check if the field is empty
        if (value === "") {
            // No error if the field is empty
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: ""
            }));
        } else {
            // Perform the regex validation only if there's a value
            if (!regex.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: "VALUE MUST BE 0, 1, OR 2"
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: ""
                }));
            }
        }

        // Update the specific field dynamically
        setFields((prevFields) => ({
            ...prevFields,
            [fieldName]: value
        }));

        // Update context for the specific field
        updatePCRContext({ [fieldName]: value });
    };

    // Common function to calculate the Apgar score
    const calculateApgarScore = (prefix) => {
        const { [`${prefix}appearance`]: appearance, [`${prefix}pulse`]: pulse, [`${prefix}grimace`]: grimace, [`${prefix}activity`]: activity, [`${prefix}RespEffort`]: respEffort } = fields;

        // Check if all fields are valid (0, 1, or 2)
        const allFieldsValid = [appearance, pulse, grimace, activity, respEffort]
            .every(value => /^[012]$/.test(value));

        // If all fields are valid, calculate the score
        if (allFieldsValid) {
            const score = [appearance, pulse, grimace, activity, respEffort]
                .reduce((sum, value) => sum + parseInt(value, 10), 0);

            setFields((prevFields) => ({
                ...prevFields,
                [`${prefix}Score`]: score.toString()
            }));
        } else {
            setFields((prevFields) => ({
                ...prevFields,
                [`${prefix}Score`]: ""
            }));
        }
    };

    // Trigger score calculation when the relevant fields change
    useEffect(() => {
        calculateApgarScore('min1'); // Calculate 1-minute score
    }, [fields.min1appearance, fields.min1pulse, fields.min1grimace, fields.min1activity, fields.min1RespEffort]);

    useEffect(() => {
        calculateApgarScore('min2'); // Calculate 5-minute score
    }, [fields.min2appearance, fields.min2pulse, fields.min2grimace, fields.min2activity, fields.min2RespEffort]);

    // Handlers for DNAR Tooltip
    const toggleScoreTooltip = () => {
        setIsScoreTooltipOpen(!isScoreTooltipOpen);
    };

    const closeScoreTooltip = () => {
        setIsScoreTooltipOpen(false);
    };


    return (
        <>
            <div className='pcr-row' style={{ width: '80%', display: 'flex' }}>
                {/* 1 min and 5 min labels */}
                <div>
                    <label style={{ marginTop: '20px', display: 'flex' }}>{t("1minLabel")}</label>
                    <label style={{ marginTop: '20px', display: 'flex' }}>{t("5minLabel")}</label>
                </div>

                {/* Appearance */}
                <label className="slds-form-element__label" htmlFor="appearance">
                    {t("APGARAppearance")}
                    <PCRTextInput
                        id={"min1appearance"}
                        value={fields.min1appearance}
                        onChange={(e) => handleInputChange('min1appearance', e?.min1appearance)}
                        required={false}
                        name="min1appearance"
                        errorText={errors.min1appearance}
                    />
                    <PCRTextInput
                        id={"min2appearance"}
                        value={fields.min2appearance}
                        onChange={(e) => handleInputChange('min2appearance', e?.min2appearance)}
                        required={false}
                        name='min2appearance'
                        errorText={errors.min2appearance}
                    />
                </label>

                {/* Pulse */}
                <label className="slds-form-element__label" htmlFor="pulse">
                    {t("APGARPulse")}
                    <PCRTextInput
                        id={"min1pulse"}
                        value={fields.min1pulse}
                        onChange={(e) => handleInputChange('min1pulse', e?.min1pulse)}
                        required={false}
                        name='min1pulse'
                        errorText={errors.min1pulse}
                    />
                    <PCRTextInput
                        id={"min2pulse"}
                        value={fields.min2pulse}
                        onChange={(e) => handleInputChange('min2pulse', e?.min2pulse)}
                        required={false}
                        name='min2pulse'
                        errorText={errors.min2pulse}
                    />
                </label>

                {/* Grimace */}
                <label className="slds-form-element__label" htmlFor="grimace">
                    {t("APGARGrimace")}
                    <PCRTextInput
                        id={"min1grimace"}
                        value={fields.min1grimace}
                        onChange={(e) => handleInputChange('min1grimace', e?.min1grimace)}
                        required={false}
                        name='min1grimace'
                        errorText={errors.min1grimace}
                    />
                    <PCRTextInput
                        id={"min2grimace"}
                        value={fields.min2grimace}
                        onChange={(e) => handleInputChange('min2grimace', e?.min2grimace)}
                        required={false}
                        name='min2grimace'
                        errorText={errors.min2grimace}
                    />
                </label>

                {/* Activity */}
                <label className="slds-form-element__label" htmlFor="activity">
                    {t("APGARActivity")}
                    <PCRTextInput
                        id={"min1activity"}
                        value={fields.min1activity}
                        onChange={(e) => handleInputChange('min1activity', e?.min1activity)}
                        required={false}
                        name='min1activity'
                        errorText={errors.min1activity}
                    />
                    <PCRTextInput
                        id={"min2activity"}
                        value={fields.min2activity}
                        onChange={(e) => handleInputChange('min2activity', e?.min2activity)}
                        required={false}
                        name='min2activity'
                        errorText={errors.min2activity}
                    />
                </label>

                {/* Resp. effort */}
                <label className="slds-form-element__label" htmlFor="respEffort">
                    {t("APGARRespEffort")}
                    <PCRTextInput
                        id={"min1RespEffort"}
                        value={fields.min1RespEffort}
                        onChange={(e) => handleInputChange('min1RespEffort', e?.min1RespEffort)}
                        required={false}
                        name='min1RespEffort'
                        errorText={errors.min1RespEffort}
                    />
                    <PCRTextInput
                        id={"min2RespEffort"}
                        value={fields.min2RespEffort}
                        onChange={(e) => handleInputChange('min2RespEffort', e?.min2RespEffort)}
                        required={false}
                        name='min2RespEffort'
                        errorText={errors.min2RespEffort}
                    />
                </label>

                {/* Apgar score (no input handling needed for score fields) */}
                <label className="slds-form-element__label" htmlFor="score">
                    {t("APGARScore")}
                    <Tooltip
                        isOpen={isScoreTooltipOpen}
                        onRequestClose={closeScoreTooltip}
                        content={
                            <div ref={scoreTooltipRef} style={{ position: 'relative' }}>
                                <Button
                                    assistiveText={{ icon: 'close' }}
                                    iconCategory="utility"
                                    iconName="close"
                                    iconSize="small"
                                    variant="icon"
                                    onClick={closeScoreTooltip}
                                    className="custom-tooltip-close-button"
                                />
                                <img
                                    src={`${process.env.PUBLIC_URL}/Apgar_Reference_Guide.png`}
                                    alt="APGAR Reference Guide"
                                    className="custom-tooltip-image"
                                    style={{ width: '1800px', height: '180px' }}
                                />
                            </div>
                        }
                        align="top"
                    >
                        <Button
                            assistiveText={{ icon: 'info' }}
                            iconCategory="utility"
                            iconName="info"
                            iconSize="small"
                            variant="icon"
                            onClick={toggleScoreTooltip}
                            className="slds-m-left_x-small"
                        />
                    </Tooltip>
                    <PCRTextInput
                        id={"min1Score"}
                        value={fields.min1Score}
                        required={false}
                        name='min1Score'
                        disabled={true}
                    />
                    <PCRTextInput
                        id={"min2Score"}
                        value={fields.min2Score}
                        required={false}
                        name='min2Score'
                        disabled={true}
                    />
                </label>
            </div>
        </>
    )
};

export default PCRAPGARScore;