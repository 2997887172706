import React, { useState, useEffect, useContext } from "react";
import { Button, Input, Popover } from "@salesforce/design-system-react";
import PCRComboBox from "../../../../common/PCRComboBox";
import {
  successOptions,
  viewOptions,
  airwayDeviceOptions,
  iGelSizes,
  etTubeSizes,
} from "../../../../../data/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PCRTimePicker from "../../../../common/PCRTimePicker";
import { PCRContext } from "../../../../../context/PCRContext";

const AdvancedAirway = ({
  index,
  isSelected,
  onAirwaySelect,
  updatePCRContext,
  airwayItems,
  setAirwayItems,
}) => {
  const { t } = useTranslation();
  const { treatmentErrors, setTreatmentErrors } = useContext(PCRContext);
  const [selectedAirwayDevice, setSelectedAirwayDevice] = useState("");
  const [sizeOptions, setSizeOptions] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [time, setTime] = useState("");
  const [selectedSuccess, setSelectedSuccess] = useState("");
  const [selectedView, setSelectedView] = useState("");
  const [viewRequired, setViewRequired] = useState(false);
  const [depthAtGums, setDepthAtGums] = useState("");
  const [depthAtGumsRequired, setDepthAtGumsRequired] = useState(false);
  const [etco2, setEtco2] = useState("");
  const [selectedGastricTube, setSelectedGastricTube] = useState("");
  const [gastricTubeRequired, setGastricTubeRequired] = useState(false);
  const [initials, setInitials] = useState("");
  const [id, setId] = useState("");
  const [selectedRLung, setSelectedRLung] = useState("");
  const [selectedLLung, setSelectedLLung] = useState("");
  const [selectedEpigastrium, setSelectedEpigastrium] = useState("");
  const [localAirwayItem,setLocalAirwayItem] = useState(airwayItems);

  useEffect(() => {
    setLocalAirwayItem(airwayItems);
  },[airwayItems]);

  // Handle airway device selection
  const handleAirwayDeviceChange = (selected) => {

    const airwayDeviceValue = selected.label || selected;
    setSelectedAirwayDevice(airwayDeviceValue);
    updatePCRContext({ [`airwayDevice-${index}`]: airwayDeviceValue });
    if (airwayDeviceValue === "IGel") {
      setSizeOptions(iGelSizes);
      setViewRequired(false);
      setDepthAtGumsRequired(false);
      setGastricTubeRequired(true);
    } else if (airwayDeviceValue === "ET tube") {
      setSizeOptions(etTubeSizes);
      setViewRequired(true);
      setDepthAtGumsRequired(true);
      setGastricTubeRequired(false);
    } else {
      setSizeOptions([]);
      setViewRequired(false);
      setDepthAtGumsRequired(false);
      setGastricTubeRequired(false);
    }
    setSelectedSize("");
    setSelectedView("");
    setDepthAtGums("");
    setSelectedGastricTube("");
    let tempRc = [...airwayItems]
    tempRc[index]['airwayDevice'] = selected;

  };

  // Handle size selection
  const handleSizeChange = (selected) => {

    let tempRc = [...airwayItems];

    if (!selected && selectedAirwayDevice) {
      tempRc[index]["sizeError"] = t("inputIsRequiredError");
    }
    if (selected) {
      tempRc[index]["sizeError"] = "";
      tempRc[index]['size'] = selected;

    }
    setAirwayItems(tempRc);
    const sizeValue = selected.label || selected;
    setSelectedSize(sizeValue);
    updatePCRContext({ [`size-${index}`]: sizeValue });
  };

  // Handle time change
  const handleTimeChange = (timestamp) => {
    let tempRc = [...airwayItems];
    if (!timestamp && selectedAirwayDevice) {
      tempRc[index]["timeError"] = t("inputIsRequiredError");
    }
    if (timestamp) {
      tempRc[index]["timeError"] = "";
      tempRc[index]['time'] = timestamp;
    }
    let formatteTime = timestamp;
    if (timestamp && timestamp.length > 5) {
      formatteTime = moment(timestamp).utc().format("HH:mm");
    }
    setTime(timestamp);
    setAirwayItems(tempRc);
  };

  // Handle Successful dropdown change
  const handleSuccessChange = (selected) => {
    let tempRc = [...airwayItems];
    if (!selected && selectedAirwayDevice) {
      tempRc[index]["successfulError"] = t("inputIsRequiredError");
    }
    if (selected) {
      tempRc[index]["successfulError"] = "";
      tempRc[index]['success'] = selected;
    }
    const successValue = selected.label || selected;
    setAirwayItems(tempRc);

    setSelectedSuccess(successValue);
    updatePCRContext({ [`successful-${index}`]: successValue });
  };

  // Handle View dropdown change
  const handleViewChange = (selected) => {
    let tempRc = [...airwayItems];
    if (!selected && selectedAirwayDevice) {
      tempRc[index]["viewError"] = t("inputIsRequiredError");
    }
    if (selected) {
      tempRc[index]["viewError"] = "";
      tempRc[index]['view'] = selected;

    }
    const viewValue = selected.label || selected;
    setAirwayItems(tempRc);

    setSelectedView(viewValue);
    updatePCRContext({ [`view-${index}`]: viewValue });
  };

  // Handle Depth at Gums change with validation
  const handleDepthAtGumsChange = (e) => {
    const value = e.target.value;
    let tempRc = [...airwayItems];
    
    if (!value && selectedAirwayDevice) {
      tempRc[index]["depthAtGumsError"] = t("inputIsRequiredError");
    }

    if (value) {
      tempRc[index]["depthAtGumsError"] = "";
      tempRc[index]['depthAtGums'] = value;
    }
    
    setAirwayItems(tempRc);
    if (value >= 0) {
    setDepthAtGums(value);
    updatePCRContext({ [`depthAtGums-${index}`]: value });
    }
  };

  // Handle ETCO₂ change
  const handleEtco2Change = (e) => {
    const value = e.target.value;
    let tempRc = [...airwayItems];

    if (!value && selectedAirwayDevice) {
      tempRc[index]["etco2Error"] = t("inputIsRequiredError");
    }
    if (value) {
      tempRc[index]["etco2Error"] = "";
      tempRc[index]['etco2'] = value;
    }
    setAirwayItems(tempRc);

    if (value >= 0) {
      setEtco2(value);
      updatePCRContext({ [`etco2-${index}`]: value });
    }
  };

  // Handle Gastric Tube dropdown change
  const handleGastricTubeChange = (selected) => {
    const gastricTubeValue = selected.label || selected;
    let tempRc = [...airwayItems];

    if (!selected && selectedAirwayDevice == "IGel") {
      tempRc[index]["gastricTubeError"] = t("inputIsRequiredError");
    }
    if (selected) {
      tempRc[index]["gastricTubeError"] = "";
      tempRc[index]['gastricTube'] = selected;

    }

    setAirwayItems(tempRc);

    setSelectedGastricTube(gastricTubeValue);
    updatePCRContext({ [`gastricTube-${index}`]: gastricTubeValue });
  };

  // Handle Initial change
  const handleInitialsChange = (e) => {
    const value = e.target.value;
    let tempRc = [...airwayItems];
    if (!value && selectedAirwayDevice) {
      tempRc[index]["initialsError"] = t("inputIsRequiredError");
    } else {
      if (!/^[A-Za-zÀ-ÿ]*$/.test(initials)) {
        tempRc[index]["initialsError"] = "";
      } else {
        tempRc[index]["initialsError"] = "";
        tempRc[index]['initials'] = value;
      }
    }
    setAirwayItems(tempRc);

    setInitials(value);
    updatePCRContext({ [`initials-${index}`]: value });
  };

  const handleIntialsBlur = (val) => {
    let tempErrors = { ...treatmentErrors };
  };

  // Handle Id change
  const handleIdChange = (e) => {
    let value = e.target.value;
    let tempRc = [...airwayItems];

    if (!value && selectedAirwayDevice) {
      tempRc[index]["idError"] = t("inputIsRequiredError");
    } else {
      tempRc[index]["idError"] = "";
      // tempRc[index]['id'] = value;

    }

    setAirwayItems(tempRc);

    setId(e.target.value);
    updatePCRContext({ [`id-${index}`]: e.target.value });
  };

  // Handle RLung change
  const handleRLungChange = (selected) => {
    let tempRc = [...airwayItems];
    if (!selected && selectedAirwayDevice) {
      tempRc[index]["rLungError"] = t("inputIsRequiredError");
    } else {
      tempRc[index]["rLungError"] = "";
     

    }
    const rLungValue = selected.label || selected;

    setSelectedRLung(rLungValue);
    tempRc[index]['rLung'] = rLungValue;
    setAirwayItems(tempRc);

    updatePCRContext({ [`rLung-${index}`]: rLungValue });
  };

  // Handle LLung change
  const handleLLungChange = (selected) => {
    let tempRc = [...airwayItems];
    const lLungValue = selected.label || selected;
    if (!selected && selectedAirwayDevice) {
      tempRc[index]["lLungError"] = t("inputIsRequiredError");
    } else {
      tempRc[index]["lLungError"] = "";
      tempRc[index]['lLung'] = lLungValue;

    }

    setAirwayItems(tempRc);

    setSelectedLLung(lLungValue);
    updatePCRContext({ [`lLung-${index}`]: lLungValue });
  };

  // Handle Epigastrium change
  const handleEpigastriumChange = (selected) => {
    let tempRc = [...airwayItems];
    const epigastriumValue = selected.label || selected;
    if (!selected && selectedAirwayDevice) {
      tempRc[index]["epigastriumError"] = t("inputIsRequiredError");
    } else {
      tempRc[index]["epigastriumError"] = "";
      tempRc[index]["epigastrium"] = epigastriumValue;
    }
   
    setAirwayItems(tempRc);

    setSelectedEpigastrium(epigastriumValue);
    updatePCRContext({ [`epigastrium-${index}`]: epigastriumValue });
  };

  useEffect(() => {
    if (selectedAirwayDevice === "IGel") {
      setSizeOptions(iGelSizes);
    } else if (selectedAirwayDevice === "ET tube") {
      setSizeOptions(etTubeSizes);
    }
  }, [selectedAirwayDevice]);

  return (
    <div
      className="slds-grid slds-gutters"
      style={{ gridColumnGap: "10px", paddingLeft: "10px" }}
    >
      {/* <div className="slds-col"></div> */}

      {/* Airway device */}
      <PCRComboBox
        id="airwayDevice-combo-box"
        label={t("airwayDevice")}
        options={airwayDeviceOptions}
        value={selectedAirwayDevice}
        placeholder="--"
        onChange={handleAirwayDeviceChange}
        onSelect={(val) => handleAirwayDeviceChange(val.airwayDevice)}
        selection={
          selectedAirwayDevice
            ? [{ id: selectedAirwayDevice, label: selectedAirwayDevice }]
            : []
        }
        name="airwayDevice"
        onError={(e) => console.log(e)}
      />

      {/* Size */}
      <PCRComboBox
        id="size-combo-box"
        label={t("size")}
        options={sizeOptions}
        value={selectedSize}
        placeholder="--"
        onChange={handleSizeChange}
        onSelect={(val) => handleSizeChange(val.size)}
        selection={
          selectedSize ? [{ id: selectedSize, label: selectedSize }] : []
        }
        name="size"
        required={selectedAirwayDevice}
        errorText={
          localAirwayItem[index]["sizeError"] && (
            <p style={{ color: "red" }}> {localAirwayItem[index]["sizeError"]} </p>
          )
        }
      />

      {/* Time */}

      <PCRTimePicker
        assistiveText={{ label: "Time" }}
        id="time"
        className="bor-rad m10 pad-1 w-time-cust-120"
        placeholder="HH:MM"
        value={time}
        name="time"
        onChange={(val) => {
          handleTimeChange(val.time);
        }}
        required={selectedAirwayDevice}
        label="Time"
        errorText={
          localAirwayItem[index]["timeError"] && (
            <p style={{ color: "red" }}> {localAirwayItem[index]["timeError"]} </p>
          )
        }
      />

      {/* Successful */}
      <PCRComboBox
        id="successful-combo-box"
        label={t("successful")}
        options={successOptions}
        value={selectedSuccess}
        placeholder="--"
        onChange={handleSuccessChange}
        onSelect={(val) => handleSuccessChange(val.successful)}
        selection={
          selectedSuccess
            ? [{ id: selectedSuccess, label: selectedSuccess }]
            : []
        }
        name="successful"
        required={selectedAirwayDevice}
        errorText={
          localAirwayItem[index]["successfulError"] && (
            <p style={{ color: "red" }}>
              {localAirwayItem[index]["successfulError"]}
            </p>
          )
        }
      />

      {/* View */}
      {selectedAirwayDevice === "ET tube" && (
        <PCRComboBox
          id="view-combo-box"
          label={t("view")}
          options={viewOptions}
          value={selectedView}
          placeholder="--"
          onChange={handleViewChange}
          onSelect={(val) => handleViewChange(val.view)}
          selection={
            selectedView ? [{ id: selectedView, label: selectedView }] : []
          }
          name="view"
          required={selectedAirwayDevice === "ET tube"}
          errorText={
            localAirwayItem[index]["viewError"] && (
              <p style={{ color: "red" }}>{localAirwayItem[index]["viewError"]}</p>
            )
          }
        />
      )}

      {/* Depth at Gums */}
      {selectedAirwayDevice === "ET tube" && (
        <div className="df" style={{marginTop: '6px'}}>
         <label>
          <div className="df">
          <p> <span style={{color: 'red'}}>*</span> {t("depthAtGums")}   </p>
            <Popover
                body={<p>Measurement should be taken at the patients gums.</p>}
                id="depth-filed-popover"
                align="top left"
            >
                <Button
                assistiveText={{ icon: "Medication Signs info" }}
                iconCategory="utility"
                iconName="info"
                iconSize="medium"
                iconVariant="bare"
                variant="icon"
                />
            </Popover>
          </div>
          <Input
            id="depthAtGums"
            value={depthAtGums}
            onChange={handleDepthAtGumsChange}
            onBlur={handleDepthAtGumsChange}
            type="number"
            name="depthAtGums"
            min="0"
            step="1"
            required={selectedAirwayDevice === "ET tube"}
            label={""}
            errorText={
              localAirwayItem[index]["depthAtGumsError"] && (
                <p style={{ color: "red" }}>
                  {localAirwayItem[index]["depthAtGumsError"]}
                </p>
              )
            }
          />
         </label>
        </div>
        // <div className="df">
          
         
        // </div>
      )}

      {/* ETCO₂ */}

      <Input
        id="etco2"
        value={etco2}
        onChange={handleEtco2Change}
        onBlur={handleEtco2Change}
        type="number"
        name="etco2"
        min="0"
        step="1"
        required={selectedAirwayDevice}
        label={t("etco2")}
        errorText={
          localAirwayItem[index]["etco2Error"] && (
            <p style={{ color: "red" }}>{t(localAirwayItem[index]["etco2Error"])}</p>
          )
        }
      />

      {/* Gastric tube */}
      {/* {
                selectedAirwayDevice == 'iGel'
            } */}
      <PCRComboBox
        id="gastricTube-combo-box"
        label={t("gastricTube")}
        options={successOptions}
        value={selectedGastricTube}
        placeholder="--"
        onChange={handleGastricTubeChange}
        onSelect={(val) => handleGastricTubeChange(val.gastricTube)}
        selection={
          selectedGastricTube
            ? [{ id: selectedGastricTube, label: selectedGastricTube }]
            : []
        }
        required={selectedAirwayDevice === "IGel"}
        name="gastricTube"
        errorText={
          localAirwayItem[index]["gastricTubeError"] && (
            <p style={{ color: "red" }}>
              {localAirwayItem[index]["gastricTubeError"]}
            </p>
          )
        }
      />

      {/* Initial */}
      <Input
        id="initials"
        value={initials}
        name="initials"
        onChange={handleInitialsChange}
        required={selectedAirwayDevice}
        label={t("initial")}
        onBlur={handleInitialsChange}
        errorText={
          localAirwayItem[index]["initialsError"] && (
            <p style={{ color: "red" }} >
              {localAirwayItem[index]["initialsError"]}
            </p>
          )
        }
      />

      {/* ID# */}
      <Input
        id="id"
        value={id}
        onChange={handleIdChange}
        required={selectedAirwayDevice}
        label={t("id")}
        onBlur={handleIdChange}
        errorText={
          localAirwayItem[index]["idError"] && (
            <p style={{ color: "red" }}>{localAirwayItem[index]["idError"]}</p>
          )
        }
        name="id"
      />
      {/* R lung */}
      <PCRComboBox
        id="rLung-combo-box"
        label={t("rLung")}
        options={successOptions}
        value={selectedRLung}
        placeholder="--"
        onChange={handleRLungChange}
        onSelect={(val) => handleRLungChange(val.rLung)}
        selection={
          selectedRLung ? [{ id: selectedRLung, label: selectedRLung }] : []
        }
        name="rLung"
        required={selectedAirwayDevice}
        errorText={
          localAirwayItem[index]["rLungError"] && (
            <p style={{ color: "red" }}>{localAirwayItem[index]["rLungError"]}</p>
          )
        }
      />

      {/* L lung */}
      <PCRComboBox
        id="lLung-combo-box"
        label={t("lLung")}
        options={successOptions}
        value={selectedLLung}
        placeholder="--"
        onChange={handleLLungChange}
        onSelect={(val) => handleLLungChange(val.lLung)}
        selection={
          selectedLLung ? [{ id: selectedLLung, label: selectedLLung }] : []
        }
        name="lLung"
        required={selectedAirwayDevice}
        errorText={
          localAirwayItem[index]["lLungError"] && (
            <p style={{ color: "red" }}>{localAirwayItem[index]["lLungError"]}</p>
          )
        }
      />

      {/* Epigastrium */}
      <PCRComboBox
          id="epigastrium-combo-box"
          label= {t("epigastrium")}
          options={successOptions}
          value={selectedEpigastrium}
          placeholder="--"
          onChange={handleEpigastriumChange}
          onSelect={(val) => handleEpigastriumChange(val.epigastrium)}
          selection={
            selectedEpigastrium
              ? [{ id: selectedEpigastrium, label: selectedEpigastrium }]
              : []
          }
          name="epigastrium"
          required={selectedAirwayDevice}
          errorText={
            localAirwayItem[index]["epigastriumError"] && (
              <p style={{ color: "red" }}>
                {localAirwayItem[index]["epigastriumError"]}
              </p>
            )
          }
        />
    </div>
  );
};

export default AdvancedAirway;
