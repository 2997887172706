import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  Datepicker,
  Icon,
  Modal,
} from "@salesforce/design-system-react";
import "../../../../css/identification.css";
import "../../../../css/comboBox.css";
import {
  traumaDetails,
  transferOptions,
  uniqueIdentifierTypeOptions,
  genderIdentify,
  relationShipsOptions,
  interfacilityTransfer,
  provinceList,
  sexAtBirthDetails,
  countries,
  ageUnitOptions,
} from "../../../../data/constants";
import { convertKgToLbs } from "../../../../utils/util";
import {
  getMonths,
  getPatientAge,
  getNoOfDays,
  getFormattedDateString,
} from "../../../../utils/date";
import PCRComboBox from "../../../common/PCRComboBox";
import { useTranslation } from "react-i18next";
import PCRAddressCompleteComboBox from "../../../common/PCRAddressCompleteComboBox";
import { useAddressComplete } from "../../../../hooks/useAddressComplete";
import {
  PCRContext,
  evaluateError,
} from "../../../../context/PCRContext";
import PCRTextInput from "../../../common/PCRTextInput";
import PDFViewer from "../../../PDFViewer";
import moment from "moment";
import { PCRPhoneNumber } from "../../../common/PCRPhoneNumber";

const IdentificationSVPatientDetails = ({ pcr, handleCancelConfirmation }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, onSaveIdentificationTab,formValidationConfig} = useContext(PCRContext);
  const { identification } = pcrData;
  const [ageUnit, setAgeUnit] = useState("Years");
  const { getAddressDetails } = useAddressComplete();
  const [sexAtBirth, setSexAtBirth] = useState(pcr?.patientSexAtBirth);
  const [openModal, setOpenModal] = useState(false);
  const [openRefusalModal, setOpenRefusalModal] = useState(false);
  const [showRiskAssessmentTool, setShowRiskAssessmentTool] = useState(false);

  useEffect(() => setAgeBasedOnDOB(), [ageUnit, identification?.patientDob]);

  const handlePatientAddressOnSelect = async (data) => {
    if (!data?.selection?.[0]?.id) return;
    let res = await getAddressDetails(data?.selection?.[0]?.id);
    if (res && res?.Items?.[0]) {
      const address = res.Items[0];
      updatePCRContext({
        patientAddress: data?.selection?.[0]?.label || "",
        postalCode: address.PostalCode,
        city: address.City,
        provinceSelected: address.ProvinceCode,
      });
    }
  };

  const onRadioChange = ({ target: { value } }) => {
    setAgeUnit(value);
  };

  const setAgeBasedOnDOB = (value) => {
    if (!value) {
      value = identification?.patientDob;
    }
    if (!value) return;
    if (ageUnit === "Months") {
      updatePCRContext({ patientAge: getMonths(value) });
    } else if (ageUnit === "Years") {
      const { years } = getPatientAge(value);
      updatePCRContext({ patientAge: years });
    } else if (ageUnit === "Days") {
      updatePCRContext({ patientAge: getNoOfDays(value) });
    }
  };

  const toggleOpen = () => setOpenModal(!openModal);

  const onChangeWeights = (value, weight) => {
    if (weight === "lbs") {
      updatePCRContext({
        weightKgs: convertKgToLbs(
          isNaN(parseInt(value)) ? "" : parseInt(value),
          "lbs"
        ),
        weightLbs: isNaN(parseInt(value)) ? "" : parseInt(value),
      });
    } else {
      updatePCRContext({
        weightKgs: isNaN(parseInt(value)) ? "" : parseInt(value),
        weightLbs: isNaN(convertKgToLbs(parseInt(value)))
          ? ""
          : convertKgToLbs(parseInt(value)),
      });
    }
  };

  const handleDate = (date) => {
    updatePCRContext({
      patientDob: date,
    });
  };

  const updatePCRContext = (updatedAttrObj) => {
    const field = formValidationConfig.find(
      (f) => f.name === Object.keys(updatedAttrObj)[0]
    );
    const error = evaluateError(
      pcrData,
      { identification: updatedAttrObj },
      field,
      t
    );
    setPCRData({
      ...pcrData,
      identification: { ...identification, ...updatedAttrObj },
      ...error,
    });
  };

  const handleCancelFromModal = () => {
    setOpenModal(false);
    handleCancelConfirmation();
  };

  const handleRAT = () => setShowRiskAssessmentTool(!showRiskAssessmentTool);

  return (
    <>
      <PDFViewer
        showPDF={showRiskAssessmentTool}
        onDone={handleRAT}
        fileName={"Risk_Assessment_Tool.pdf"}
      />
      <div className="df border-bottom-styles">
        <span className="details-icon patient-details-icon">
          <Icon
            assistiveText={{ label: "Patient Details" }}
            category="utility"
            name="user"
            size="x-small"
          />
        </span>
        <span className="section-header">Patient Details</span>
      </div>
      <div className="m-t-b">
        <div className="df h-4half-r">
          <div className="w-20">
            <div className="slds-form-element">
              <PCRComboBox
                id="unique-combo-box"
                options={uniqueIdentifierTypeOptions}
                onChange={(value) => updatePCRContext({ unique: value })}
                onRequestRemoveSelectedOption={null}
                onSelect={updatePCRContext}
                label={"Patient unique identifier type"}
                required
                name={"unique"}
                value={identification?.unique}
                errorText={pcrData?.uniqueError}
                //value={uniqueIdentifier}
              />
            </div>
          </div>
          {identification?.unique !== "Not available" && (
            <div className="w-30">
              <PCRTextInput
                assistiveText={{ label: "My label" }}
                id="patient-unique-id"
                placeholder=""
                label="Patient unique identifier #"
                className="bor-rad"
                name="uniqueIdentifier"
                onChange={updatePCRContext}
                value={identification?.uniqueIdentifier}
                required
                errorText={pcrData?.uniqueIdentifierError}
              />
            </div>
          )}
          {/* <div className="w-20" style={{ paddingTop: "1.65rem" }}>
            <Button label={t("patientLookup")} variant="outline-brand"></Button>
          </div> */}
        </div>
        <div className="df h-4half-r ">
          <div className="w-40">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="surname-id"
              placeholder=""
              label="Surname of patient"
              name="patientLastName"
              onChange={updatePCRContext}
              value={identification?.patientLastName}
              className="bor-rad"
              required
              errorText={pcrData?.patientLastNameError}
            />
          </div>
          <div className="w-40">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="givenName-id"
              placeholder=""
              label="Given name"
              className="bor-rad"
              required
              name={"patientName"}
              onChange={updatePCRContext}
              value={identification?.patientName}
              errorText={pcrData?.patientNameError}
            />
          </div>
          <div className="w-20">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="givenNameInitial-id"
              placeholder=""
              label="Given initials name"
              className="bor-rad"
              required
              name={"givenNameinitials"}
              value={identification?.givenNameinitials}
              onChange={updatePCRContext}
              errorText={pcrData?.givenNameinitialsError}
            />
          </div>
        </div>
        <div className="df h-4half-r ">
          <div className="w-80 combo-box-wrapper">
            <PCRAddressCompleteComboBox
              id="combobox-inline-patient-address"
              onChange={(value) => updatePCRContext({ patientAddress: value })}
              label="Patient address"
              variant="inline-listbox"
              value={identification?.patientAddress}
              required
              errorText={pcrData?.patientAddressError}
              onSelect={handlePatientAddressOnSelect}
            />
          </div>
          <div className="w-20">
            <div className="slds-form-element">
              <PCRComboBox
                id={"countries"}
                options={countries}
                onChange={(val) => updatePCRContext({ addressCountryCode: val })}
                onSelect={updatePCRContext}
                label={"Country"}
                name={"addressCountryCode"}
                value={
                  identification?.addressCountryCode &&
                  countries.find((c) => c.value === identification?.addressCountryCode)
                    ?.label
                }
                selectAttribute={"value"}
                errorText={pcrData?.addressCountryCodeError}
              />
            </div>
          </div>
        </div>
        <div className="df h-4half-r ">
          <div className="w-25">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="patient-details-city"
              placeholder=""
              label="Town"
              className="bor-rad"
              name="city"
              onChange={updatePCRContext}
              value={identification?.city}
              required
              errorText={pcrData?.cityError}
            />
          </div>
          <div className="w-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="province"
                options={provinceList}
                label={"Province"}
                name={"provinceSelected"}
                onChange={(value) =>
                  updatePCRContext({ provinceSelected: value })
                }
                onSelect={updatePCRContext}
                value={
                  identification?.provinceSelected &&
                  provinceList.find(
                    (c) => c.value === identification?.provinceSelected
                  )?.label
                }
                selectAttribute={"value"}
                errorText={pcrData?.provinceSelectedError}
              />
            </div>
          </div>
          <div className="w-25">
            <PCRTextInput
              assistiveText={{ label: "My label" }}
              id="postalCode"
              placeholder=""
              label="Postal code"
              className="bor-rad"
              onChange={updatePCRContext}
              name={"postalCode"}
              value={identification?.postalCode}
              required
              errorText={pcrData?.postalCodeError}
            />
          </div>
          <div className="w-25">
            <PCRPhoneNumber
              required
              id={"patient-details-telephone"}
              label={t("Telephone")}
              errorText={pcrData?.patientPrimaryPhoneNumberError}
              value={identification?.patientPrimaryPhoneNumber}
              onChange={updatePCRContext}
              name="patientPrimaryPhoneNumber"
            />
          </div>
        </div>
        <div className="disp-align-end h-4half-r ">
          <div className="w-25 date">
            <Datepicker
              labels={{
                label: "Date of birth",
              }}
              placeholder="DD-MM-YYYY"
              formatter={(date) => {
                return date ? moment(date).format("DD-MM-YYYY") : "";
              }}
              parser={(dateString) => {
                return moment(dateString, "DD-MM-YYYY").toDate();
              }}
              relativeYearFrom={-100}
              onChange={(event, data) => {
                handleDate(data?.formattedDate);
              }}
              style={{ width: "100%" }}
              onCalendarFocus={(event, data) => {}}
              className="w-hundread"
            />
          </div>
          <div className="w-25 df">
            <div className="w-50">
              <PCRTextInput
                assistiveText={{ label: "My label" }}
                id="age-id"
                placeholder=""
                label="Age"
                className="bor-rad"
                required
                name={"patientAge"}
                onChange={updatePCRContext}
                value={identification?.patientAge}
                errorText={pcrData?.patientAgeError}
              />
            </div>
            <div className="w-50">
              <RadioGroup
                labels={{ label: "Age unit" }}
                onChange={onRadioChange}
                required
                name="age-unit-radio-group"
                className="disp-align-end"
              >
                {ageUnitOptions.map((ele) => {
                  return (
                    <Radio
                      id={`age-unit-${ele?.id}`}
                      labels={{ label: ele.label }}
                      value={ele?.value}
                      checked={ageUnit === ele?.value}
                      variant="base"
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
          {/* <div className="w-20 disp-align-end radio-alignment">
            
          </div> */}
          <div className="w-25 df">
            <div className="w-50">
              <label className="slds-form-element__label" for="vehicleTypes">
                <abbr class="slds-required" title="required">
                  *
                </abbr>
                Weight (kg/lb)
              </label>
              <div className="df">
                <PCRTextInput
                  assistiveText={{ label: "My label" }}
                  id="weightkgs-id"
                  placeholder="kg"
                  value={identification?.weightKgs}
                  label=""
                  name="weightKgs"
                  className="bor-rad w-50"
                  onChange={({ weightKgs }) =>
                    onChangeWeights(weightKgs, "kgs")
                  }
                  errorText={pcrData?.weightKgsError}
                />
                <PCRTextInput
                  assistiveText={{ label: "My label" }}
                  id="weightlbs-id"
                  placeholder="lb"
                  label=""
                  value={identification?.weightLbs}
                  name="weightLbs"
                  className="bor-rad w-50 pad-r-25"
                  onChange={({ weightLbs }) =>
                    onChangeWeights(weightLbs, "lbs")
                  }
                  errorText={pcrData?.weightLbsError}
                />
              </div>
            </div>
            <div className="w-50">
              <RadioGroup
                labels={{ label: "Sex at birth" }}
                onChange={(e) => setSexAtBirth(e.target.value)}
                required
                name="sex-at-birth-group"
                className="disp-align-end"
              >
                {sexAtBirthDetails.map((ele) => {
                  return (
                    <Radio
                      id={`sex-at-birth-${ele.id}`}
                      labels={{ label: ele.label }}
                      value={ele.value}
                      checked={sexAtBirth === ele.value}
                      variant="base"
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>

          <div className="w-25 m-r-25">
            <div className="slds-form-element">
              <PCRComboBox
                id="genderIdentity"
                label={"Gender identity"}
                name={"genderIdentify"}
                options={genderIdentify}
                onSelect={updatePCRContext}
                onChange={(value) =>
                  updatePCRContext({ genderIdentify: value })
                }
                value={identification?.genderIdentify}
                errorText={pcrData?.genderIdentifyError}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdentificationSVPatientDetails;
