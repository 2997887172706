import Dexie from "dexie";

export const db = new Dexie("ePCR");

db.version(1).stores({
  pcrs: "&id, transferNumber, vehicleNumber, dispatchType, medicIds, status, timestamp",
});

export const resetDatabase = () => {
  return db.transaction("rw", db.pcrs, async () => {
    await Promise.all(db.tables.map((table) => table.clear()));
  });
};

/* 71*/
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0,
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

// Function to save or update PCR data
export const savePCRData = async (data) => {
  try {
    await db.pcrs.put({
      id: data.id || generateUUID(), // Assuming generateUUID() creates a unique ID if not present
      transferNumber: data.transferNumber,
      vehicleNumber: data.vehicleNumber || "",
      dispatchType: data.dispatchType || "defaultType", // Include default values as needed
      medicIds: data.medicIds || "defaultMedic", // Include default values as needed
      status: data.status || "In Progress", // Include default values as needed
      json: JSON.stringify(data), // Save the entire PCR data as a JSON string
      timestamp: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Failed to save PCR data to IndexedDB:", error);
  }
};

// Function to retrieve all PCR data from IndexedDB
export const getAllPCRData = async () => {
  try {
    const records = await db.pcrs.toArray();
    return records.map((r) => ({
      id: r.id,
      transferNumber: r.transferNumber,
      vehicleNumber: r.vehicleNumber,
      dispatchType: r.dispatchType,
      medicIds: r.medicIds,
      status: r.status,
      timestamp: r.timestamp,
      ...JSON.parse(r.json), // Parse the JSON string back into an object
    }));
  } catch (error) {
    console.error("Failed to retrieve PCR data from IndexedDB:", error);
    return [];
  }
};

// Function to get a specific PCR data by ID (optional, if needed)
export const getPCRDataById = async (id) => {
  try {
    const record = await db.pcrs.get(id);
    return record ? { ...record, ...JSON.parse(record.json) } : null;
  } catch (error) {
    console.error(`Failed to retrieve PCR data with ID ${id} from IndexedDB:`, error);
    return null;
  }
};
