import React, { useContext, useEffect, useState } from 'react';
import { RadioGroup, Radio, Checkbox, Button, Modal, Icon } from '@salesforce/design-system-react';
import { useTranslation } from 'react-i18next';
import { PCRContext } from 'context/PCRContext';
import EMSDefibrillation from './PCREMSDefibrillation.jsx';
import PCRTimePicker from '../../../../common/PCRTimePicker';
import { useTreatmentValidations } from '../../../../../hooks/useTreatmentValidations.js';

const CardiacArrestSection = ({ updatePCRContext }) => {
    const { t } = useTranslation();
    const {formValidationConfig,pcrData } = useContext(PCRContext);

    // State to manage selected witness type and time input
    const [witnessArrest, setWitnessArrest] = useState("");
    const [witnessTime, setWitnessTime] = useState("");
    const [cpr, setCpr] = useState("");
    const [cprTime, setCprTime] = useState("");
    const [bystanderDefibrillation, setBystanderDefibrillation] = useState("");
    const [bystanderDefibTime, setBystanderDefibTime] = useState("");
    const [responderDefibrillation, setResponderDefibrillation] = useState("");
    const [responderDefibTime, setResponderDefibTime] = useState("");
    const [arrestEtiology, setArrestEtiology] = useState("");
    const [terminationOfCare, setTerminationOfCare] = useState("");
    const [terminationTime, setTerminationTime] = useState("");
    const [rosc, setRosc] = useState("");
    const [roscTime, setRoscTime] = useState("");
    const [oxygen, setOxygen] = useState(false);
    const [cooling, setCooling] = useState(false);
    const [ivTherapy, setIvTherapy] = useState(false);
    const [leadAcquired, setLeadAcquired] = useState(false);
    const [emsItems, setEmsItems] = useState([{ id: 0, data: {} }]);
    const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [errorText, setErrorText] = useState({
        witnessTime: "",
        cprTime: "",
        bystanderDefibTime: "",
        responderDefibTime: "",
        terminationTime: "",
        roscTime: "",
    });

    const {getTreatmentValidations}  = useTreatmentValidations();

    // Handle radio button change
    const handleWitnessChange = (e) => {
        setWitnessArrest(e.target.value);
        if (e.target.value === "") {
            setWitnessTime("");
        }
        updatePCRContext({ witnessArrest: e.target.value });
    };

    // Handle checkbox change for optional selections in ROSC
    const handleCheckboxChange = (setter, e, fieldName) => {
        setter(e.target.checked);
        updatePCRContext({ [fieldName]: e.target.checked });
    };

    // Handle time input change with validation
    const handleTimeChange = (setTime, value, fieldName) => {
        const timeValue = value || "";
        setTime(timeValue);
        const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        setErrorText((prevErrors) => ({
            ...prevErrors,
            [fieldName]: timeValue.length !== 5 || (timeValue.length === 5 && !timePattern.test(timeValue))
                ? t("invalidFormat")
                : ""
        }));
        updatePCRContext({ [fieldName]: timeValue });
    };

    // Function to add a new EMS defibrillation row
    const addEMSItem = () => {
        const newId = emsItems.length > 0 ? emsItems[emsItems.length - 1].id + 1 : 0;
        setEmsItems([...emsItems, { id: newId, data: {} }]);
    };

    // Function to handle data updates in each row
    const handleEMSRowDataChange = (index, field, value) => {
        const updatedItems = [...emsItems];
        updatedItems[index].data[field] = value;
        setEmsItems(updatedItems);
    };

    // Function to check if a row contains any data (returns true if it has data)
    const isRowEmpty = (rowData) => {
        return Object.values(rowData).every(value => value === "" || value === null || value === undefined);
    };

    // Function to handle the delete button click
    const handleDeleteClick = (index) => {
        const rowData = emsItems[index].data;

        // If the row is empty, delete it directly
        if (isRowEmpty(rowData)) {
            removeEMSItem(index);
        } else if (!isRowEmpty(rowData) && emsItems.length === 1) {
            setSelectedRowForDeletion(index);
            setShowDeleteModal(true);
            addEMSItem();
        } else {
            // If the row contains data, show the modal for confirmation
            setSelectedRowForDeletion(index);
            setShowDeleteModal(true);
        }
    };

    // Function to remove the selected row and handle the case for 1 row with data
    const removeEMSItem = (index) => {
        let updatedItems = emsItems.filter((_, i) => i !== index);

        // If there is only one row and it contains data, remove it and add a new empty row
        if (emsItems.length === 1 && !isRowEmpty(emsItems[0].data)) {
            updatedItems = [{ id: 0, data: {} }];
        }

        // If no rows left after deletion, add a new empty row
        if (updatedItems.length === 0) {
            updatedItems.push({ id: 0, data: {} });
        }

        setEmsItems(updatedItems);
        setSelectedRowForDeletion(null);
        setShowDeleteModal(false);
    };

    // Function to confirm row deletion from the modal
    const confirmDeletion = () => {
        removeEMSItem(selectedRowForDeletion);
    };

    const isRequiredField = (name) => {
        // let allFields = getTreatmentValidations(pcrData);
        let allFields
       let field = formValidationConfig.find(f => f.name === name);     
       return field?.shouldDisplayError

    }

    return (
        <>
            <div className='pcr-col' style={{ width: '100%' }}>
                <div className="pcr-row">
                    {/* Witness Arrest */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("cardiacArrestWitnessArrest") }}
                            name="witness-arrest-radio-group"
                            onChange={handleWitnessChange}
                            required={false}
                        >
                            <Radio
                                id="witness-bystander"
                                labels={{ label: t("witnessArrestBystander") }}
                                value="Bystander"
                                checked={witnessArrest === "Bystander"}
                            />
                            <Radio
                                id="witness-first-responder"
                                labels={{ label: t("witnessArrest1stResponder") }}
                                value="1st responder"
                                checked={witnessArrest === "1st responder"}
                            />
                            <Radio
                                id="witness-ems"
                                labels={{ label: t("witnessArrestEMS") }}
                                value="EMS"
                                checked={witnessArrest === "EMS"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for Witness Arrest */}
                    {witnessArrest && (
                        <div style={{ marginLeft: '-80px', marginTop: '25px', width: '80px' }}>
                            <PCRTimePicker
                                id="witness-time"
                                placeholder="HH:MM"
                                value={witnessTime}
                                onChange={(e) => { handleTimeChange(setWitnessTime, e?.witnessTime, "witnessTime") }}
                                errorText={errorText.witnessTime}
                                required={false}
                                name="witnessTime"
                            />
                        </div>
                    )}

                    {/* CPR */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("cardiacArrestCPR") }}
                            name="cpr-radio-group"
                            onChange={(e) => {
                                setCpr(e.target.value)
                                updatePCRContext({ cpr: e.target.value });
                            }}
                        >
                            <Radio
                                id="cpr-bystander"
                                labels={{ label: t("CPRBystander") }}
                                value="Bystander"
                                checked={cpr === "Bystander"}
                            />
                            <Radio
                                id="cpr-first-responder"
                                labels={{ label: t("CPR1stResponder") }}
                                value="1st responder"
                                checked={cpr === "1st responder"}
                            />
                            <Radio
                                id="cpr-ems"
                                labels={{ label: t("CPREMS") }}
                                value="EMS"
                                checked={cpr === "EMS"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for CPR */}
                    {cpr && (
                        <div style={{ marginLeft: '-80px', marginTop: '25px', width: '80px' }}>
                            <PCRTimePicker
                                id="cpr-time"
                                placeholder="HH:MM"
                                value={cprTime}
                                onChange={(e) => handleTimeChange(setCprTime, e?.cprTime, "cprTime")}
                                errorText={errorText.cprTime}
                                name="cprTime"
                            />
                        </div>
                    )}

                    {/* Bystander Defibrillation */}
                    <div className="radio-group" style={{ marginTop: '-25px', width: '100px' }}>
                        <RadioGroup
                            labels={{ label: t("cardiacArrestBystanderDefibrillation") }}
                            name="bystander-defib-radio-group"
                            onChange={(e) => {
                                setBystanderDefibrillation(e.target.value);
                                updatePCRContext({ bystanderDefibrillation: e.target.value });
                            }}
                            required={isRequiredField('bystanderDefibrillation')}
                        >
                            <Radio
                                id="bystander-defib-yes"
                                labels={{ label: t("Yes") }}
                                value="Yes"
                                checked={bystanderDefibrillation === "Yes"}
                            />
                            <Radio
                                id="bystander-defib-no"
                                labels={{ label: t("No") }}
                                value="No"
                                checked={bystanderDefibrillation === "No"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for Bystander Defibrillation */}
                    {bystanderDefibrillation === "Yes" && (
                        <div style={{ marginLeft: '-140px', width: '80px', marginRight: '30px', marginBottom: '-1.5rem' }}>
                            <PCRTimePicker
                                id="bystander-defib-time"
                                placeholder="HH:MM"
                                value={bystanderDefibTime}
                                onChange={(e) => handleTimeChange(setBystanderDefibTime, e?.bystanderDefibTime, "bystanderDefibrillationTime")}
                                errorText={errorText.bystanderDefibTime}
                                name="bystanderDefibTime"
                                required={bystanderDefibrillation === "Yes"}
                            />
                        </div>
                    )}

                    {/* 1st Responder Defibrillation */}
                    <div className="radio-group" style={{ marginTop: '-25px', width: '100px' }}>
                        <RadioGroup
                            labels={{ label: t("1st responder defibrillation") }}
                            name="responder-defib-radio-group"
                            onChange={(e) => {
                                setResponderDefibrillation(e.target.value);
                                updatePCRContext({ firstResponderDefibrillation: e.target.value });
                            }}
                            required={isRequiredField('firstResponderDefibrillation')}
                        >
                            <Radio
                                id="responder-defib-yes"
                                labels={{ label: t("Yes") }}
                                value="Yes"
                                checked={responderDefibrillation === "Yes"}
                            />
                            <Radio
                                id="responder-defib-no"
                                labels={{ label: t("No") }}
                                value="No"
                                checked={responderDefibrillation === "No"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for 1st Responder Defibrillation */}
                    {responderDefibrillation === "Yes" && (
                        <div style={{ marginLeft: '-140px', width: '80px', marginBottom: '-1.5rem'  }}>
                            <PCRTimePicker
                                id="responder-defib-time"
                                placeholder="HH:MM"
                                value={responderDefibTime}
                                onChange={(e) => handleTimeChange(setResponderDefibTime, e?.responderDefibTime, "responderDefibTime")}
                                errorText={errorText.responderDefibTime}
                                name="responderDefibTime"
                                required={responderDefibrillation === "Yes"}
                            />
                        </div>
                    )}
                </div>

                <div className="pcr-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', width: '100%' }}>
                    {/* EMS defibrillation component label */}
                    <label className="slds-form-element__label" style={{ flexGrow: 1 }}>
                        {t("EMSDefibrillationLabel")}
                    </label>
                </div>

                <div className="pcr-row" style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                    {emsItems.map((item, index) => (
                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: '-18px' }}>
                            {/* Input fields container */}
                            <div style={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                <EMSDefibrillation
                                    index={index}
                                    updateRowData={(field, value) => handleEMSRowDataChange(index, field, value)}
                                    updatePCRContext={updatePCRContext}
                                />
                            </div>

                            {/* Delete Icon */}
                            <div style={{ marginLeft: '10px', flexShrink: 0 }}>
                                {
                                    index === 0 ? (
                                        <Button
                                        iconCategory="utility"
                                        iconName="new"
                                        variant="icon"
                                        className="slds-icon-text-success"
                                        onClick={addEMSItem}
                                        iconSize="large"
                                        style={{ marginLeft: '10px'}}
                                    />
                                    ) : (
                                        <Button
                                            iconCategory="utility"
                                            iconName="ban"
                                            variant="icon"
                                            className="slds-icon-text-error"
                                            onClick={() => handleDeleteClick(index)}
                                            iconSize="large"
                                            style={{ marginLeft: '10px' }}
                                        />
                                    )
                                }
                               
                               
                            </div>
                        </div>
                    ))}
                </div>

                {/* Modal for deletion confirmation */}
                {showDeleteModal && (
                    <Modal
                        footer={[
                            <Button label="Confirm" onClick={confirmDeletion} />,
                            <Button label="Cancel" onClick={() => setShowDeleteModal(false)} />,
                        ]}
                        isOpen={showDeleteModal}
                        onRequestClose={() => setShowDeleteModal(false)}
                        title={
                            <span>
                                <Icon
                                    assistiveText={{ label: "warning" }}
                                    category="utility"
                                    name="warning"
                                    size="medium"
                                    style={{ fill: "#fe9339" }}
                                />
                                <span>{t("Warning: Data Deletion")}</span>
                            </span>
                        }
                    >
                        <div className="slds-m-around_medium">
                            <p>{t("You are about to remove a row that contains data. Are you sure you want to proceed?")}</p>
                        </div>
                    </Modal>
                )}

                <div className="pcr-row" style={{ marginTop: '-30px' }}>
                    {/* Arrest Etiology */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("cardiacArrestEtiology") }}
                            name="arrest-etiology-radio-group"
                            onChange={(e) => {
                                setArrestEtiology(e.target.value);
                                updatePCRContext({ arrestEtiology: e.target.value });
                            }}
                            required={isRequiredField('arrestEtiology')}
                        >
                            <Radio
                                id="arrest-etiology-trauma"
                                labels={{ label: t("Trauma") }}
                                value="Trauma"
                                checked={arrestEtiology === "Trauma"}
                            />
                            <Radio
                                id="arrest-etiology-non-trauma"
                                labels={{ label: t("Non-trauma") }}
                                value="Non-trauma"
                                checked={arrestEtiology === "Non-trauma"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Termination of Care */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("cardiacArrestTerminationOfCare") }}
                            name="termination-of-care-radio-group"
                            onChange={(e) => {
                                setTerminationOfCare(e.target.value)
                                updatePCRContext({ terminationOfCare: e.target.value });
                            }}
                        >
                            <Radio
                                id="termination-yes"
                                labels={{ label: t("Yes") }}
                                value="Yes"
                                checked={terminationOfCare === "Yes"}
                            />
                            <Radio
                                id="termination-no"
                                labels={{ label: t("No") }}
                                value="No"
                                checked={terminationOfCare === "No"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for Termination of Care */}
                    {terminationOfCare === "Yes" && (
                        <div style={{ marginLeft: '-140px', marginRight: '50px', marginTop: '25px', width: '80px' }}>
                            <PCRTimePicker
                                id="termination-time"
                                placeholder="HH:MM"
                                value={terminationTime}
                                onChange={(e) => handleTimeChange(setTerminationTime, e?.terminationTime, "terminationTime")}
                                errorText={errorText.terminationTime}
                                required={true}
                                name="terminationTime"
                            />
                        </div>
                    )}

                    {/* ROSC */}
                    <div className="radio-group">
                        <RadioGroup
                            labels={{ label: t("cardiacArrestROSC") }}
                            name="rosc-radio-group"
                            onChange={(e) => {
                                setRosc(e.target.value);
                                updatePCRContext({ rosc: e.target.value });
                            }}
                        >
                            <Radio
                                id="rosc-yes"
                                labels={{ label: t("Yes") }}
                                value="Yes"
                                checked={rosc === "Yes"}
                            />
                            <Radio
                                id="rosc-no"
                                labels={{ label: t("No") }}
                                value="No"
                                checked={rosc === "No"}
                            />
                        </RadioGroup>
                    </div>

                    {/* Time input for ROSC */}
                    {rosc === "Yes" && (
                        <>
                            <div style={{ marginLeft: '-140px', marginTop: '25px', width: '80px' }}>
                                <PCRTimePicker
                                    id="rosc-time"
                                    placeholder="HH:MM"
                                    value={roscTime}
                                    onChange={(e) => handleTimeChange(setRoscTime, e?.roscTime, "roscTime")}
                                    errorText={errorText.roscTime}
                                    required={true}
                                    name="roscTime"
                                />
                            </div>

                            {/* Optional checkbox selections for ROSC */}
                            <div style={{ marginTop: '15px' }}>
                                <Checkbox
                                    labels={{ label: t("ROSC100Oxygen") }}
                                    checked={oxygen}
                                    onChange={(e) => handleCheckboxChange(setOxygen, e, "oxygen")}
                                />
                                <Checkbox
                                    labels={{ label: t("ROSCPassiveCooling") }}
                                    checked={cooling}
                                    onChange={(e) => handleCheckboxChange(setCooling, e, "cooling")}
                                />
                                <Checkbox
                                    labels={{ label: t("ROSCIVTherapy") }}
                                    checked={ivTherapy}
                                    onChange={(e) => handleCheckboxChange(setIvTherapy, e, "ivTherapy")}
                                />
                                <Checkbox
                                    labels={{ label: t("ROSC12LeadAcquired") }}
                                    checked={leadAcquired}
                                    onChange={(e) => handleCheckboxChange(setLeadAcquired, e, "leadAcquired")}
                                />
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
};

export default CardiacArrestSection;
