import React, { useState } from "react";
import { Button, Popover, Checkbox } from "@salesforce/design-system-react";
import { useTranslation } from "react-i18next";
import {
  electricalTherapyType,
  successOptions,
} from "../../../../../data/constants.js";
import PCRComboBox from "../../../../common/PCRComboBox.jsx";
import PCRTextInput from "../../../../common/PCRTextInput.jsx";
import PCRTimePicker from "../../../../common/PCRTimePicker.jsx";

const PCRACPElectricalTherapy = ({
  index,
  updateRowData,
  updatePCRContext,
  addTherapyItem,
  handleDeleteClick
}) => {
  const { t } = useTranslation();

  const [selectedTherapyType, setSelectedTherapyType] = useState("");
  const [electricalTherapyTime, setTime] = useState("");
  const [electricalTherapyJoules, setJoules] = useState("");
  const [electricalTherapyPulse, setSelectedPulse] = useState("");
  const [mA, setMA] = useState("");
  const [rate, setRate] = useState("");

  // Handle Therapy Type change
  const handleTherapyTypeChange = (selected) => {
    const therapyTypeValue = selected.label || selected;
    setSelectedTherapyType(therapyTypeValue);
    updateRowData(`therapyType-${index}`, therapyTypeValue);
    updatePCRContext({ [`therapyType-${index}`]: therapyTypeValue });
  };

  // Handle Time change
  const handleTimeChange = (e) => {
    const timeValue = e?.electricalTherapyTime || "";
    setTime(timeValue);
    updateRowData(`electricalTherapyTime-${index}`, timeValue);
    updatePCRContext({ [`electricalTherapyTime-${index}`]: timeValue });
  };

  // Handle Numeric Input change
  const handleNumericInputChange = (field, value, setStateFunction) => {
    const numericValue = value.replace(/[^0-9]/g, "");
    if (numericValue !== "" && parseInt(numericValue, 10) < 1) {
      value = "";
    } else {
      value = numericValue;
    }
    setStateFunction(value);
    updateRowData(field, value);
    updatePCRContext({ [field]: value });
  };

  const handlePulseChange = (selected) => {
    const pulseValue = selected.label || selected;
    setSelectedPulse(pulseValue);
    updateRowData(`electricalTherapyPulse-${index}`, pulseValue);
    updatePCRContext({ [`electricalTherapyPulse-${index}`]: pulseValue });
  };

  const isCardioManualFieldRequired =
    selectedTherapyType === "Cardioversion" ||
    selectedTherapyType === "Manual defibrillation";
  const isPacingFieldRequired = selectedTherapyType === "Pacing";

  return (
    <>
      <div style={{display: 'inline-flex'}}>
        <div className="df h-4half-r" style={{width: "100%"}}>
          {/* Type */}
          <div className="w-33">
            <PCRComboBox
              id={`therapyType-combo-box-${index}`}
              options={electricalTherapyType}
              value={selectedTherapyType}
              label={t("electricalTherapyType")}
              placeholder="--"
              onChange={handleTherapyTypeChange}
              onSelect={(val) => handleTherapyTypeChange(val.therapyType)}
              selection={
                selectedTherapyType
                  ? [{ id: selectedTherapyType, label: selectedTherapyType }]
                  : []
              }
              name="therapyType"
            />
          </div>

          {/* Time */}
          <div className="w-33">
            <PCRTimePicker
              id={`electricalTherapyTime-${index}`}
              placeholder="HH:MM"
              value={electricalTherapyTime}
              label={t("electricalTherapyTime")}
              onChange={handleTimeChange}
              pattern="([01][0-9]|2[0-3]):[0-5][0-9]"
              errorText={
                electricalTherapyTime &&
                  !/^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(electricalTherapyTime)
                  ? t("invalidFormat")
                  : ""
              }
              name="electricalTherapyTime"
              required={isPacingFieldRequired || isCardioManualFieldRequired}
            />
          </div>

          {/* Joules */}
          <div className="w-33">
            <PCRTextInput
              id={`electricalTherapyJoules-${index}`}
              value={electricalTherapyJoules}
              onChange={(e) =>
                handleNumericInputChange(
                  `electricalTherapyJoules-${index}`,
                  e?.electricalTherapyJoules,
                  setJoules
                )
              }

              label={t("electricalTherapyJoules")}
              name="electricalTherapyJoules"
              required={isCardioManualFieldRequired}
            />
          </div>

          {/* Pulse */}
          <div className="w-33">
            <PCRComboBox
              id={`electricalTherapyPulse-combo-box-${index}`}
              options={successOptions}
              value={electricalTherapyPulse}
              label={
                <>
                  {t("electricalTherapyPulse")}
                  <Popover
                    body={t("therapyPulseInfo")}
                    id="settings-popover"
                    align="bottom right"
                  >
                    <Button
                      assistiveText={{ icon: "info" }}
                      iconCategory="utility"
                      iconName="info"
                      iconSize="medium"
                      variant="icon"
                    />
                  </Popover>
                </>}
              placeholder="--"
              onChange={handlePulseChange}
              onSelect={(val) => handlePulseChange(val.electricalTherapyPulse)}
              selection={
                electricalTherapyPulse
                  ? [{ id: electricalTherapyPulse, label: electricalTherapyPulse }]
                  : []
              }
              name="electricalTherapyPulse"
              required={isCardioManualFieldRequired}
            />
          </div>

          {/* mA */}
          <div className="w-33">
            <PCRTextInput
              id={`mA-${index}`}
              label={t("electricalTherapymA")}
              value={mA}
              onChange={(e) =>
                handleNumericInputChange(`mA-${index}`, e?.mA, setMA)
              }
              name="mA"
              required={isPacingFieldRequired}
            />
          </div>

          {/* Rate */}
          <div className="w-33">
            <PCRTextInput
              id={`rate-${index}`}
              type="text"
              value={rate}
              label={t("electricalTherapyRate")}
              onChange={(e) =>
                handleNumericInputChange(`rate-${index}`, e?.rate, setRate)
              }
              name="rate"
              required={isPacingFieldRequired}
            />
          </div>

          <div className="slds-form-element__label">
            {index == 0 ? (
              <Button
                iconCategory="utility"
                iconName="new"
                variant="icon"
                className="slds-icon-text-success"
                onClick={addTherapyItem}
                iconSize="large"
                style={{ marginLeft: "10px", marginTop: "1.5rem" }}
              />
            ) : (
              <Button
                iconCategory="utility"
                iconName="ban"
                variant="icon"
                className="slds-icon-text-error"
                onClick={() => handleDeleteClick(index)}
                iconSize="large"
                style={{ marginLeft: "1.5rem", marginTop: "1.5rem" }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PCRACPElectricalTherapy;
