import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'css/PCRMedications.css';
import { PCRContext} from 'context/PCRContext.js';
import { Button, Modal, Icon } from '@salesforce/design-system-react';
import { VALIDATION_TYPES } from "../../../../../data/constants";
import PCRMedicationItem from 'components/PCR/PCR-AV/Treatment/Medications/PCRMedicationItem';

const PCRMedications = ({ pcr }) => {
  const { t } = useTranslation();
  const { pcrData, setPCRData, formValidationConfig } = useContext(PCRContext);
  const { treatment } = pcrData || {};
  const patientMedications = treatment?.patientMedications || [];

  const [medicationItems, setMedicationItems] = useState([{ id: 0, data: {} }]);
  const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePCRContext = (inputObj) => {
    const field = formValidationConfig.find(f => f.name === Object.keys(inputObj)[0]);
    let errorState = {};

    Object.entries(inputObj).forEach(([key, value]) => {
      field?.validations.forEach((type) => {
        if (field?.shouldDisplayError && !value) {
          switch (type) {
            case VALIDATION_TYPES.VALUE_REQUIRED:
              errorState[`${field.name}Error`] = t("inputIsRequiredError");
              break;
            default:
              break;
          }
        }
        if (field?.shouldDisplayError && value) {
          errorState[`${field.name}Error`] = "";
        }
      });
    });

    setPCRData({
      ...pcrData,
      treatment: { ...treatment, medications: { ...patientMedications, ...inputObj } },
      ...errorState,
    });
  };

  // Function to add a new medication  row
  const addMedicationItem = () => {
    const newId = medicationItems.length > 0 ? medicationItems[medicationItems.length - 1].id + 1 : 0;
    setMedicationItems([...medicationItems, { id: newId, data: {} }]);
  };

  // Function to handle data updates in each row
  const handleMedicationRowDataChange = (index, field, value) => {
    const updatedItems = [...medicationItems];
    updatedItems[index].data[field] = value;
    setMedicationItems(updatedItems);
  };

  // Function to check if a row contains any data (returns true if it has data)
  const isRowEmpty = (rowData) => {
    return Object.values(rowData).every(value => value === "" || value === null || value === undefined);
  };

  // Function to handle the delete button click
  const handleDeleteClick = (index) => {
    const rowData = medicationItems[index].data;

    // If the row is empty, delete it directly
    if (isRowEmpty(rowData)) {
      removeMedicationItem(index);
    } else if (!isRowEmpty(rowData) && medicationItems.length === 1) {
      setSelectedRowForDeletion(index);
      setShowDeleteModal(true);
      addMedicationItem();
    } else {
      // If the row contains data, show the modal for confirmation
      setSelectedRowForDeletion(index);
      setShowDeleteModal(true);
    }
  };

  // Function to remove the selected row and handle the case for 1 row with data
  const removeMedicationItem = (index) => {
    let updatedItems = medicationItems.filter((_, i) => i !== index);

    // If there is only one row and it contains data, remove it and add a new empty row
    if (medicationItems.length === 1 && !isRowEmpty(medicationItems[0].data)) {
      updatedItems = [{ id: 0, data: {} }];
    }

    // If no rows left after deletion, add a new empty row
    if (updatedItems.length === 0) {
      updatedItems.push({ id: 0, data: {} });
    }

    setMedicationItems(updatedItems);
    setSelectedRowForDeletion(null);
    setShowDeleteModal(false);
  };

  // Function to confirm row deletion from the modal
  const confirmDeletion = () => {
    removeMedicationItem(selectedRowForDeletion);
  };

  return (
    <>
      <div className='pcr-col' style={{ width: '100%', marginTop: '3rem' }}>
        <div className="pcr-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', width: '100%', marginTop: '-20px' }}>
          {/* Medications component label */}
          <label className="slds-form-element__label" style={{ flexGrow: 1, fontWeight: '400', fontSize: '16px' }}>
            {t("medications")}
          </label>

          {/* Add button to add new Medications row */}
        
        </div>

        <div className="pcr-row" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {medicationItems.map((item, index) => (
           <div key={item.id} className='slds-grid slds-gutters'>
              {/* Input fields container */}
              <PCRMedicationItem
                  index={index}
                  updateRowData={(field, value) => handleMedicationRowDataChange(index, field, value)}
                  updatePCRContext={updatePCRContext}
                  t={t}
                />
              {/* Delete Icon */}
              <div style={{margin: '1.5rem -12px 0px 1rem'}}>
               {
                index === 0 ? ( <Button
                  iconCategory="utility"
                  iconName="new"
                  variant="icon"
                  className="slds-icon-text-success"
                  onClick={addMedicationItem}
                  iconSize="large"
                />
              ) : (  <Button
                iconCategory="utility"
                iconName="ban"
                variant="icon"
                className="slds-icon-text-error"
                onClick={() => handleDeleteClick(index)}
                iconSize="large"
                // style={{ paddingLeft: '1rem'}}
              />)
               }
              </div>
            </div>
          ))}
        </div>

        {/* Modal for deletion confirmation */}
        {showDeleteModal && (
          <Modal
            footer={[
              <Button label="Confirm" onClick={confirmDeletion} />,
              <Button label="Cancel" onClick={() => setShowDeleteModal(false)} />,
            ]}
            isOpen={showDeleteModal}
            onRequestClose={() => setShowDeleteModal(false)}
            title={
              <span>
                <Icon
                  assistiveText={{ label: "warning" }}
                  category="utility"
                  name="warning"
                  size="medium"
                  style={{ fill: "#fe9339" }}
                />
                <span>{t("Warning: Data Deletion")}</span>
              </span>
            }
          >
            <div className="slds-m-around_medium">
              <p>{t("You are about to remove a row that contains data. Are you sure you want to proceed?")}</p>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PCRMedications;